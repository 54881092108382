import * as React from 'react';
import { useState, useEffect } from 'react';
import { NavLink } from 'react-router-dom';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Select, InputLabel, Box, Button, } from '@mui/material';
import { styled, alpha } from '@mui/material/styles';
import CloseIcon from '@mui/icons-material/Close';
import IconButton from '@mui/material/IconButton'
import { Stack } from '@mui/system';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';
import MenuItem from '@mui/material/MenuItem';
import TextField from '@mui/material/TextField';
import Backdrop from '@mui/material/Backdrop';
import { Helpers } from '../service';
import { UserApi } from '../service/axios';


const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
    ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: 'center',
    color: theme.palette.text.secondary,
}));

export default function DataPrivacy() {
    const [open, setOpen] = React.useState(false);
    const [error, setError] = useState('');
    const [selection, setSelection] = useState('');
    const [maskingData, setMaskingData] = useState([]);
    const [singleMaskingData, setSingleMaskingData] = useState('');
    const [number, setNumber] = useState('');
    const [action, setAction] = useState('Active');
    const [loading, setLoading] = useState(true);


    useEffect(() => {
        getMaskingWithDataRule();
    }, []);

    const getMaskingWithDataRule = () => {
        setLoading(true);
        UserApi.getMaskingWithDataRule()
            .then((resp) => {
                if (resp.status === 200) {
                    setMaskingData(resp.data.Masking_rules);
                } else {
                }
            })
            .catch((error) => {
                console.error('Error fetching to get Masking Data Rule:', error);
            })
            .finally(() => {
                setLoading(false);
            });
    };

    const submitPrivacyRules = (id, data) => {
        setLoading(true);
        UserApi.submitPrivacyRules(id, data)
            .then((resp) => {
                if (resp.status === 200) {
                } else {
                    console.log('error', resp.data.error_msg);
                }
            })
            .catch((error) => {
                console.error('Error fetching to get Masking Data Rule:', error);
            })
            .finally(() => {
                getMaskingWithDataRule();
                setLoading(false);
                setOpen(false);
            });
    };

    const handleClickOpen = (mask) => {
        setSingleMaskingData(mask);
        setSelection(mask.data_rule.technique);
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
        setError('');
    };


    const handleChange = (event) => {
        setSelection(event.target.value);
        const updatedMask = {
            ...singleMaskingData,
            data_rule: {
                ...singleMaskingData.data_rule,
                technique: event.target.value,
            },
        };
        setSingleMaskingData(updatedMask);
    };


    useEffect(() => {
        updateInputRepresentation(singleMaskingData?.data_rule?.rule_value || ''); 
    }, [singleMaskingData?.data_rule?.rule_value]);

    const handleNumberChange = (event) => {
        const inputValue = event.target.value.slice(0, 1);
        const updatedMask = {
            ...singleMaskingData,
            data_rule: {
                ...singleMaskingData?.data_rule,
                rule_value: inputValue,
            },
        };
        setSingleMaskingData(updatedMask);
        setNumber(inputValue);

        updateInputRepresentation(inputValue);
    };

    const handleChangeCheck = (event) => {
        setAction(event.target.value);
        const updatedMask = {
            ...singleMaskingData,
            status: event.target.value
        };
        setSingleMaskingData(updatedMask);
    };
    const handleSubmit = () => {
        if (action === '' || selection === '' || (selection === "MASKING" && number === '')) {
            setError('Please fill in all the required fields.');
        } else {
            setError('');
            let formData;
            if (selection === "ENCRYPTION") {
                formData = {
                    "status": singleMaskingData.status,
                    "technique": singleMaskingData.data_rule.technique
                };
            } else {
                formData = {
                    "status": singleMaskingData.status,
                    "technique": singleMaskingData.data_rule.technique,
                    "rule_value": singleMaskingData.data_rule.rule_value
                };
            }

            submitPrivacyRules(singleMaskingData.id, formData);
        }
    };
// input field get data
const [inputRepresentation, setInputRepresentation] = useState('');

const updateInputRepresentation = (value) => {
    let formattedValue = value;
    let abc = '1234567890';
    let result = abc.substring(10-value, 10);
    const remainingZeros = "x".repeat(10 - result.length);
    formattedValue = remainingZeros + result;
    setInputRepresentation(formattedValue);
};

    return (
        <>
            <TableContainer component={Paper}>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell>Field</TableCell>
                            <TableCell>status</TableCell>
                            <TableCell>Technique</TableCell>
                            <TableCell sx={{ textAlign: "right" }}>Action</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {maskingData.map((mask, index) => (
                            <TableRow key={index}>
                                <TableCell style={{ color: '#0889FF' }}>{mask.field_lable}</TableCell>
                                <TableCell style={{ color: '#51921F', fontWeight: 700 }}>{mask.data_rule.technique.toLowerCase()}</TableCell>
                                <TableCell style={{ color: '#51921F', fontWeight: 700 }}>{mask.status}</TableCell>
                                <TableCell sx={{ textAlign: "right" }}>

                                    <img
                                        src="/edit.png"
                                        alt=""
                                        width={16}
                                        style={{ "cursor": "pointer" }}
                                        onClick={() => handleClickOpen(mask)}
                                    />
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
            <Dialog
                className='dialogMasking'
                open={open}
                onClose={handleClose}
                error={error}
                action={action}
                selection={selection}
                number={number}
                handleChangeCheck={handleChangeCheck}
                handleChange={handleChange}
                handleNumberChange={handleNumberChange}
                handleSubmit={handleSubmit}

            >
                <DialogTitle id="alert-dialog-title" sx={{ display: "flex", justifyContent: "space-between", alignItems: "center", }}>
                    {singleMaskingData.field_lable}
                    <IconButton aria-label="Close" onClick={handleClose}>
                        <CloseIcon />
                    </IconButton>
                </DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        <Stack direction='row' spacing={2}>
                            <FormControl>
                                <FormLabel id="demo-row-radio-buttons-group-label">Action</FormLabel>
                                <RadioGroup
                                    row
                                    aria-labelledby="demo-row-radio-buttons-group-label"
                                    name="row-radio-buttons-group"
                                    value={singleMaskingData.status}
                                    onChange={handleChangeCheck}
                                >
                                    <FormControlLabel value="active" control={<Radio />} label="Active" />
                                    <FormControlLabel value="inactive" control={<Radio />} label="InActive" />
                                </RadioGroup>
                            </FormControl>
                        </Stack>
                        <FormControl fullWidth sx={{ marginTop: "16px" }}>
                            <InputLabel id="demo-simple-select-label" required>Select Any One</InputLabel>
                            <Select
                                labelId="demo-simple-select-label"
                                id="demo-simple-select"
                                value={selection}
                                label="Select Any One"
                                onChange={handleChange}
                            >
                                <MenuItem value="">
                                    <em>None</em>
                                </MenuItem>
                                <MenuItem value="MASKING">Masking</MenuItem>
                                <MenuItem value="ENCRYPTION">Encryption</MenuItem>
                            </Select>
                        </FormControl>
                        {error && <div style={{ color: 'red', marginTop: '8px' }}>{error}</div>}
                        {selection === "MASKING" && (
                            <FormControl fullWidth sx={{ marginTop: "16px" }}>

                                <input
                                    className='inputfield'
                                    type="number"
                                    name="number"
                                    min="1"
                                    max="9"
                                    value={singleMaskingData.data_rule.rule_value}
                                    onChange={handleNumberChange}
                                />
                                <div style={{ marginTop: '8px', color: '#51921F', fontWeight: 700 }}>ex: {inputRepresentation}</div>

                            </FormControl>
                        )}

                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button className='contactBtn2' onClick={handleSubmit}>Submit</Button>
                </DialogActions>
            </Dialog>
        </>
    )
}