import React, { useEffect, useState } from 'react';
import TextField from '@mui/material/TextField';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import { Link, Navigate, useLocation, useNavigate } from 'react-router-dom';
import axios from 'axios';
import { object } from 'yup';
import { AuthApi } from '../service';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Helpers } from '../service';
import { UserApi } from '../service/axios';
import { styled, alpha } from '@mui/material/styles';



const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: 'center',
  color: theme.palette.text.secondary,
}));



const customTheme = createTheme({
  palette: {
    primary: {
      main: '#E27606', // Define your custom color here
    },
  },
});

const logo = require('../img/belnotifire.png');

export default function Register() {
  const [formData, setFormData] = useState({
    whatsapp_business_token: '',
    whatsapp_business_account_id: '',
    app_id: '',
    whatsapp_mobile_no: '',
  });

  const [errors, setErrors] = useState({});
  const [valid, setValid] = useState(true);
  const [roleData, setRoleData] = useState([]);
  const [buttonInfo, setButtonInfo] = useState('Sign Up');
  const [no, setNoId] = useState('');
  const navigate = useNavigate();
  const { state } = useLocation();


  useEffect(() => {
    if (state) {
      setButtonInfo('Update');
      setNoId(state);
      getNoDeatail(state);
    }
  }, []);

  const getNoDeatail = (id) => {
    UserApi.getAssociateWhatsappNumberwithId(id).then(
      (resp) => {
        if (resp.status === 200) {
          const updatedData = {
            whatsapp_business_token: resp.data.associated_no.whatsapp_business_token,
            whatsapp_business_account_id: resp.data.associated_no.whatsapp_business_account_id,
            app_id: resp.data.associated_no.app_id,
            whatsapp_mobile_no: resp.data.associated_no.whatsapp_mobile_no,
          };
          setFormData(updatedData);
        } else {
          console.log("error", resp.data.error_msg);
          alert(resp.data.error_msg);
        }
      }
    ).catch((error) => {
      Helpers.error401(error);
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (state) {
      UserApi.updateAssociateNumbers(formData, no)
        .then(result => {
          toast.success('Whatsapp no details updated');
          navigate('/dashboard/Configration');
        })
        .catch(err => console.log(err));
    } else {
      UserApi.storeAssociateNumbers(formData)
        .then(result => {
          toast.success('Whatsapp no configured');
          navigate('/dashboard/Configration');
        })
        .catch(err => console.log(err));
    }
  };

  return (
    <>
      <div style={{
        "background": "#fff",
        "margin": "10px 0px 0px",
        "border-radius": "9px",
        "box-shadow": "0px 3px 1px 0px #EBEBEB",
        "padding": "24px",

      }}>
        <Box sx={{ width: '100%' }}>
          <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
            <Grid item xs={6} mt={10}>
              <Item className='inneritems'>
                <img src='/1.png' />
              </Item>
            </Grid>
            <Grid item xs={6}>
              <Item className='inneritems'>
                <ThemeProvider theme={customTheme}>
                  <div>
                    <ToastContainer />
                    <form className="condition" autoComplete="off" onSubmit={handleSubmit}>
                      <Box
                        className="loginbody2"
                        sx={{
                          my: 8,
                          mx: 4,
                          display: 'flex',
                          flexDirection: 'column',
                          alignItems: 'center',
                        }}
                      >
                        <img src={logo} alt="Logo" style={{ width: "210px", paddingBottom: "30px", marginTop: "20px" }} />

                        <Box sx={{ mt: 1 }}>
                          <Grid container spacing={2}>
                            <Grid item xs={12}>

                              <TextField
                                fullWidth
                                label="Whatsapp Mobile No"
                                name="whatsapp_mobile_no"
                                variant="outlined"
                                value={formData.whatsapp_mobile_no}
                                onChange={(e) => setFormData({ ...formData, whatsapp_mobile_no: e.target.value })}
                                required
                              />
                            </Grid>
                            <Grid item xs={12}>
                              <TextField
                                fullWidth
                                label="Whatsapp Business Token"
                                name="whatsapp_business_token"
                                variant="outlined"
                                value={formData.whatsapp_business_token}
                                onChange={(e) => setFormData({ ...formData, whatsapp_business_token: e.target.value })}
                                required
                              />
                            </Grid>
                            <Grid item xs={12}>
                              <TextField
                                fullWidth
                                label="Whatsapp Business Account Id"
                                name="whatsapp_business_account_id"
                                variant="outlined"
                                value={formData.whatsapp_business_account_id}
                                onChange={(e) => setFormData({ ...formData, whatsapp_business_account_id: e.target.value })}
                                required
                              />
                            </Grid>
                            <Grid item xs={12} >
                              <TextField
                                fullWidth
                                label="Whatsapp App Id"
                                name="app_id"
                                variant="outlined"
                                value={formData.app_id}
                                onChange={(e) => setFormData({ ...formData, app_id: e.target.value })}
                                required
                              />
                            </Grid>
                          </Grid>
                          <div className={Helpers.cmAccess('ADMIN_CONFIGURATION_EDIT_UPDATE')}>
                          <Button
                           className={`btnbig`}
                            type="submit"
                            fullWidth
                            variant="contained"
                            color="primary"
                            sx={{ mt: 3, mb: 2 }}
                          // onClick={handleSubmit}
                          >
                            {buttonInfo}
                          </Button>
                          </div>
                        </Box>
                      </Box>
                    </form>
                  </div>
                </ThemeProvider>
              </Item>
            </Grid>
          </Grid>
        </Box>
        {/* <ThemeProvider theme={customTheme}>
          <div>
            <ToastContainer />
            <form className="condition" autoComplete="off" onSubmit={handleSubmit}>
              <Box
                className="loginbody"
                sx={{
                  my: 8,
                  mx: 4,
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                }}
              >
                <img src={logo} alt="Logo" style={{ width: "210px", paddingBottom: "30px", marginTop: "20px" }} />

                <Box sx={{ mt: 1 }}>
                  <Grid container spacing={2}>
                    <Grid item xs={12}>

                      <TextField
                        fullWidth
                        label="Whatsapp Mobile No"
                        name="whatsapp_mobile_no"
                        variant="outlined"
                        value={formData.whatsapp_mobile_no}
                        onChange={(e) => setFormData({ ...formData, whatsapp_mobile_no: e.target.value })}
                        required
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <TextField
                        fullWidth
                        label="Whatsapp Business Token"
                        name="whatsapp_business_token"
                        variant="outlined"
                        value={formData.whatsapp_business_token}
                        onChange={(e) => setFormData({ ...formData, whatsapp_business_token: e.target.value })}
                        required
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <TextField
                        fullWidth
                        label="Whatsapp Business Account Id"
                        name="whatsapp_business_account_id"
                        variant="outlined"
                        value={formData.whatsapp_business_account_id}
                        onChange={(e) => setFormData({ ...formData, whatsapp_business_account_id: e.target.value })}
                        required
                      />
                    </Grid>
                    <Grid item xs={12} >
                      <TextField
                        fullWidth
                        label="Whatsapp App Id"
                        name="app_id"
                        variant="outlined"
                        value={formData.app_id}
                        onChange={(e) => setFormData({ ...formData, app_id: e.target.value })}
                        required
                      />
                    </Grid>
                  </Grid>
                  <Button
                    className="btnbig"
                    type="submit"
                    fullWidth
                    variant="contained"
                    color="primary"
                    sx={{ mt: 3, mb: 2 }}
                  // onClick={handleSubmit}
                  >
                    {buttonInfo}
                  </Button>
                </Box>
              </Box>
            </form>
          </div>
        </ThemeProvider> */}
      </div>
    </>
  );
}
