import React from 'react';
import { useNavigate } from 'react-router-dom';
import { useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import { Typography, Stack, CircularProgress } from '@mui/material';
import { Helpers } from '../service';
import { UserApi } from '../service/axios';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper } from '@mui/material';

const SimpleTable = () => {
  const navigate = useNavigate();
  const [roleData, setRoleData] = useState([]);
  const [roleid, setRoleId] = useState('');
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const userInfoString = localStorage.getItem('userInfo');
    const userInfo = JSON.parse(userInfoString);
    getRolls();
  }, []);


  const getRolls = () => {
    UserApi.getRolls().then(
      (resp) => {
        if (resp.status === 200) {
          setRoleData(resp.data.data);
        } else {
          alert(resp.data.error_msg);
        }
      }
    ).catch((error) => {
      Helpers.error401(error);
    })
      .finally(() => {
        setLoading(false);
      });
  };

  const getAccessRole = (id) => {
    navigate('/dashboard/Accessrole', { replace: true, state: id });
  };


  return (
    <>
      {loading ? (
        <Box display="flex" justifyContent="center" alignItems="center" height="300px">
          <CircularProgress />
        </Box>
      ) : (
        <TableContainer component={Paper}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Roles</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {roleData.map((role) => (
                <TableRow key={role.role_id} style={{ cursor: "pointer" }} onClick={() => getAccessRole(role.role_id)} className={Helpers.cmAccess('ADMIN_ACCESS_VIEW')}>
                  <TableCell>{role.role_name}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      )}
    </>
  );
};

export default SimpleTable;
