import React from 'react';
import Router from './routes';
// import ScrollToTop from './layouts/ScrollToTop';

export default function App() { 
  return ( 
    <>
    {/* <ScrollToTop /> */}
    <Router />
    </>
  );
}
