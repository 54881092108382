import * as React from 'react';
import { useState, useEffect } from 'react';
import TextField from '@mui/material/TextField';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import Button from '@mui/material/Button';
import FormControl from '@mui/material/FormControl';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Fade from '@mui/material/Fade';
import Container from '@mui/material/Container';
import Slide from '@mui/material/Slide';
import Grid from '@mui/material/Grid';
import { Link, useNavigate } from 'react-router-dom';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import { AuthApi, Helpers } from '../service/index';
import InputAdornment from '@mui/material/InputAdornment';
import IconButton from '@mui/material/IconButton';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';


import '../css/style.css';




const images = ['scr1m.png', 'scr2m.png', 'scr3m.png'];
const imagess = ['scr1l.png', 'scr2l.png', 'scr3l.png'];


const customTheme = createTheme({
  palette: {
    primary: {
      main: '#E27606', // Define your custom color here
    },
  },
});

const logo = require('../img/belnotifire.png');
export default function Login() {


  const [index, setIndex] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      setIndex((prevIndex) => (prevIndex + 1) % imagess.length);
    }, 6000);

    return () => clearInterval(interval);
  }, []);

  useEffect(() => {
    const interval = setInterval(() => {
      setIndex((prevIndex) => (prevIndex + 1) % imagess.length);
    }, 6000);

    return () => clearInterval(interval);
  }, []);



  const [showPassword, setShowPassword] = useState(false);
  const [loading, setLoading] = useState(false);
  const [rememberMe, setRememberMe] = useState(false);
  const [errors, setErrors] = useState({ 'key': false, 'message': '' });
  const navigate = useNavigate();

  // const handleSuccessfulLogin = () => {
  //   // Display a success toast
  //   toast.success('Login successful!', {
  //     position: 'top-right',
  //     autoClose: 3000, // Close the toast after 3 seconds
  //     hideProgressBar: false,
  //     closeOnClick: true,
  //     pauseOnHover: true,
  //     draggable: true,
  //   });
  // };
  const handleSuccessfulLogin = () => {
    // Display a success toast
    toast.success('Login successful!', {
      position: 'top-right',
      autoClose: 3000, // Close the toast after 3 seconds
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
    });
  };

  const handleFailedLogin = (errorMessage) => {
    // Display an error toast with the provided error message
    toast.error(errorMessage, {
      position: 'top-right',
      autoClose: 3000, // Close the toast after 3 seconds
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
    });
  };



  useEffect(() => {
    const storedRememberMe = localStorage.getItem('rememberMe');
    setRememberMe(storedRememberMe === 'true');
  }, []);

  const handleSubmit = (event) => {
    setErrors({ 'key': '', 'message': '' });
    event.preventDefault();

    try {
      const data = new FormData(event.currentTarget);
      const formData = new FormData();

      if (!data.get('email')) {
        throw new Error(JSON.stringify({ 'key': 'email', 'message': 'email required' }));
      }

      if (!data.get('password')) {
        throw new Error(JSON.stringify({ 'key': 'password', 'message': 'password required' }));
      }

      if(!data.get('company')){
        throw new Error(JSON.stringify({ 'key': 'company', 'message': 'company name required' }));
      }

      formData.append("email", data.get('email'));
      formData.append("password", data.get('password'));
      formData.append("company", data.get('company'));

      setLoading(true);
      AuthApi.login(formData).then(
        (resp) => {
          if (resp.status === 200) {
            setLoading(false);
            Helpers.createLSByName("token", resp.data.token);
            Helpers.createLSByName("userInfo", resp.data.user);
            Helpers.createLSByName("institution_id", resp.data.user.institution_id);
            Helpers.createLSByName("selectedWhatsappNumberId",resp.data.associated_no[0].id);
            Helpers.getComponent(resp.data.user.role_id);

            handleSuccessfulLogin();

            if (rememberMe) {
              localStorage.setItem('rememberMe', 'true');
            } else {
              localStorage.removeItem('rememberMe');
            }
          } else {
            console.log("Login Error:", resp);

            if (resp.status === 401) {
              setErrors({ 'key': 'common', 'message': 'Invalid email or password' });
              handleFailedLogin('Invalid email or password');
            } else {
              setErrors({ 'key': 'common', 'message': 'An error occurred during login' });
              handleFailedLogin('An error occurred during login');
            }
          }
        }
      ).catch((error) => {
        setLoading(false);

        if (error.response && error.response.status === 400) {
          if (!error.response.data.validator) {
            setErrors({ 'key': 'common', 'message': error.response.data.error_msg });
          } else {
            setErrors({ 'key': 'common', 'message': error.response.data.error_msg[0] });
          }
        }
      });
    } catch (error) {
      const errorData = JSON.parse(error.message);
      setErrors({ 'key': errorData.key, 'message': errorData.message });
    }
  };


  return (
    <ThemeProvider theme={customTheme}>

      <Grid container spacing={2}>
        <Grid item xs={7} style={{background: "linear-gradient(rgb(255 102 0 / 14%) 0%, rgb(255 102 0 / 0%) 100%)",height: "100vh",padding: 0,}}>
          <div>
          <img src="/shape1.png" className="shapess" />
            
          </div>
          <div className="textmainimg">
            <h2>Welcome To Bel N<span className="notifireee"><img src="/bell_fire2.gif" /></span>tifier</h2>
            {/* <p>Lorem Isum is a dummy text for all dummy websites.</p> */}
          </div>
          <div className="relativemain">

            <img src="/login1.png" className="imglogin" />
            <img src="/login2.png" className="imglogin2" />
            
            <div className="mokeup image-container">
            <Container>
      {images.map((image, i) => (
        <Fade
        key={i}
        in={i === index}
        timeout={3000}
        mountOnEnter
        unmountOnExit
          className="imglogin3"
          style={{transition:"all 0.3s ease-in-out"}}
        >
          <div>
            <img style={{ width: "100%",transition:"all 0.3s ease-in-out"}} src={image} alt={`Slide ${i + 1}`}  />
          </div>
        </Fade>
      ))}
    </Container>
    <Container>
      {imagess.map((imagesss, i) => (
        <Fade
        key={i}
        in={i === index}
        timeout={3000}
        mountOnEnter
        unmountOnExit
          className="imglogin4"
          style={{transition:"all 0.3s ease-in-out"}}
        >
          <div>
            <img style={{ width: "100%",transition:"all 0.3s ease-in-out"}} src={imagesss} alt={`Slide ${i + 1}`}  />
          </div>
        </Fade>
      ))}
    </Container>
            </div>
          </div>
          
        </Grid>
        <Grid item xs={5}>
        <div>
        <form style={{ paddingTop: "20px" }} className='condition' noValidate onSubmit={handleSubmit}>
        <img src={logo} alt="Logo" className="logmainlogin" style={{ width: "170px", paddingBottom: "0px", marginTop: "20px" }} />
          <Box
            className="login"
            sx={{
              my: 8,
              mx: 4,
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              justifyContent: "center"
            }}
          >
          
        
            <h3 className="textlogin">Login</h3>
            {/* <p className="loginpera">Lorem ipsum is a dummy text.</p> */}
            <Box component="div" sx={{ mt: 1 }}>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <label className="labelLogin">Email ID <span style={{color:"red"}}>*</span></label>
                  <input
                  className="custominput"
                    placeholder="Email"
                    fullWidth
                    label="Email"
                    type="email"
                    name="email"
                    variant="outlined"
                    id="email"
                    error={Boolean(errors.key === 'email' ? true : false)}
                    helperText={(Boolean(errors.key === 'email' ? true : false)) ? errors?.message : ""}
                  />
                </Grid>
                <Grid item xs={12}>
                <label className="labelLogin">Password <span style={{color:"red"}}>*</span></label>

                  <input
                  className="custominput"

                    fullWidth
                    label="Password"
                    placeholder="Password"
                    name="password"
                    variant="outlined"
                    id="password"
                    type={showPassword ? 'text' : 'password'}
                    error={Boolean(errors.key === 'password')}
                    helperText={Boolean(errors.key === 'password') ? errors?.message : ''}
                    autoComplete="current-password"
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton
                            onClick={() => setShowPassword(!showPassword)}
                            edge="end"
                          >
                            {showPassword ? <VisibilityOff /> : <Visibility />}
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                  />
                </Grid>
                <Grid item xs={12}>
                <label className="labelLogin">Company Name <span style={{color:"red"}}>*</span></label>

                  <input
                  className="custominput"
                  placeholder="Company Name"
                    fullWidth
                    label="Company Name"
                    name="company"
                    variant="outlined"
                    id="company"
                    error={Boolean(errors.key === 'company'
                    // ? true : false
                    )}
                    // helperText={(Boolean(errors.key === 'company' ? true : false)) ? errors?.message : ""}
                  />
                </Grid>
              </Grid>
             
              <Button
                className="btnbig"
                type="submit"
                fullWidth
                variant="contained"
                color="primary" // Use your custom color defined in the theme
                sx={{ mt: 3, mb: 2 }}

              >
                Sign in
              </Button>
              <Link to="/forgotpass" className="forgetpassword" style={{ textDecoration: "none", color: "#4096ee" }}>
                  Forgot Password?
                </Link>
              {/* <Typography variant="body2" color="text.secondary" align="center" style={{ fontSize: "16px" }}>
                New on BelNotifier? &nbsp;<Link to="/registerform" style={{ textDecoration: "none", color: "#E27606" }}>Create your free account</Link>
              </Typography> */}
              {/* Display common error message */}
              <Typography variant="body2" color="error" align="center" style={{ fontSize: "16px" }}>
                {errors.key === 'common' && errors.message}
              </Typography>
            </Box>
          </Box>
          {/* </Grid> */}
        </form>
        {/* Toast Container for displaying notifications */}
        <ToastContainer />
      </div>
        </Grid>
      </Grid>

    </ThemeProvider>
  );
}