import { Navigate, useRoutes } from "react-router-dom";
import SideNavBar from "./Layouts/SideNavBar";
import Dashboard from "./pages/Dashboard";
import DashboardLayout from "./Layouts/DashboardLayout";
import MassageTemplate from "./pages/MassageTemplate";
import MassageTemplateForm from "./pages/MassageTemplateForm";
import MassageTemplateFormEdit from "./pages/MassageTemplateFormEdit";
import Login from "./pages/Login";
import RegisterForm from "./pages/RegisterForm";
import WhatsappNoConfig from "./pages/WhatsappNoConfig";
import Forgotpass from "./pages/Forgotpass";
import ContactUs from "./pages/ContactUs";
import Addcontact from "./pages/Addcontact";
import Notification from "./pages/Notification";
import AddNotification from "./pages/AddNotification";
import Analytics from "./pages/Analytics";
import Userdashboard from "./pages/Userdashboard";
import ChatsScreen from "./pages/ChatsScreen";
import Notificationdetail from "./pages/Notificationdetail";
import TemplateView from "./pages/TemplateView";
import Access from "./pages/Access";
import Configration from "./pages/Configration";
import Admin from "./pages/Admin";
import Userlist from "./pages/Userlist";
import Accessrole from "./pages/Accessrole";
import Campaign from "./pages/Campaign";
import Campaigndetail from "./pages/Campaigndetail";
import Registerphone from "./pages/Registerphone";
import Setupcredentials from "./pages/Setupcredentials";
import DataPrivacy from "./pages/DataPrivacy";
import RegisterWhatsappNumber from "./pages/RegisterWhatsappNumber";

export default function Router() {
  return useRoutes([
    {
      path: "/dashboard",
      element: <DashboardLayout />,
      children: [
        { path: '', element: <Navigate to="/login" replace /> },
        
        { path: "app", element: <Dashboard /> },
        { path: "massagetemplate", element: <MassageTemplate /> },
        { path: "massagetemplate/massagetemplateform", element: <MassageTemplateForm /> },
        { path: "massagetemplate/massagetemplateformedit", element: <MassageTemplateFormEdit /> },
        { path: "contactus", element: <ContactUs /> },
        { path: "contactus/addcontact", element: <Addcontact /> },
        { path: "notification", element: <Notification /> },
        { path: "notification/addnotification", element: <AddNotification /> },
        { path: "analytics", element: <Analytics /> },
        { path: "contactus/userdashboard", element: <Userdashboard /> },
        { path: "analytics/chatsscreen", element: <ChatsScreen /> },
        { path: "massagetemplate/templateview", element: <TemplateView /> },
        { path: "admin/access", element: <Access /> },
        { path: "admin/configration", element: <Configration /> },
        { path: "admin", element: <Admin /> },
        { path: "admin/whatsappnoconfig", element: <WhatsappNoConfig /> },
        { path: "admin/userlist", element: <Userlist /> },
        { path: "accessrole", element: <Accessrole /> },
        { path: 'admin/registerform', element: <RegisterForm /> },
        { path: "notification/notificationdetail", element: <Notificationdetail /> },
        { path: 'campaign', element: <Campaign /> },
        { path: 'registerphone', element: <Registerphone /> },
        { path: 'campaign/campaigndetail', element: <Campaigndetail /> },
        { path: 'admin/whatsappnumber/setup', element: <Setupcredentials /> },
        { path: 'admin/dataprivacy', element: <DataPrivacy /> },
        { path: 'admin/whatsappnumber', element: <RegisterWhatsappNumber /> },

      ]
    },
    {
      path: '/',
   //   element: <LogoOnlyLayout />,
      children: [
        { path: '', element: <Login /> },
        { path: 'login', element: <Login /> },
        { path: 'forgotpass', element: <Forgotpass /> },
       
      ]
    },
    { path: '*', element: <Navigate to="/404" replace /> }
  ]);
}