import React from 'react';
import { Grid, Card, CardContent, Typography, CardMedia } from '@mui/material';
import { Link } from 'react-router-dom';
import { Helpers } from '../service';

const ThreeColumnLayout = () => {
  return (
    <Grid container spacing={3} style={{ marginTop: "10px", }} mb={4}>
      <Grid item xs={12} sm={4}>
        <Card sx={{height:"100%"}}>
          <Link to="/dashboard/admin/Access" className={`link-style ${Helpers.cmAccess('ADMIN_ACCESS')}`}>
            <CardMedia
              component="img"
              className="img-main-access"
              alt="Image 1"
              src="/password.png" 
            />
            <CardContent>
              <Typography variant="h5" component="div" className="headingaced">
                Access
              </Typography>
              <Typography variant="body2" color="text.secondary">
                The Role-Based Access Control (RBAC) Administration System is a user-friendly application that empowers administrators to efficiently manage access permissions based on user roles within an organization. With an intuitive dashboard, administrators can easily create, modify, and delete roles, assign permissions, and track access changes through a comprehensive audit trail. The system offers customizable workflows, notification alerts, and seamless integration with existing systems, ensuring a secure and compliant access control environment. This scalable solution is designed to streamline user onboarding, enhance security, and provide a centralized hub for monitoring and managing access across the organization.
              </Typography>
            </CardContent>
          </Link>
        </Card>
      </Grid>

      <Grid item xs={12} sm={4}>
        <Card sx={{height:"100%"}}>
          <Link to="/dashboard/admin/Configration" className={`link-style ${Helpers.cmAccess('ADMIN_CONFIGURATION')}`}>
            <CardMedia
              component="img"
              className="img-main-access"
              alt="Image 2"
              src="/project-management.png" 
            />
            <CardContent>
              <Typography variant="h5" component="div" className="headingaced">
                Configuration
              </Typography>
              <Typography variant="body2" color="text.secondary">

                Introducing our Mobile Registration and Credential Management Menu, designed for effortless integration with the WhatsApp Business platform. Users can easily register their mobile number, manage business details, and update credentials in real-time. The user-friendly dashboard, coupled with robust security measures and instant notifications, ensures a seamless and professional engagement with customers on WhatsApp.
              </Typography>
            </CardContent>
          </Link>
        </Card>
      </Grid>

      <Grid item xs={12} sm={4}>
        <Card sx={{height:"100%"}}>
          <Link to="/dashboard/admin/Userlist" className={`link-style ${Helpers.cmAccess('ADMIN_USER_LIST')}`}>
            <CardMedia
              component="img"
              className="img-main-access"
              alt="Image 3"
              src="/listing.png" 
            />
            <CardContent>
              <Typography variant="h5" component="div" className="headingaced">
                User List
              </Typography>
              <Typography variant="body2" color="text.secondary">
                Introducing the User Management Menu, a versatile tool empowering administrators to efficiently handle user accounts within an organization. With this feature-rich menu, administrators can seamlessly add, edit, and customize user details based on evolving requirements. The user-friendly interface ensures quick and intuitive navigation for administrators to perform tasks such as updating user information, changing access levels, and managing user roles. The menu also provides a comprehensive list of all users, allowing administrators to easily track and review the entire user base.
              </Typography>
            </CardContent>
          </Link>
        </Card>
      </Grid>
      <Grid item xs={12} sm={4}>
        <Card sx={{height:"100%"}}>
          <Link to="/dashboard/admin/dataprivacy" className={`link-style ${Helpers.cmAccess('ADMIN_USER_LIST')}`}>
            <CardMedia
              component="img"
              className="img-main-access"
              alt="Image 3"
              src="/safety.png" 
            />
            <CardContent>
              <Typography variant="h5" component="div" className="headingaced">
                Data Privacy
              </Typography>
              <Typography variant="body2" color="text.secondary">
              Data masking and Encryption is particularly beneficial in scenarios where sensitive data needs to be protected not only during non-production use (data masking) but also when stored or transmitted (encryption).
              </Typography>
            </CardContent>
          </Link>
        </Card>
      </Grid>
    </Grid>
  );
};

export default ThreeColumnLayout;
