import * as React from 'react';
import { useState, useEffect } from 'react';
import { NavLink } from 'react-router-dom';
import {
    Box,
    Button,
    Grid,
    Paper,
    Stack,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Typography,
} from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import SearchIcon from '@mui/icons-material/Search';
import InputBase from '@mui/material/InputBase';
import { styled, alpha } from '@mui/material/styles';
import { Link } from 'react-router-dom';
import { Helpers } from '../service';
import { UserApi } from '../service/axios';
import CircularProgress from '@mui/material/CircularProgress';

const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
    ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: 'center',
    color: theme.palette.text.secondary,
}));

const Search = styled('div')(({ theme }) => ({
    position: 'relative',
    display: 'flex',
    borderRadius: theme.shape.borderRadius,
    backgroundColor: alpha(theme.palette.common.white, 0.15),
    '&:hover': {
        backgroundColor: alpha(theme.palette.common.white, 0.25),
    },
    marginRight: theme.spacing(2),
    marginLeft: 0,
    width: '100%',
    [theme.breakpoints.up('sm')]: {
        marginLeft: theme.spacing(3),
        width: 'auto',
    },
}));

const SearchIconWrapper = styled('div')(({ theme }) => ({
    padding: theme.spacing(0, 2),
    height: '100%',
    position: 'absolute',
    pointerEvents: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
}));

const StyledInputBase = styled(InputBase)(({ theme }) => ({
    color: 'inherit',
    '& .MuiInputBase-input': {
        padding: theme.spacing(1, 1, 1, 0),
        paddingLeft: `calc(1em + ${theme.spacing(4)})`,
        transition: theme.transitions.create('width'),
        width: '100%',
        [theme.breakpoints.up('md')]: {
            width: '32ch',
            border: '1px solid #E9E9E9',
            borderRadius: '6px',
            fontSize: '14px',
        },
        [theme.breakpoints.up('sm')]: {
            border: '1px solid #E9E9E9',
            borderRadius: '6px',
            fontSize: '14px',
            backgroundColor: '#fff',
            height: '1.5em',
        },
        [theme.breakpoints.up('xs')]: {
            border: '1px solid #E9E9E9',
            borderRadius: '6px',
            fontSize: '14px',
            backgroundColor: '#fff',
            height: '1.5em',
        },
    },
}));

const Notification = () => {
    const [reportData, setReportData] = useState([]);
    const [templateMapping, setTemplateMapping] = useState({});
    const [searchTerm, setSearchTerm] = useState('');
    const [loading, setLoading] = useState(true);
    const calculateTotalPercentage = (row) => {
        const { sent, delivered, read, pending, failed } = row;
        const total = sent + delivered + read + pending + failed;

        if (total === 0) {
            return {
                sent: '0.00%',
                delivered: '0.00%',
                read: '0.00%',
                pending: '0.00%',
                failed: '0.00%',
            };
        }

        const sentPercentage = ((sent / total) * 100).toFixed(2) + '%';
        const deliveredPercentage = ((delivered / total) * 100).toFixed(2) + '%';
        const readPercentage = ((read / total) * 100).toFixed(2) + '%';
        const pendingPercentage = ((pending / total) * 100).toFixed(2) + '%';
        const failedPercentage = ((failed / total) * 100).toFixed(2) + '%';

        return {
            sent: sentPercentage,
            delivered: deliveredPercentage,
            read: readPercentage,
            pending: pendingPercentage,
            failed: failedPercentage,
        };
    };

    const calculateTotal = (row) => {
        const { read, sent, failed, pending, delivered } = row;
        return read + sent + failed + pending + delivered;
    };

    useEffect(() => {
        // Fetch data from the API
        const fetchData = async () => {

            try {
                const resp = await UserApi.getTemplateReport()
                if (resp.status === 200) {
                    const mapping = {};
                    resp.data.template.forEach((template) => {
                        mapping[template.id] = {
                            name: template.name,
                            status: template.status,
                            created_at: template.created_at,
                        };
                    });
                    setTemplateMapping(mapping);
                    setReportData(resp.data.report);
                } else {
                    console.error('Error getting template:', resp.data.error_msg);
                    alert(resp.data.error_msg);
                }
                // const response = await fetch('https://belnotifierbec.smartmanager.in/api/getReportSendTemplate');
                // const data = await response.json();

                // // Create a mapping between template_id and template information
                // const mapping = {};
                // data.template.forEach((template) => {
                //     mapping[template.id] = {
                //         name: template.name,
                //         status: template.status,
                //         created_at: template.created_at,
                //     };
                // });

                // setTemplateMapping(mapping);
                // setReportData(data.report);
            } catch (error) {
                console.error('Error fetching data:', error);
            } finally {
                setLoading(false); 
              }
        };

        fetchData();
    }, []);

    const handleSearchChange = (event) => {
        setSearchTerm(event.target.value);
    };

    // Filter the report data based on the search term
    const filteredReportData = reportData.filter((row) =>
        templateMapping[row.template_id]?.name.toLowerCase().includes(searchTerm.toLowerCase()) || templateMapping[row.template_id]?.status.toLowerCase().includes(searchTerm.toLowerCase())
    );

    return (
        <>
        {loading ? (
        <div style={{ textAlign: 'center', paddingTop: '150px' }}>
          <CircularProgress />
        </div>
      ) : (
            <div style={{
                background: '#fff',
                margin: '24px 0px 0px',
                borderRadius: '9px',
                boxShadow: '0px 3px 1px 0px #EBEBEB',
                padding: '24px',
            }}>
                <Box sx={{ width: '100%' }}>
                    <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
                        <Grid item xs={4}>
                            <Item className='inneritems'>
                                <h4 className='Templateheading2'>Notification</h4>
                            </Item>
                        </Grid>
                        <Grid item xs={8}>
                            <Item className='inneritems' sx={{ "padding": "0px", "float": "inline-end", }}>
                                <Stack direction='row' spacing={2}>
                                    <NavLink to="/dashboard/notification/addnotification" className={Helpers.cmAccess('NOTIFICATION_ADD_NEW')}>
                                        <Button variant="outlined" className='contactBtn' startIcon={<AddIcon />}>
                                            Send
                                        </Button>
                                    </NavLink>
                                    <div className={Helpers.cmAccess('NOTIFICATION_SEARCH')}>
                                        <Search sx={{ "padding": "0px", }}>
                                            <SearchIconWrapper style={{ color: "#757575", "zIndex": "99", "top": "0px", }}>
                                                <SearchIcon style={{ fontSize: 16, }} />
                                            </SearchIconWrapper>
                                            <StyledInputBase
                                                placeholder="Search…"
                                                inputProps={{ "aria-label": "search" }}
                                                value={searchTerm}
                                                onChange={handleSearchChange}
                                            />
                                        </Search>
                                    </div>
                                </Stack>
                            </Item>
                        </Grid>
                    </Grid>
                </Box>
                <Box sx={{ width: '100%' }}>
                    <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
                        <Grid item xs={12}>
                            <Item className='inneritems'>
                                <TableContainer className={Helpers.cmAccess('NOTIFICATION_TABLE')}>
                                    <Table className='Notificationtable' sx={{ minWidth: '100%' }} aria-label="simple table">
                                        <TableHead>
                                            <TableRow>
                                                <TableCell>Title</TableCell>
                                                <TableCell>Status</TableCell>
                                                <TableCell>Total</TableCell>
                                                <TableCell>Sent</TableCell>
                                                <TableCell>Delivered</TableCell>
                                                <TableCell>Read</TableCell>
                                                <TableCell>Pending</TableCell>
                                                <TableCell>Failed</TableCell>
                                                <TableCell>Created</TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {filteredReportData.map((row) => (
                                                <TableRow key={row.template_id}>
                                                    <TableCell>
                                                        <NavLink to="/dashboard/notification/notificationdetail" style={{ "background-color": "transparent", "text-decoration": "none", "color": "#0889FF" }} component={Link} replace={true} state={row.template_id} className={Helpers.cmAccess('NOTIFICATION_VIEW')}>
                                                            {templateMapping[row.template_id]?.name || 'Unknown'}
                                                        </NavLink>
                                                    </TableCell>
                                                    <TableCell>{templateMapping[row.template_id]?.status || 'Unknown'}</TableCell>
                                                    <TableCell>{calculateTotal(row)}(100%)</TableCell>
                                                    <TableCell>{row.sent} ({calculateTotalPercentage(row).sent})</TableCell>
                                                    <TableCell>{row.delivered} ({calculateTotalPercentage(row).delivered})</TableCell>
                                                    <TableCell>{row.read} ({calculateTotalPercentage(row).read})</TableCell>
                                                    <TableCell>{row.pending} ({calculateTotalPercentage(row).pending})</TableCell>
                                                    <TableCell>{row.failed} ({calculateTotalPercentage(row).failed})</TableCell>
                                                    <TableCell>
                                                        {new Date(templateMapping[row.template_id]?.created_at || 'Unknown').toLocaleDateString('en-GB')}
                                                    </TableCell>
                                                </TableRow>
                                            ))}
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                            </Item>
                        </Grid>
                    </Grid>
                </Box>
            </div>
      )}
        </>
    );
};

export default Notification;
