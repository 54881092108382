import axios from "axios";



let token = "";
const belNotifier = axios.create({
    baseURL: 'https://belnotifierbec.smartmanager.in/api',
    timeout: 60000,
    headers: {"content-type": "multipart/form-data",
}
});

//request interceptor for adding token

belNotifier.interceptors.request.use((config) => {
    
    if(localStorage.getItem('token'))
    {
       token = JSON.parse(localStorage.getItem('token'));
    }
    // add token to request headers
    config.headers.Authorization = `Bearer ${token}`;
    return config;
});

export default belNotifier;