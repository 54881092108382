import { styled, alpha } from '@mui/material/styles';
import Paper from '@mui/material/Paper';
import { useNavigate } from 'react-router-dom';
import { Typography, Grid, Box, Button, Toolbar, List, ListItem, ListItemAvatar, Stack, ListItemText, ListSubheader, Avatar, TableBody, TableCell, TableContainer, TableHead, TableRow, Divider, } from '@mui/material';
import { PieChart, Pie, Cell } from 'recharts';
import CssBaseline from '@mui/material/CssBaseline';
import Fab from '@mui/material/Fab';
import { Link, NavLink } from 'react-router-dom';
import { Helpers } from '../service';
import { UserApi } from '../service/axios';
import React, { useEffect, useState } from 'react';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogActions from '@mui/material/DialogActions';
import CircularProgress from '@mui/material/CircularProgress';


const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: 'center',
  color: theme.palette.text.secondary,
}));

const data = [
  { name: 'Total Attempts', value: 700 },
  { name: 'Sent & Deliver', value: 350 },
  { name: 'Message Sent', value: 200 },
  { name: 'Message Sent', value: 200 },
  { name: 'Message Sent', value: 500 },
];

const COLORS = ['#D2175A', '#FF6600', '#FF9650', '#59BE1B', '#6856D7', '#FF0000'];

const StyledFab = styled(Fab)({
  position: 'absolute',
  zIndex: 1,
  top: -30,
  left: 0,
  right: 0,
  margin: '0 auto',
});
export default function Analytics() {

  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const [chartdata, setchartdata] = useState([]);
  const [groupdata, setgroupdata] = useState([]);
  const [fromDate, setFromDate] = useState('');
  const [toDate, setToDate] = useState('');
  const [total, setTotal] = useState('');
  const [maxToDate, setMaxToDate] = useState('');
  const [templatedata, settemplatedata] = useState([]);
  const [user, setUser] = useState([]);
  const [open, setOpen] = useState(false);
  const [templateid, setTemplateId] = useState();

  useEffect(() => {
    const userInfoString = localStorage.getItem('userInfo');
    const userInfo = JSON.parse(userInfoString);
    setUser(userInfo);
    getReportData();
    getTemplate();
    getGroup();
  }, []);


  const getReportDatawithdate = (from, to) => {
    UserApi.getReportwithdate(from, to).then(
      (resp) => {
        if (resp.status === 200) {
          var delivercount = 0;
          var sentcount = 0;
          var readcount = 0;
          var failedcount = 0;
          var pendingcount = 0;
          if (resp.data.report) {
            for (var i = 0; i < resp.data.report.length; i++) {
              if (resp.data.report[i].status == 'read') {
                readcount++;
              }
              if (resp.data.report[i].status == 'sent') {
                sentcount++;
              }
              if (resp.data.report[i].status == 'delivered') {
                delivercount++;
              }
              if (resp.data.report[i].status == 'failed') {
                failedcount++;
              }
              if (resp.data.report[i].status == 'pending') {
                pendingcount++;
              }
            }
            const data = [
              { name: 'Message Delivered', value: delivercount },
              { name: 'Message Sent', value: sentcount },
              { name: 'Message Failed', value: failedcount },
              { name: 'Message Read', value: readcount },
              { name: 'Message Pending', value: pendingcount },
            ];
            setTotal(delivercount + sentcount + failedcount + readcount + pendingcount);
            setchartdata(data);
            setFromDate(resp.data.from);
            setToDate(resp.data.to);
            handleClose();
          }
        } else {
          alert(resp.data.error_msg);
        }
      }
    ).catch((error) => {
      Helpers.error401(error);
    });
  };

  const getTemplateDetailwithdate = (from, to, id) => {
    UserApi.getReportByTemplatewithdate(from, to, id).then(
      (resp) => {
        if (resp.status === 200) {
          var delivercount = 0;
          var sentcount = 0;
          var readcount = 0;
          var failedcount = 0;
          var pendingcount = 0;
          if (resp.data.report) {
            for (var i = 0; i < resp.data.report.length; i++) {
              if (resp.data.report[i].status == 'read') {
                readcount++;
              }
              if (resp.data.report[i].status == 'sent') {
                sentcount++;
              }
              if (resp.data.report[i].status == 'delivered') {
                delivercount++;
              }
              if (resp.data.report[i].status == 'failed') {
                failedcount++;
              }
              if (resp.data.report[i].status == 'pending') {
                pendingcount++;
              }
            }
            const data = [
              { name: 'Message Delivered', value: delivercount },
              { name: 'Message Sent', value: sentcount },
              { name: 'Message Failed', value: failedcount },
              { name: 'Message Read', value: readcount },
              { name: 'Message Pending', value: pendingcount },
            ];
            setTotal(delivercount + sentcount + failedcount + readcount + pendingcount);
            setchartdata(data);
          }
        } else {
          alert(resp.data.error_msg);
        }
      }
    ).catch((error) => {
      Helpers.error401(error);
    });
  };


  const getTemplate = () => {
    setLoading(true);
    UserApi.getTemplate().then(
      (resp) => {
        if (resp.status === 200) {
          settemplatedata(resp.data.template);
        } else {
          alert(resp.data.error_msg);
        }
      }
    ).catch((error) => {
      Helpers.error401(error);
    })
    .finally(() => {
      setLoading(false);
    });
  };

  const getReportData = () => {
    UserApi.getReport().then(
      (resp) => {
        if (resp.status === 200) {
          var delivercount = 0;
          var sentcount = 0;
          var readcount = 0;
          var failedcount = 0;
          var pendingcount = 0;
          if (resp.data.report) {
            for (var i = 0; i < resp.data.report.length; i++) {
              if (resp.data.report[i].status == 'read') {
                readcount++;
              }
              if (resp.data.report[i].status == 'sent') {
                sentcount++;
              }
              if (resp.data.report[i].status == 'delivered') {
                delivercount++;
              }
              if (resp.data.report[i].status == 'failed') {
                failedcount++;
              }
              if (resp.data.report[i].status == 'pending') {
                pendingcount++;
              }
            }
          }
          const data = [
            // { name: 'Total Attempts', value: delivercount+sentcount+failedcount+readcount+pendingcount },
            { name: 'Message Delivered', value: delivercount },
            { name: 'Message Sent', value: sentcount },
            { name: 'Message Failed', value: failedcount },
            { name: 'Message Read', value: readcount },
            { name: 'Message Pending', value: pendingcount },
          ];
          setTotal(delivercount + sentcount + failedcount + readcount + pendingcount);
          setchartdata(data);
          setFromDate(resp.data.from);
          setToDate(resp.data.to);
          handleClose();
        } else {
          alert(resp.data.error_msg);
        }
      }
    ).catch((error) => {
      Helpers.error401(error);
    });
  };

  const getGroup = () => {
    UserApi.getGroup().then(
      (resp) => {
        if (resp.status === 200) {
          setgroupdata(resp.data.group);

        } else {
          alert(resp.data.error_msg);
        }
      }
    ).catch((error) => {
      Helpers.error401(error);
    });
  };

  const getGroupConversation = (id) => {
    navigate('/dashboard/analytics/chatsscreen', { replace: true, state: id });
  };


  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleFromDateChange = (date) => {
    setFromDate(date);
  };
  const handleToDateChange = (date) => {
    setToDate(date);
  };

  const handleTemplateSelect = (event) => {
    const selectedTemplateId = event.target.value;
    setTemplateId(event.target.value);
  };

  const handleFilterButtonClick = () => {
    if (!fromDate || !toDate) {
      alert('Please select both From Date and To Date');
      return;
    }
    if (!templateid) {
      getReportDatawithdate(fromDate, toDate);
    } else {
      getTemplateDetailwithdate(fromDate, toDate, templateid);
    }
  };

  return (
    <>
      {loading ? ( 
        <div style={{ textAlign: 'center', paddingTop: '150px' }}>
          <CircularProgress />
        </div>
      ) : (
        <div style={{
          // "padding": "20px 20px 0",
          "borderRadius": "10px",
          "marginTop": "10px",
        }}>
          <Box sx={{ width: '100%' }}>
            <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
              <Grid item xs={8}>
                <Item className='griditems'>
                  <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
                    <Grid item xs={12}>
                      <Item className='inneritems'>
                        <Stack spacing={2}>
                          <h2 className='formheading'>Conversations Analytics ({total})</h2>
                        </Stack>
                        <div className="popupfiltermain">
                          <div className={Helpers.cmAccess('CONVERSATIONS_FILTER')}>
                            <Button variant="contained" className={`filterpopup`} onClick={handleClickOpen}>
                              <div><img src="/funnel.svg" className="imgfilter" /></div>
                            </Button>
                          </div>
                          <Dialog open={open} onClose={handleClose}>
                            <DialogTitle>Filter</DialogTitle>
                            <DialogContent>
                              <DialogContentText>
                                <Grid container spacing={2}>
                                  <Grid item xs={6}>
                                    <input type="date" className="Datepicker" value={fromDate} onChange={(e) => handleFromDateChange(e.target.value)} max={maxToDate} />
                                  </Grid>
                                  <Grid item xs={6}>
                                    <input type="date" className="Datepicker" value={toDate} onChange={(e) => handleToDateChange(e.target.value)} min={fromDate} max={maxToDate} />
                                  </Grid>
                                  <Grid item xs={6}>
                                    <select className="Datepicker" onChange={handleTemplateSelect}>
                                      <option selected>
                                      </option>
                                      {templatedata.map(item => (
                                        <option value={item.id}>
                                          {item.name}
                                        </option>
                                      ))}
                                    </select>
                                  </Grid>
                                  <Grid item xs={6} className="textleft">
                                    <Button className="btnfilter" onClick={handleFilterButtonClick}><img src="/funnel.svg" /></Button>
                                  </Grid>
                                </Grid>          </DialogContentText>
                            </DialogContent>
                            <DialogActions>
                              <Button onClick={handleClose} color="primary">
                                Close
                              </Button>
                            </DialogActions>
                          </Dialog>
                        </div>
                        <div style={{ marginTop: "10px" }} className={Helpers.cmAccess('ANALYTICS_GRAPH')}>
                          <PieChart width={550} height={280} style={{ outline: "none" }}>
                            <Pie
                              data={chartdata}
                              dataKey="value"
                              cx={250}
                              cy={135}
                              fill="#8884d8"
                              startAngle={0}
                              endAngle={360}
                              style={{ outline: "none" }}
                              label
                            >
                              {chartdata.map((entry, index) => (
                                <Cell
                                  key={`cell-${index}`}
                                  fill={COLORS[index % COLORS.length]}
                                  style={{ outline: "none" }}
                                />
                              ))}
                            </Pie>
                          </PieChart>
                          <div style={{ marginTop: 20 }}>
                            <ul style={{ "display": "flex", "padding": "10px 45px", "listStyle": "none" }}>
                              {chartdata.map((item, index) => (
                                <li key={index} style={{ "margin-right": "10px", "color": "#000", "display": "flex", "alignItems": "center", "flexDirection": "column", "gap": "8px" }}>
                                  <span style={{ "width": "15px", "height": "15px", "borderRadius": "50%", "background": COLORS[index % COLORS.length], "marginRight": "5px" }}></span>
                                  <Typography sx={{ "fontSize": "10px", "color": "#000", "fontWeight": "500" }}>{item.name}:</Typography><b>{item.value}</b>
                                </li>
                              ))}
                            </ul>
                          </div>
                        </div>
                      </Item>
                    </Grid>
                  </Grid>
                </Item>
              </Grid>
              <Grid item xs={4} className={Helpers.cmAccess('GROUP_LIST')}>
                <Item className='griditems'>
                  <React.Fragment>
                    <CssBaseline />
                    <Paper id='style-4' square sx={{ pb: '40px', boxShadow: "none", textAlign: "left", minHeight: 485, overflow: "auto", height: 485, }}>
                      <Typography className='formheading' variant="h2" gutterBottom component="h2" sx={{ p: 2, pb: 0, fontWeight: "bold" }}>
                        Group List
                      </Typography>
                      <List sx={{ mb: 2 }}>
                        {groupdata.map(({ id, group_name, status, contacts }) => (
                          <React.Fragment key={id}>
                            <Box onClick={() => getGroupConversation(id)} style={{ textDecoration: "none", backgroundColor: "transparent", }} className={Helpers.cmAccess('CHAT_SCREEN')}>
                              <ListItem button sx={{ fontSize: 16, color: "#000", }}>
                                {/* <ListItemAvatar>
                                                                <Avatar alt="Profile Picture" src={person} />
                                                            </ListItemAvatar> */}
                                <ListItemText primary={group_name} secondary={`${status} ${contacts.length}`} />
                              </ListItem>
                            </Box>

                          </React.Fragment>
                        ))}
                      </List>
                    </Paper>
                  </React.Fragment>
                </Item>
              </Grid>
            </Grid>
          </Box>
        </div>
      )}
    </>
  );
}