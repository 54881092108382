import React, { useState, useEffect } from 'react';
import { styled, alpha } from '@mui/material/styles';
import Paper from '@mui/material/Paper';
import {
  Typography,
  Grid,
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  CardMedia,
  Stack,
  Input,
  Autocomplete,
  TextField,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  FormHelperText
} from '@mui/material';
import { useLocation, useNavigate } from 'react-router-dom';
import { UserApi } from '../service/axios';
import { Helpers } from '../service';
import { width } from '@mui/system';
import CheckIcon from '@mui/icons-material/Check';

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: 'center',
  color: theme.palette.text.secondary,
}));

const AddNotification = () => {
  const [templateLoading, setTemplateLoading] = useState(true);
  const navigate = useNavigate();
  const [activeBox, setActiveBox] = useState('Nmarketing');
  const [sendWhen, setSendWhen] = useState('');
  const [contactList, setContactList] = useState([]);
  const [selectedContacts, setSelectedContacts] = useState([]);
  const [messageTemplates, setMessageTemplates] = useState([]);
  const [template, setTemplate] = useState(null);
  const [button, setButton] = useState(null);
  const [editedButtonAction, setEditedButtonAction] = useState('');
  const [headerValues, setHeaderValues] = useState([]);
  const [bodyValues, setBodyValues] = useState([]);
  const [bodytext, setBodyText] = useState([]);
  const [headertext, setHeaderText] = useState([]);
  const [groups, setGroups] = useState([]);
  const [selectedGroup, setSelectedGroup] = useState('');
  const [isGroupSelected, setIsGroupSelected] = useState(false);
  const [headerType, setHeaderType] = useState('');
  const [uploadedImage, setUploadedImage] = useState(null);
  const [selectedTemplate, setSelectedTemplate] = useState('');
  const [uploadedVideo, setUploadedVideo] = useState(null);
  const [uploadedDocument, setUploadedDocument] = useState(null);
  const [longitude, setLongitude] = useState('');
  const [latitude, setLatitude] = useState('');
  const [locationName, setLocationName] = useState('');
  const [locationAddress, setLocationAddress] = useState('');
  const [longitudeFilled, setLongitudeFilled] = useState(true);
  const [latitudeFilled, setLatitudeFilled] = useState(true);
  const [locationNameFilled, setLocationNameFilled] = useState(true);
  const [locationAddressFilled, setLocationAddressFilled] = useState(true);
  const [selectAll, setSelectAll] = useState(false);
  const location = useLocation();
  const initialTemplateId = location.state?.templateId || '';
  const [selectedMessageTemplate, setSelectedMessageTemplate] = useState(initialTemplateId);


  const handleBoxClick = (boxName) => {
    setActiveBox(boxName);
  };



  const handleSaveAndSend = () => {
    if (!selectedMessageTemplate) {
      console.error('No template selected');
      return;
    }
    if (headerType === 'LOCATION') {
      if (!longitude || !latitude || !locationName || !locationAddress) {
        setLongitudeFilled(!!longitude);
        setLatitudeFilled(!!latitude);
        setLocationNameFilled(!!locationName);
        setLocationAddressFilled(!!locationAddress);
        return;
      }
      setLongitudeFilled(true);
      setLatitudeFilled(true);
      setLocationNameFilled(true);
      setLocationAddressFilled(true);
    }
    const buttonActionValue = editedButtonAction;

    let data = {
      contact_ids: Array.isArray(selectedContacts) ? selectedContacts.join(',') : '',
      button_action: buttonActionValue,
      example_body_text: bodyValues.join(','),  

      ...(headerType === 'TEXT' && { example_header_text: headerValues.join(',') }),


      ...(headerType === 'IMAGE' && { media: uploadedImage }),

      ...(headerType === 'VIDEO' && { media: uploadedVideo }),

      ...(headerType === 'DOCUMENT' && { media: uploadedDocument }),

      ...(headerType === 'LOCATION' && {
        longitude: longitude,
        latitude: latitude,
        location_name: locationName,
        location_address: locationAddress,
      }),
    };

    UserApi.sendTemplate(selectedMessageTemplate, data)
      .then((resp) => {
        if (resp.status === 200) {
          navigate('/dashboard/Notification');
        } else {
          console.error('Error sending template:', resp.data.error_msg);
          alert(resp.data.error_msg);
        }
      })
      .catch((error) => {
        console.error('Error sending template:', error);
        Helpers.error401(error);
      });
  };
  const fetchContactList = async (groupId) => {
    try {
      const resp = await UserApi.Groupcontact(groupId);
      if (resp.status === 200) {
        if (resp.data.message === 'Successfull' && resp.data.group) {
          setContactList(resp.data.group)
        }
        else {
          console.error('Error getting contacts::', resp.data.error_msg);
          alert(resp.data.error_msg);
        }
      }
    } catch (error) {
      console.error('Error getting contacts:', error);
      Helpers.error401(error);
    }
  };

  const MAX_IMAGE_SIZE = 5 * 1024 * 1024; 
  const ALLOWED_IMAGE_TYPES = ['image/jpeg', 'image/png'];

  const MAX_VIDEO_SIZE = 16 * 1024 * 1024; 
  const ALLOWED_VIDEO_TYPES = ['video/mp4', 'video/3gp'];

  const MAX_DOCUMENT_SIZE = 100 * 1024 * 1024; 
  const ALLOWED_DOCUMENT_TYPES = [
    'application/pdf',
    'application/vnd.ms-powerpoint',
    'application/msword',
    'application/vnd.ms-excel',
    'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
    'application/vnd.openxmlformats-officedocument.presentationml.presentation',
    'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
  ];

  const handleImageUpload = (file) => {
    if (file.size > MAX_IMAGE_SIZE || !ALLOWED_IMAGE_TYPES.includes(file.type)) {
      console.error('Invalid image. Please upload an image with max size 5MB.');
      return;
    }
    setUploadedImage(file);
  };

  const handleVideoUpload = (file) => {
    if (file.size > MAX_VIDEO_SIZE || !ALLOWED_VIDEO_TYPES.includes(file.type)) {
      console.error('Invalid video. Please upload a video with max size 16MB.');
      return;
    }
    setUploadedVideo(file);
  };

  const handleDocumentUpload = (file) => {
    if (file.size > MAX_DOCUMENT_SIZE || !ALLOWED_DOCUMENT_TYPES.includes(file.type)) {
      console.error('Invalid document. Please upload a document with max size 100MB.');
      return;
    }
    setUploadedDocument(file);
  };


  useEffect(() => {
    setTemplateLoading(true);

    const fetchGroups = async () => {
      try {
        const resp = await UserApi.getGroup();
        if (resp.status === 200) {
          if (resp.data.group) {
            setGroups(resp.data.group);
          }
        } else {
          console.error('Error getting groups:', resp.data.error_msg);
          alert(resp.data.error_msg);
        }
      } catch (error) {
        console.error('Error getting groups:', error);
        Helpers.error401(error);
      }
    };

    fetchGroups();

    const fetchTemplateDetails = async () => {
      try {
        const resp = await UserApi.gettemplatebyId(selectedMessageTemplate);
        if (resp.status === 200) {
          setTemplate(resp.data.template);
          setHeaderType(resp.data.template.header_type);
          const bodyvalues = resp.data.template.example_body_text ? resp.data.template.example_body_text.split(',') : [];
          const headervalues = resp.data.template.example_header_text ? resp.data.template.example_header_text.split(',') : [];
          const bodytext = resp.data.template.body_text ? resp.data.template.body_text.split(',') : [];
          const headertext = resp.data.template.header_text ? resp.data.template.header_text.split(',') : [];
          setHeaderText(headertext);
          setBodyText(bodytext);
          setHeaderValues(headervalues);
          setBodyValues(bodyvalues);
          setButton(resp.data.button);
        } else {
          console.error('Error sending template:', resp.data.error_msg);
          alert(resp.data.error_msg);
        }
      } catch (error) {
        console.error('Error sending template:', error);
        Helpers.error401(error);
      } finally {
        setTemplateLoading(false);
      }
    };

    if (selectedGroup) {
      fetchContactList(selectedGroup);
    }

    if (selectedMessageTemplate) {
      fetchTemplateDetails();
    }

    const fetchMessageTemplates = async () => {
      try {
        const resp = await UserApi.getTemplate();
        if (resp.status === 200) {
          if (resp.data.template) {
            setMessageTemplates(resp.data.template);
          }
        }
        else {
          console.error('Error getting template:', resp.data.error_msg);
          alert(resp.data.error_msg);
        }
      } catch (error) {
        console.error('Error getting template:', error);
        Helpers.error401(error);
      }
    }
    // fetchContactList();
    fetchMessageTemplates();
    setUploadedImage(null);
  }, [selectedMessageTemplate, selectedGroup, selectedTemplate]);



  return (
    <>
      <div style={{
        "padding": "20px 20px 0",
        "borderRadius": "10px",
        "marginTop": "10px",
      }}>
        <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
          <Grid item xs={8}>
            <div>
              {activeBox === 'Nmarketing' && (
                <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
                  <Grid item xs={12}>
                    <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }} >
                      <Grid item xs={12}>
                        <Item className='griditems'>
                          <Box sx={{ width: '100%' }}>
                            <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
                              <Grid item xs={12}>
                                <Item className='inneritems'>
                                  <Stack spacing={2}>
                                    <h2 className='formheading2'>Notification</h2>
                                    <h2 className='heading'>Select Contact List</h2>
                                  </Stack>
                                </Item>
                              </Grid>
                              <Grid item xs={12}>
                                <Item className='inneritems'>
                                  <Box
                                    sx={{
                                      maxWidth: '100%',
                                      textAlign: 'left',
                                    }}
                                  >
                                    <FormControl fullWidth>
                                      <InputLabel id="group-select-label" className="labelbg">Select Group</InputLabel>
                                      <Select
                                        labelId="group-select-label"
                                        id="group-select"
                                        value={selectedGroup}
                                        onChange={(e) => {
                                          setSelectedGroup(e.target.value);
                                          setIsGroupSelected(!!e.target.value);
                                          setSelectedContacts([]); 
                                        }}
                                        sx={{ border: '1px solid #E9E9E9', borderRadius: '6px' }}
                                      >
                                        <MenuItem value="">Select Group</MenuItem>
                                        {groups.map((group) => (
                                          <MenuItem key={group.id} value={group.id}>
                                            {group.group_name}
                                          </MenuItem>
                                        ))}
                                      </Select>
                                    </FormControl>
                                  </Box>
                                </Item>
                              </Grid>
                              <Grid item xs={12}>
                                <Item className='inneritems'>
                                  <Box
                                    sx={{
                                      maxWidth: '100%',
                                      textAlign: 'left',
                                    }}
                                  >
                                    <FormControl fullWidth>
                                      <InputLabel id="contact-select-label" className="mutlilable">Select Contact</InputLabel>
                                      <Select
                                        className="multibox"
                                        labelId="contact-select-label"
                                        id="contact-select"
                                        multiple
                                        value={selectAll ? contactList.map(contact => contact.id) : selectedContacts}
                                        onChange={(event) => {
                                          if (event.target.value.includes('selectAll')) {
                                            setSelectAll(!selectAll);
                                            setSelectedContacts(
                                              selectAll ? [] : contactList.map(contact => contact.id)
                                            );
                                          } else {
                                            setSelectedContacts(event.target.value.filter(id => id !== 'selectAll'));
                                            setSelectAll(false);
                                          }
                                        }}
                                        input={<Input sx={{ border: '1px solid #E9E9E9', borderRadius: '6px' }} />}
                                        disabled={!isGroupSelected}
                                        renderValue={(selected) => (
                                          <div style={{ display: 'flex', flexWrap: 'wrap' }}>
                                            {selected.includes('selectAll') ? (
                                              <div>All Contacts Selected</div>
                                            ) : (
                                              selected.map((contactId, index) => {
                                                const contact = contactList.find((c) => c.id === contactId);
                                                const isSelected = selectedContacts.includes(contactId);

                                                return (
                                                  <div key={contactId} style={{ display: 'flex', alignItems: 'center' }}>
                                                    {contact.name}
                                                    {index < selected.length - 1 ? ',' : ''}
                                                  </div>
                                                );
                                              })
                                            )}
                                          </div>
                                        )}
                                        MenuProps={{
                                          PaperProps: {
                                            style: {
                                              maxHeight: '300px', 
                                            },
                                          },
                                        }}
                                      >
                                        <MenuItem value="selectAll">Select All</MenuItem>
                                        {contactList.map((contact) => (
                                          <MenuItem key={contact.id} value={contact.id}>
                                            <div style={{ display: 'flex', alignItems: 'center' }}>
                                              {contact.name}
                                              {selectedContacts.includes(contact.id) && <CheckIcon style={{ color: "99D3A9", marginLeft: '5px' }} />}
                                            </div>
                                          </MenuItem>
                                        ))}
                                      </Select>
                                    </FormControl>
                                    <label className='errormsg'>Select contacts to whom you want to send the notification.</label>
                                  </Box>
                                </Item>
                              </Grid>
                              <Grid item xs={12}>
                                <Item className='inneritems'>
                                  <Box
                                    sx={{
                                      maxWidth: '100%',
                                      "textAlign": "left",
                                    }}
                                  >
                                    <FormControl fullWidth>
                                      <InputLabel id="messageTemplateLabel" className="labelbg">Message Template</InputLabel>
                                      <Select
                                        value={selectedMessageTemplate}
                                        onChange={(e) => setSelectedMessageTemplate(e.target.value)}
                                        label="Message Template"
                                        id="messageTemplate"
                                      >
                                        <MenuItem value="">Select Message Template</MenuItem>
                                        {messageTemplates
                                          .filter(template => template.status === 'APPROVED')
                                          .map(template => (
                                            <MenuItem key={template.id} value={template.id}>
                                              {template.name}
                                            </MenuItem>
                                          ))}
                                      </Select>
                                    </FormControl>
                                    {headerType === 'IMAGE' && (
                                      <div>
                                        <input
                                          type="file"
                                          accept="image/*"
                                          onChange={(e) => handleImageUpload(e.target.files[0])}
                                        />
                                      </div>
                                    )}

                                    {(headerType === 'VIDEO') && (
                                      <div>
                                        <input
                                          type="file"
                                          accept="video/*"
                                          onChange={(e) => handleVideoUpload(e.target.files[0])}
                                        />
                                      </div>
                                    )}

                                    {(headerType === 'DOCUMENT') && (
                                      <div>
                                        <input
                                          type="file"
                                          accept="document/*"
                                          onChange={(e) => handleDocumentUpload(e.target.files[0])}
                                        />
                                      </div>
                                    )}

                                    {(headerType === 'LOCATION') && (
                                      <div>
                                        <TextField
                                          label="Longitude"
                                          value={longitude}
                                          fullWidth
                                          style={{ marginTop: '16px' }}
                                          onChange={(e) => {
                                            setLongitude(e.target.value);
                                            setLongitudeFilled(!!e.target.value);
                                          }}
                                          required
                                          error={!longitudeFilled}
                                          helperText={!longitudeFilled && 'This field is required'}
                                        />

                                        <TextField
                                          label="Latitude"
                                          value={latitude}
                                          fullWidth
                                          style={{ marginTop: '16px' }}
                                          onChange={(e) => {
                                            setLatitude(e.target.value);
                                            setLatitudeFilled(!!e.target.value);
                                          }}
                                          required
                                          error={!latitudeFilled}
                                          helperText={!latitudeFilled && 'This field is required'}
                                        />

                                        <TextField
                                          label="Location Name"
                                          value={locationName}
                                          fullWidth
                                          style={{ marginTop: '16px' }}
                                          onChange={(e) => {
                                            setLocationName(e.target.value);
                                            setLocationNameFilled(!!e.target.value)
                                          }}
                                          required
                                          error={!locationNameFilled}
                                          helperText={!locationNameFilled && 'This field is required'}
                                        />

                                        <TextField
                                          label="Location Address"
                                          value={locationAddress}
                                          fullWidth
                                          style={{ marginTop: '16px' }}
                                          onChange={(e) => {
                                            setLocationAddress(e.target.value);
                                            setLocationAddressFilled(!!e.target.value)
                                          }}
                                          required
                                          error={!locationAddressFilled}
                                          helperText={!locationAddressFilled && 'This field is required'}
                                        />
                                      </div>
                                    )}

                                    {!templateLoading && button && (
                                      <div>
                                        {Array.isArray(button) && button.length > 0 ? (
                                          button
                                            .filter(button => button.button_type === 'COPY_CODE')
                                            .map((button, index) => (
                                              <div key={index}>
                                                <TextField
                                                  label="Button Type"
                                                  value={button.button_type}
                                                  fullWidth
                                                  disabled
                                                  style={{ marginTop: '16px' }}
                                                />
                                                <TextField
                                                  label="Button Text"
                                                  value={button.button_text}
                                                  fullWidth
                                                  disabled
                                                  style={{ marginTop: '16px' }}
                                                />
                                                <TextField
                                                  label="Button Action"
                                                  value={editedButtonAction}
                                                  fullWidth
                                                  style={{ marginTop: '16px' }}
                                                  onChange={(e) => setEditedButtonAction(e.target.value)}
                                                />
                                              </div>
                                            ))
                                        ) : (
                                          (
                                            <p>No COPY_CODE buttons available</p>
                                          )
                                        )}
                                        {headerValues.map((value, index) => (
                                          <TextField
                                            key={index}
                                            label={`Header ${index + 1}`}
                                            value={value}
                                            fullWidth
                                            style={{ marginTop: '16px' }}
                                            onChange={(e) => {
                                              if (!e.target.value.includes(',')) {
                                                const updatedValues = [...headerValues];
                                                updatedValues[index] = e.target.value;
                                                setHeaderValues(updatedValues);
                                              } else {
                                                console.warn('Comma is not allowed in this field');
                                              }
                                            }}
                                          />
                                        ))}
                                        {bodyValues.map((value, index) => (
                                          <TextField
                                            key={index}
                                            label={`body ${index + 1}`}
                                            value={value}
                                            fullWidth
                                            style={{ marginTop: '16px' }}
                                            onChange={(e) => {
                                              if (!e.target.value.includes(',')) {
                                                const updatedValues = [...bodyValues];
                                                updatedValues[index] = e.target.value;
                                                setBodyValues(updatedValues);
                                              } else {
                                                console.warn('Comma is not allowed in this field');
                                              }
                                            }}
                                          />
                                        ))}
                                      </div>
                                    )}
                                  </Box>
                                </Item>
                              </Grid>
                            </Grid>
                          </Box>
                        </Item>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              )}
            </div>
          </Grid >
          <Grid item xs={4}>
            <Item className='griditems'>
              <Box style={{ "padding": "10px 0px 0px 17px", }}>
                <h2 className='formheading'>Send Template</h2>
              </Box>
              <Button variant="contained" className='savebtn' onClick={handleSaveAndSend}>Save and send</Button>
            </Item>
            <div className='previewBox addbg' style={{ "padding": " 19px 16px", "margin-top": "20px", backgroundImage: "url(/ws.png) ", backgroundSize: "cover" }}>
              <Box>
                <Card className="chatsign" sx={{ maxWidth: 345, background: '#fff', padding: '6px', overflow: "visible" }}>
                  {headertext.map((variable, index) => (
                    <Typography
                      key={index}
                      variant="body2"
                      color="text.secondary"
                      sx={{
                        textAlign: 'left',
                        fontWeight: 600,
                      }}
                    >
                      {variable.split(/(\{\{\d+\}\})/).map((part, partIndex) => {
                        const match = part.match(/\{\{(\d+)\}\}/);
                        return match ? headerValues[parseInt(match[1], 10) - 1] : part;
                      })}
                    </Typography>
                  ))}

                  {(headerType === 'IMAGE' || headerType === 'VIDEO' || headerType === 'LOCATION' || headerType === 'DOCUMENT') && (
                    <Box
                      style={{
                        backgroundColor: '#f1f1f1',
                        borderRadius: '6px',
                        height: '150px',
                        alignItems: 'center',
                        display: 'flex',
                        justifyContent: 'center',
                      }}
                    >
                      {headerType == 'LOCATION' ? (
                        <img src="/map.png" width={80} height={80} />
                      ) : null}

                      {uploadedVideo || uploadedImage || uploadedDocument ? (
                        headerType === 'VIDEO' ? (
                          <video
                            controls
                            width="100%"
                            height="100%"
                            style={{ borderRadius: '6px' }}
                          >
                            <source src={URL.createObjectURL(uploadedVideo)} type="video/mp4" />
                            Your browser does not support the video tag.
                          </video>
                        ) : headerType === 'IMAGE' ? (<img
                          src={URL.createObjectURL(uploadedImage)}
                          alt="Uploaded Header"
                          style={{ maxWidth: '100%', maxHeight: '100%', borderRadius: '6px' }}
                        />) :
                          headerType === 'DOCUMENT' ? (<iframe
                            src={URL.createObjectURL(uploadedDocument)}
                            alt="Uploaded Header"
                            style={{ maxWidth: '100%', maxHeight: '100%', borderRadius: '6px' }}
                          />) : null
                      ) : (
                        <CardMedia sx={{}} title="Default content"></CardMedia>
                      )}
                    </Box>
                  )}
                  {bodytext.map((variable, index) => (
                    <CardContent key={index} style={{ padding: '0px' }}>
                      <Typography variant="body2" color="text.secondary" sx={{ textAlign: 'left', fontWeight: 600 }}>
                        <p className="headlines">
                          {variable.split(/(\{\{\d+\}\})/).map((part, partIndex) => {
                            const match = part.match(/\{\{(\d+)\}\}/);
                            return match ? bodyValues[parseInt(match[1], 10) - 1] : part;
                          })}
                        </p>
                      </Typography>
                    </CardContent>
                  ))}
                  {!templateLoading && button && (
                    <div>
                      {button.map((buttonInfo, index) => (
                        <div key={index}>
                          <Button
                            fullWidth
                            style={{ marginTop: '0px', textTransform: "capitalize", "borderTop": "1px solid #ddd", "borderRadius": "0 !important" }}
                          > {buttonInfo.button_text}</Button>
                        </div>
                      ))}
                    </div>
                  )}
                </Card>
              </Box>
            </div>
          </Grid>
        </Grid >
      </div >
    </>
  );
};

export default AddNotification;
