// StepperComponent.js
import React, { useState } from 'react';
import { Stepper, Step, StepLabel, Button, Typography } from '@mui/material';
import { orange } from '@mui/material/colors';
import FacebookOutlinedIcon from '@mui/icons-material/FacebookOutlined';


const steps = ['Step 1', 'Step 2', 'Step 3'];
const StepperComponent = () => {
  const [activeStep, setActiveStep] = useState(0);

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };



  return (
    <>
      <div className="bgwhitemain">

        <div>

          <div className="stepsw">
            <h4>
              STEP 1 - Connect your phone number with WhatsApp API

            </h4>
            <h4>IMPORTANT NOTE:</h4>
            <div className="notes">
              <p>Following are four must-have requirements to setup your phone number:</p>
              <ul>
                <li>Phone Number that's not currently connected with WhatsApp.</li>
                <li>Valid business registration document of your business. GSTIN mandatory for Indian businesses.</li>
                <li>Valid website of your business.</li>
                <li>Payment method like a credit or debit card.</li>
              </ul>
              <p>If you do not have any of these, you will not be able to complete the setup.</p>
              <p style={{marginBottom:"12px"}}>Click on the button below and follow the instructions on-screen to connect your phone number with WhatsApp Cloud APIs.</p>
            </div>
            <p>Click on the button below and follow the instructions on-screen to connect your phone number with WhatsApp Cloud APIs.</p>
            <a href="#" className="btn facebook-btn">
              <FacebookOutlinedIcon /> Facebook
            </a>
          </div>
          <div className="stepsw">
            <h4>
              STEP 2 - Add your payment method for API billing

            </h4>

          </div>
          <div className="stepsw">
            <h4>
              STEP 3 - Proceed to Dashboard

            </h4>

          </div>
        </div>
      </div>
      <div className="bgwhitemain">
        <div className="stepsw requirements">
          <h4 style={{ "margin-bottom": "6px ", }}>
            Want to connect using API credentials instead?
          </h4>
          <p>If you want to connect your phone number using your custom generated WhatsApp Cloud API credentials, click on the button below.</p><br />
          <a href="#" className="btn Api_credentials-btn">
            Connect using API credentials
          </a>
          <br />
        </div>
      </div>
    </>
  );
};

export default StepperComponent;
