import * as React from 'react';
import { styled } from '@mui/material/styles';
import Paper from '@mui/material/Paper';
import {
    Typography,
    Grid,
    Box,
    Button,
    Card,
    CardActions,
    CardContent,
    CardMedia,
    Stack,
    Autocomplete,
    TextField,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Divider,
    Accordion,
    AccordionDetails,
    AccordionSummary,
} from '@mui/material';
// import React, { useState } from 'react';
// import { } from '@mui/x-charts/PieChart';
import { PieChart, Pie, Cell } from 'recharts';
import { NavLink } from 'react-router-dom/dist';
import { useLocation } from 'react-router-dom';
import { UserApi } from '../service/axios';
import { Helpers } from '../service';
import CircularProgress from '@mui/material/CircularProgress';
import { useEffect, useState } from 'react';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
    ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: 'center',
    color: theme.palette.text.secondary,
}));

// Pie Chart


const COLORS = ['#D2175A', '#FF6600', '#FF9650', '#59BE1B', '#6856D7'];
export default function Userdashboard() {
    const location = useLocation();
    const [reportData, setReportData] = useState(null); // State to store API response
    const [loading, setLoading] = useState(true); // State to manage loading state
    const [error, setError] = useState(null); // State to manage error state
    const [sendCount, setSendCount] = useState(0);
    const [deliveredCount, setDeliveredCount] = useState(0);
    const [readCount, setReadCount] = useState(0);
    const [failedCount, setFailedCount] = useState(0);
    const [totalCount, setTotalCount] = useState(0);
    const [deliveredPercentage, setDeliveredPercentage] = useState(0);
    const [sendPercentage, setSendPercentage] = useState(0);
    const [readPercentage, setReadPercentage] = useState(0);
    const [failedPercentage, setFailedPercentage] = useState(0);
    const [uniqueTemplates, setUniqueTemplates] = useState([]);
    const [expanded, setExpanded] = React.useState(false);

    const handleChange = (panel) => (event, isExpanded) => {
        setExpanded(isExpanded ? panel : false);
    };

    const data = [
        // { name: 'Total Attempts', value: totalCount },
        { name: 'Sent', value: sendCount },
        { name: 'Failed', value: failedCount },
        { name: 'Read', value: readCount },
        { name: 'Delivered', value: deliveredCount },
    ];
    // Access the contact ID from the state
    const contactId = location.state;

    useEffect(() => {
        const fetchReportData = async () => {
            try {
                const resp = await UserApi.getReportByContactId(contactId)
                if (resp.status === 200) {
                    setReportData(resp.data);
                    const uniqueTemplates = Array.from(new Set(resp.data.report.map(item => (item.template && item.template.name) || null).filter(Boolean)));
                    setUniqueTemplates(uniqueTemplates);
                    const sendItems = resp.data.report.filter(item => item.status === "sent");
                    setSendCount(sendItems.length);
                    const deliveredItems = resp.data.report.filter(item => item.status === "delivered");
                    setDeliveredCount(deliveredItems.length);
                    const readItems = resp.data.report.filter(item => item.status === "read");
                    setReadCount(readItems.length);
                    const failedItems = resp.data.report.filter(item => item.status === "failed");
                    setFailedCount(failedItems.length);
                    const totalItems = sendItems.length + deliveredItems.length + readItems.length + failedItems.length;
                    setTotalCount(totalItems);
                    const sendPercentage = (sendItems.length / totalItems) * 100;
                    const deliveredPercentage = (deliveredItems.length / totalItems) * 100;
                    const readPercentage = (readItems.length / totalItems) * 100;
                    const failedPercentage = (failedItems.length / totalItems) * 100;

                    // Set the percentages in state
                    setSendPercentage(sendPercentage);
                    setDeliveredPercentage(deliveredPercentage);
                    setReadPercentage(readPercentage);
                    setFailedPercentage(failedPercentage);
                } else {
                    console.error('Error getting template:', resp.data.error_msg);
                    alert(resp.data.error_msg);
                }
            } catch (error) {
                setError(error.message);
            } finally {
                setLoading(false);
            }
        };

        fetchReportData();
    }, []); // The empty dependency array ensures the effect runs once when the component mounts

    // Render loading state
    if (loading) {
        return (
            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
                <CircularProgress />
            </div>
        );
    }

    // Render error state
    if (error) {
        return <div>Error: {error}</div>;
    }



    return (
        <>
            <div style={{
                "padding": "20px 20px 0",
                "borderRadius": "10px",
                "marginTop": "10px",
            }}>
                <Box sx={{ width: '100%' }}>
                    <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
                        <Grid item xs={12}>
                            <Item className='inneritems'>
                                <Stack direction="row" spacing={2} sx={{ "align-items": "center" }}>
                                    <img src="/profile.png" alt="" />
                                    <Stack direction="column" spacing={2} sx={{ "textAlign": "left" }}>
                                        <Typography variant='h4' sx={{ "fontSize": "21px", "color": "#000", "fontWeight": "600" }}>{reportData.contact_detail.name}</Typography>
                                        <h2 style={{ "fontSize": "14px", "color": "#000", "marginTop": "5px", "fontWeight": "400", }}>{reportData.contact_detail.mobile_no}</h2>
                                    </Stack>
                                </Stack>
                            </Item>
                        </Grid>
                    </Grid>
                </Box>
                <Box sx={{ width: '100%' }} className={Helpers.cmAccess('CONTACTS_RESULTS_BOX')}>
                    <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }} columns={16} mt={2}>
                        <Grid item xs={3}>
                            <Item className='griditems' sx={{ "padding": "11px 13px", }}>
                                <Stack direction="column" spacing={2} sx={{ "textAlign": "left" }}>
                                    <Box aria-label="Example" style={{
                                        "width": "fit-content",
                                        "background": "#FAE3E3",
                                        "border-radius": "7px",
                                        "padding": "7px 8px",
                                        "justify-content": "center",
                                        "display": "flex",
                                    }}>
                                        <img src='/phone.png' />
                                    </Box>
                                    <Typography style={{ fontSize: 10, color: "#949494", marginTop: 13, }}>Total Messages</Typography>
                                    <h4 style={{ fontSize: 18, marginBottom: 0, marginTop: 8, color: "#1C1C1C" }}>{totalCount}(100%)</h4>
                                </Stack>
                            </Item>
                        </Grid>
                        <Grid item xs={3}>
                            <Item className='griditems' sx={{ "padding": "11px 13px", }}>
                                <Stack direction="column" spacing={2} sx={{ "textAlign": "left" }}>
                                    <Box aria-label="Example" style={{
                                        "width": "fit-content",
                                        "background": "#E8FAE3",
                                        "border-radius": "7px",
                                        "padding": "7px 8px",
                                        "justify-content": "center",
                                        "display": "flex",
                                    }}>
                                        <img src='/msg.png' />
                                    </Box>
                                    <Typography style={{ fontSize: 10, color: "#949494", marginTop: 13, }}>Sent</Typography>
                                    <h4 style={{ fontSize: 18, marginBottom: 0, marginTop: 8, color: "#1C1C1C" }}>{sendCount === 0 ? `0(0%)` : `${sendCount} (${sendPercentage.toFixed(2)}%)`}</h4>
                                </Stack>
                            </Item>
                        </Grid>
                        <Grid item xs={3}>
                            <Item className='griditems' sx={{ "padding": "11px 13px", }}>
                                <Stack direction="column" spacing={2} sx={{ "textAlign": "left" }}>
                                    <Box aria-label="Example" style={{
                                        "width": "fit-content",
                                        "background": "#E0F4FF",
                                        "border-radius": "7px",
                                        "padding": "7px 8px",
                                        "justify-content": "center",
                                        "display": "flex",
                                    }}>
                                        <img src='/message.png' />
                                    </Box>
                                    <Typography style={{ fontSize: 10, color: "#949494", marginTop: 13, }}>Read</Typography>
                                    <h4 style={{ fontSize: 18, marginBottom: 0, marginTop: 4, color: "#1C1C1C" }}>{readCount === 0 ? `0(0%)` : `${readCount} (${readPercentage.toFixed(2)}%)`}</h4>
                                </Stack>
                            </Item>
                        </Grid>
                        <Grid item xs={3}>
                            <Item className='griditems' sx={{ "padding": "11px 13px", }}>
                                <Stack direction="column" spacing={2} sx={{ "textAlign": "left" }}>
                                    <Box aria-label="Example" style={{
                                        "width": "fit-content",
                                        "background": "#FFF8E0",
                                        "border-radius": "7px",
                                        "padding": "7px 8px",
                                        "justify-content": "center",
                                        "display": "flex",
                                    }}>
                                        <img src='/setting.png' />
                                    </Box>
                                    <Typography style={{ fontSize: 10, color: "#949494", marginTop: 13, }}>Failed</Typography>
                                    <h4 style={{ fontSize: 18, marginBottom: 0, marginTop: 4, color: "#1C1C1C" }}>{failedCount === 0 ? `0(0%)` : `${failedCount} (${failedPercentage.toFixed(2)}%)`}</h4>
                                </Stack>
                            </Item>
                        </Grid>
                        <Grid item xs={3}>
                            <Item className='griditems' sx={{ "padding": "11px 13px", }}>
                                <Stack direction="column" spacing={2} sx={{ "textAlign": "left" }}>
                                    <Box aria-label="Example" style={{
                                        "width": "fit-content",
                                        "background": "#FFF8E0",
                                        "border-radius": "7px",
                                        "padding": "7px 8px",
                                        "justify-content": "center",
                                        "display": "flex",
                                    }}>
                                        <img src='/setting.png' />
                                    </Box>
                                    <Typography style={{ fontSize: 10, color: "#949494", marginTop: 13, }}>Delivered</Typography>
                                    <h4 style={{ fontSize: 18, marginBottom: 0, marginTop: 4, color: "#1C1C1C" }}>{deliveredCount === 0 ? `0(0%)` : `${deliveredCount} (${deliveredPercentage.toFixed(2)}%)`}</h4>
                                </Stack>
                            </Item>
                        </Grid>
                    </Grid>
                </Box>
                <Box sx={{ width: '100%' }}>
                    <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }} mt={2}>
                        <Grid item xs={6} className={Helpers.cmAccess('CONTACTS_GRAPH')}>
                            <Item className='griditems'>
                                <Stack direction='row' spacing={1}>
                                    <Box aria-label="Example" style={{
                                        "width": "fit-content",
                                        "background": "#E8FAE3",
                                        "border-radius": "7px",
                                        "padding": "7px 8px 2px",
                                        "marginRight": "9px",
                                    }}>
                                        <img src='/msg.png' />

                                    </Box>
                                    <Typography variant="h6" style={{ fontSize: 16, color: "#000", }}>Message Status</Typography>

                                </Stack>
                                <Box sx={{ width: '100%' }}>
                                    <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
                                        <Grid item xs={12}>
                                            <Item className='inneritems'>
                                                <div>
                                                    <PieChart width={400} height={280} style={{ "outline": "none" }}>
                                                        <Pie
                                                            data={data}
                                                            dataKey="value"
                                                            cx={250}
                                                            cy={135}
                                                            fill="#8884d8"
                                                            startAngle={0}
                                                            endAngle={360}
                                                            style={{ "outline": "none" }}
                                                            label
                                                        >
                                                            {data.map((entry, index) => (
                                                                <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} style={{ "outline": "none" }} />
                                                            ))}
                                                        </Pie>
                                                    </PieChart>
                                                    <div style={{ marginTop: 20 }}>
                                                        <ul style={{ "display": "flex", "padding": "10px 45px", "listStyle": "none" }}>
                                                            {data.map((item, index) => (
                                                                <li key={index} style={{ "margin-right": "10px", "color": "#000", "display": "flex", "alignItems": "center", "flexDirection": "column", "gap": "8px" }}>
                                                                    <span style={{ "width": "15px", "height": "15px", "borderRadius": "50%", "background": COLORS[index % COLORS.length], "marginRight": "5px" }}></span>
                                                                    <Typography sx={{ "fontSize": "10px", "color": "#000", "fontWeight": "500" }}>{item.name}:</Typography><b>{item.value}</b>
                                                                </li>
                                                            ))}
                                                        </ul>
                                                    </div>
                                                </div>
                                            </Item>
                                        </Grid>
                                    </Grid>
                                </Box>
                            </Item>
                        </Grid>
                        <Grid item xs={6} className={Helpers.cmAccess('CONTACTS_TEMPLATE_HISTORY')}>
                            <Item className='griditems'>
                                <Stack direction='row' spacing={1}>
                                    <Box aria-label="Example" style={{
                                        "width": "fit-content",
                                        "background": "#E3EAFA",
                                        "border-radius": "7px",
                                        "padding": "7px 8px 2px",
                                        "marginRight": "9px",
                                    }}>
                                        <img src='/message.png' />

                                    </Box>
                                    <Typography variant="h6" style={{ fontSize: 16, color: "#000", }}>Template History</Typography>
                                </Stack>
                                <Box sx={{ width: '100%' }}>
                                    <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }} mt={2}>
                                        <Grid item xs={12}>
                                            <Item className='inneritems'>
                                                <Box
                                                    sx={{
                                                        "border": " 1px solid #F5F5F5",
                                                        "border-radius": "9px",
                                                    }}
                                                >
                                                    <TableContainer>
                                                        <Table sx={{ minWidth: '100%' }} aria-label="simple table">
                                                            <TableHead>
                                                                <TableRow>
                                                                    <TableCell>Name</TableCell>
                                                                    <TableCell>Status</TableCell>
                                                                    <TableCell>message date</TableCell>
                                                                </TableRow>
                                                            </TableHead>
                                                            <TableBody>
                                                                {reportData.report
                                                                    .filter((template) => template.template && uniqueTemplates.includes(template.template.name))
                                                                    .sort((a, b) => new Date(b.message_time) - new Date(a.message_time)).slice(0, 6)
                                                                    .map((template) => (
                                                                        <TableRow key={template.id}>
                                                                            <TableCell style={{ color: '#0889FF' }}>{template.template && template.template.name}</TableCell>
                                                                            <TableCell style={{ color: '#51921F', fontWeight: 700 }}>
                                                                                {template.status}
                                                                            </TableCell>
                                                                            <TableCell style={{ color: '#51921F', fontWeight: 700 }}>
                                                                                {template.message_time}
                                                                            </TableCell>
                                                                        </TableRow>
                                                                    ))}

                                                            </TableBody>
                                                        </Table>
                                                    </TableContainer>
                                                </Box>
                                            </Item>
                                        </Grid>
                                    </Grid>
                                </Box>
                            </Item>
                        </Grid>
                    </Grid>
                </Box>
                <Box sx={{ width: '100%' }} >
                    <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }} mt={2} mb={2}>
                        <Grid item xs={12}>
                            <Item className='griditems'>
                                <Box sx={{ width: '100%' }}>
                                    <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }} mb={2}>
                                        
                                    </Grid>
                                </Box>
                                <div>
                                    <Accordion expanded={expanded === 'panel1'} onChange={handleChange('panel1')}>
                                        <AccordionSummary
                                            expandIcon={<ExpandMoreIcon />}
                                            aria-controls="panel1bh-content"
                                            id="panel1bh-header"
                                        >
                                            <Grid item xs={6}>
                                            <Item className='inneritems'>
                                                <Stack direction='row' spacing={1}>
                                                    <Box aria-label="Example" style={{
                                                        "width": "fit-content",
                                                        "background": "#E3EAFA",
                                                        "border-radius": "7px",
                                                        "padding": "7px 8px 2px",
                                                        "marginRight": "9px",
                                                    }}>
                                                        <img src='/message.png' />
                                                    </Box>
                                                    <Typography variant="h6" style={{ fontSize: 16, color: "#000", }}>Template History</Typography>
                                                </Stack>
                                            </Item>
                                        </Grid>
                                        </AccordionSummary>
                                        <AccordionDetails>
                                            <Box sx={{ width: '100%' }}>
                                                <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
                                                    <Grid item xs={12} sx={{ padding: 0, }}>
                                                        <Item className='inneritems'>
                                                            <Box
                                                                sx={{
                                                                    "border": " 1px solid #F5F5F5",
                                                                    "border-radius": "9px",
                                                                }}
                                                            >
                                                                <TableContainer>
                                                                    <Table>
                                                                        <TableHead>
                                                                            <TableRow>
                                                                                <TableCell>Sr No</TableCell>
                                                                                <TableCell>Names</TableCell>
                                                                                <TableCell>Status</TableCell>
                                                                                <TableCell>Message Date</TableCell>
                                                                            </TableRow>
                                                                        </TableHead>
                                                                        <TableBody>
                                                                            {reportData.report
                                                                                .filter((template) => template.template && uniqueTemplates.includes(template.template.name))
                                                                                .sort((a, b) => new Date(b.message_time) - new Date(a.message_time))
                                                                                .map((template, index) => (
                                                                                    <TableRow key={index}>
                                                                                        <TableCell>{index + 1}</TableCell>
                                                                                        <TableCell style={{ color: '#0889FF' }}>{template.template && template.template.name}</TableCell>
                                                                                        <TableCell style={{ color: '#51921F', fontWeight: 700 }}>
                                                                                            {template.status}
                                                                                        </TableCell>
                                                                                        <TableCell style={{ color: '#51921F', fontWeight: 700 }}>
                                                                                            {template.message_time}
                                                                                        </TableCell>
                                                                                    </TableRow>
                                                                                ))}
                                                                        </TableBody>
                                                                    </Table>
                                                                </TableContainer>
                                                            </Box>
                                                        </Item>
                                                    </Grid>
                                                </Grid>
                                            </Box>
                                        </AccordionDetails>
                                    </Accordion>
                                </div>
                            </Item>
                        </Grid>
                    </Grid>
                </Box>
            </div>
        </>
    );
}