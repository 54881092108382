import React, { useEffect, useState } from 'react';
import TextField from '@mui/material/TextField';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import { Link, Navigate, useLocation,useNavigate } from 'react-router-dom';
import axios from 'axios';
import { object } from 'yup';
import { AuthApi } from '../service';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Helpers } from '../service';
import { UserApi } from '../service/axios';

const customTheme = createTheme({
  palette: {
    primary: {
      main: '#E27606', // Define your custom color here
    },
  },
});

const logo = require('../img/belnotifire.png');

export default function Register() {
  const [formData, setFormData] = useState({
    first_name: '',
    last_name: '',
    email: '',
    password: '',
    mobile_no: '',
    role_id: '',
  });

  const [errors, setErrors] = useState({});
  const [valid, setValid] = useState(true);
  const [roleData, setRoleData] = useState([]);
  const [userData, setUserData] = useState([]);
  const [roleid, setRoleId] = useState('');
  const [buttonInfo, setButtonInfo] = useState('Sign Up');
  const [user, setUserId] = useState('');
  const [existUser, setExistUser] = useState(false);
  const navigate = useNavigate();
  const { state } = useLocation();

  
  useEffect(() => {
    getRolls();
    if (state) {
      setButtonInfo('Update');
      setUserId(state);
      getUser(state); 
      setExistUser(true);
    }
  }, []);

  const getUser = (id) => {
    UserApi.getUser(id).then(
      (resp) => {
        if (resp.status === 200) {
          const updatedData = {
            first_name: resp.data.data.users.first_name,
            last_name: resp.data.data.users.last_name,
            password:'*********',
            email: resp.data.data.users.email,
            mobile_no: resp.data.data.users.mobile_no,
            role_id: resp.data.data.users.role_id
          };
          setFormData(updatedData);
          setRoleId(resp.data.data.users.role_id);
        } else {
          console.log("error", resp.data.error_msg);
          alert(resp.data.error_msg);
        }
      }
    ).catch((error) => {
      Helpers.error401(error);
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    let isValid = true;
    let validationError = {};
  
    if (formData.first_name === '' || formData.first_name === null) {
      isValid = false;
      validationError.first_name = 'First Name required';
    }
    if (formData.last_name === '' || formData.last_name === null) {
      isValid = false;
      validationError.last_name = 'Last Name required';
    }
    if (formData.email === '' || formData.email === null) {
      isValid = false;
      validationError.email = 'Email required';
    } else if (!/\S+@\S+\.\S+/.test(formData.email)) {
      isValid = false;
      validationError.email = 'Email is not valid';
    }
    if (formData.password === '' || formData.password === null) {
      isValid = false;
      validationError.password = 'Password required';
    } else if (formData.password.length < 6) {
      isValid = false;
      validationError.password = 'Password length at least 6 characters';
    }
    if (formData.mobile_no === '' || formData.mobile_no === null) {
      isValid = false;
      validationError.mobile_no = 'Phone Number required';
    }
  
    setErrors(validationError);
    setValid(isValid);
  
    if (Object.keys(validationError).length === 0 && isValid) {
        if(state){
          UserApi.updateUser( formData,user)
          .then(result => {
            toast.success('User updated');
            navigate('/dashboard/Userlist');
          })
          .catch(err => console.log(err));
        }else{
          UserApi.register( formData)
          .then(result => {
            toast.success('Registration successful!');
            navigate('/dashboard/Userlist');
          })
          .catch(err => console.log(err));
        }
    }
  };

  const getRolls = () => {
    UserApi.getRolls().then(
      (resp) => {
        if (resp.status === 200) {
          setRoleData(resp.data.data);
        } else {
          console.log("error", resp.data.error_msg);
          alert(resp.data.error_msg);
        }
      }
    ).catch((error) => {
      Helpers.error401(error);
    });
  };

  return (
    <ThemeProvider theme={customTheme}>
      <div>
      <ToastContainer />
        <form className="condition" autoComplete="off" onSubmit={handleSubmit}>
          <Box
            className="loginbody"
            sx={{
              my: 8,
              mx: 4,
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              backgroundColor:"#fff",
            }}
          >
            <img src={logo} alt="Logo" style={{ width: "210px", paddingBottom: "30px", marginTop: "20px" }} />
            
            <Box sx={{ mt: 1 }}>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={6}>
                 
                  <TextField
                    fullWidth
                    label="First name"
                    name="first_name"
                    variant="outlined"
                    value={formData.first_name}
                    onChange={(e) => setFormData({ ...formData, first_name: e.target.value })}
                    required
                    error={Boolean(errors.first_name)}
                    helperText={errors.first_name}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    fullWidth
                    label="Last name"
                    name="last_name"
                    variant="outlined"
                    value={formData.last_name}
                    onChange={(e) => setFormData({ ...formData, last_name: e.target.value })}
                    required
                    error={Boolean(errors.last_name)}
                    helperText={errors.last_name}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    fullWidth
                    label="Email"
                    type="email"
                    name="email"
                    variant="outlined"
                    value={formData.email}
                    onChange={(e) => setFormData({ ...formData, email: e.target.value })}
                    required
                    error={Boolean(errors.email)}
                    helperText={errors.email}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    fullWidth
                    label="Password"
                    name="password"
                    variant="outlined"
                    value={formData.password}
                    onChange={(e) => setFormData({ ...formData, password: e.target.value })}
                    required
                    error={Boolean(errors.password)}
                    helperText={errors.password}
                  />
                </Grid>
                <Grid item xs={12} >
                  <TextField
                    fullWidth
                    label="Phone Number"
                    name="mobile_no"
                    variant="outlined"
                    value={formData.mobile_no}
                    onChange={(e) => setFormData({ ...formData, mobile_no: e.target.value })}
                    required
                    error={Boolean(errors.mobile_no)}
                    helperText={errors.mobile_no}
                  />
                </Grid>
                <Grid item xs={12} >
                <select
                  className='selectBox'
                  name="role_id"
                  id="role_id"
                  onChange={(e) => setFormData({ ...formData, role_id: e.target.value })}
                  value={formData.role_id}
                  required
                >
                  {roleData.map((item, index) => (
                    <option key={index} value={item.role_id}>
                      {item.role_name}
                    </option>
                  ))}
                </select>
                </Grid>
                {/* <Grid item xs={12} sm={6}>
                  <TextField
                    fullWidth
                    label="How did you find us?"
                    name="how_find"
                    variant="outlined"
                    value={formData.how_find}
                    onChange={(e) => setFormData({ ...formData, how_find: e.target.value })}
                    required
                    error={Boolean(errors.how_find)}
                    helperText={errors.how_find}
                  />
                </Grid> */}
              </Grid>
              <div className={Helpers.cmAccess('ADMIN_CONFIGURATION_EDIT_UPDATE')}>
              <Button
               className={`btnbig`}
                type="submit"
                fullWidth
                variant="contained"
                color="primary"
                sx={{ mt: 3, mb: 2 }}
                // onClick={handleSubmit}
              >
                {buttonInfo}
              </Button>
              </div>
            </Box>
          </Box>
        </form>
      </div>
    </ThemeProvider>
  );
}
