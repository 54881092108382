import React, { useState, useEffect } from 'react';
import { styled, alpha } from '@mui/material/styles';
import Paper from '@mui/material/Paper';
import { Typography, Grid, Box, Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, TextField, Select, Divider, List, ListItem, ListItemText, } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import { useLocation,useNavigate } from 'react-router-dom';
import { UserApi } from '../service/axios';
import { Helpers } from '../service';

const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
    ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: 'center',
    color: theme.palette.text.secondary,
}));
const logo = require('../img/belnotifire.png');

export default function Setupcredentials() {
    const [mobileNumberData, setMobileNumberData] = useState({
        app_id:'',
        phone_no_id:'',
        status:'',
        webhook_id:'',
        whatsapp_business_account_id:'',
        whatsapp_business_token:'',
        whatsapp_mobile_no:''
    });
    const [mobileNumberId, setMobileNumberId] = useState('');
    const [loading, setLoading] = useState(true);
    const { state } = useLocation();
    const [open, setOpen] = React.useState(false);
    const [scroll, setScroll] = React.useState('paper');

    useEffect(() => {
        if (state) {
            setMobileNumberData({
                app_id: state.app_id,
                phone_no_id: state.phone_no_id,
                status: state.status,
                webhook_id: state.webhook_id,
                whatsapp_business_account_id: state.whatsapp_business_account_id,
                whatsapp_business_token: state.whatsapp_business_token,
                whatsapp_mobile_no: state.whatsapp_mobile_no,
            });
            setMobileNumberId(state.id);
        } 
     }, []);

     const configureMobileNo = () => {
        setLoading(true);
        UserApi.storeAssociateNumberswithid(mobileNumberId,mobileNumberData)
            .then((resp) => {
                if (resp.status === 200) {
                    Helpers.toastSuccess(resp.data.message);
                } else {
                    console.log('error', resp.data.error_msg);
                }
            })
            .catch((error) => {
                Helpers.toastError(error.response.data.error);
            })
            .finally((error) => {
                setLoading(false);
            });
    };
     const handleOnChange = (event) => {
        const { name, value } = event.target;
        setMobileNumberData(prevState => ({
            ...prevState,
            [name]: value
        }));
     }   
    const handleClickOpen = (scrollType) => () => {
        setOpen(true);
        setScroll(scrollType);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const descriptionElementRef = React.useRef(null);
    React.useEffect(() => {
        if (open) {
            const { current: descriptionElement } = descriptionElementRef;
            if (descriptionElement !== null) {
                descriptionElement.focus();
            }
        }
    }, [open]);
    const listItems = [
        "Create a free Meta developer account, if you don't already have one. Follow the steps shown on screen to complete creating your account if you're doing it for the first time.",
        "Next, click on the green Create App button to create a new app.",
        "On the next page, it'll ask Which use case do you want to add to your app?, select the Other option.",
        "On the next page, select the Business option and click on the Next button at the bottom.",
        "On the next page, Add an app name and Add contact email. Then in the Business Account field, select a business account if you already have one. If you don't have one, keep the No Business Manager account selected option selected. Then click on Create app button.",
        "You will be redirected to your App page where you need to Add products to your app. Scroll to the bottom to find WhatsApp product and click Set up.",
        "On the next WhatsApp Business Platform API screen, you will see the option to select an existing Meta Business Account. Select your account if you already have it, or else select the Create a business account option to create a new one. Then click Continue.",
        "You will be redirected to the Quickstart page that'll look like this:",
    ];

    return (
        <>
            <Box
                className="loginbody"
                sx={{
                    my: 8,
                    mx: 4,
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    backgroundColor: "#fff",
                    width: "750px"
                }}
            >
                <img src={logo} alt="Logo" style={{ width: "210px", paddingBottom: "30px", marginTop: "20px" }} />
                <Typography variant='h5'>
                    Setup your WhatsApp Cloud API credentials
                </Typography>
                <Typography variant='p' component='p' sx={{ textAlign: 'center', fontSize: 14, marginTop: 2, }}>Generate your API credentials by watching the video below and add them in the form below.</Typography>

                <div style={{ margin: "20px 1px", }}>
                    <iframe
                        width="550"
                        height="315"
                        src="https://www.youtube.com/embed/your-video-id"
                        title="YouTube video player"
                        frameborder="0"
                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                        allowfullscreen
                    ></iframe>
                </div>

                <Box sx={{ width: '100%', px: 3 }}>
                    <Typography sx={{ textAlign: "left" }}><b>Callback URL:</b>
                        <span style={{ textDecoration: "none" }}> https://belnotifierbec.smartmanager.in/api/getPostBywhatsapp/{mobileNumberData.webhook_id}</span>
                    </Typography>
                    <Typography sx={{ textAlign: "left" }}><b>Verify Token:</b> Secret123#</Typography>
                </Box>
                <Divider sx={{ width: "100%", margin: "25px 0px", }} />
                <Box sx={{ width: '100%', px: 1 }}>
                    <Typography variant='p' component='p' sx={{ textAlign: 'left', fontSize: 14, marginTop: 2, }}>Watch the video above to get the following WhatsApp Cloud API credentials.
                    </Typography>
                </Box>
                <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 1 }} mt={2}>
                    <Grid item xs={6}>
                        <Item className='inneritems' sx={{ "textAlign": "left" }}>
                            <label for="Number-select" style={{ "fontWeight": "600", color: "#000" }}>PHONE NUMBER ID</label><br />
                            <input id="tac" className='inputfield' value={mobileNumberData.phone_no_id} type="text" name="phone_no_id" placeholder='Enter Phone Number ID' onChange={(event) => handleOnChange(event)} />
                        </Item>
                    </Grid>
                    <Grid item xs={6}>
                        <Item className='inneritems' sx={{ "textAlign": "left" }}>
                            <label for="Category-select" style={{ "fontWeight": "600", color: "#000" }}>WHATSAPP BUSINESS ACCOUNT ID</label><br />
                            <input id="tac" className='inputfield'  value={mobileNumberData.whatsapp_business_account_id} type="text" name="whatsapp_business_account_id" placeholder='Enter Whatsapp Account ID' onChange={(event) => handleOnChange(event)} />
                        </Item>
                    </Grid>
                    <Grid item xs={12}>
                        <Item className='inneritems' sx={{ "textAlign": "left" }}>
                            <label for="Category-select" style={{ "fontWeight": "600", color: "#000" }}>PERMANENT ACCESS TOKEN</label><br />
                            <textarea className='textareafield' rows="4" value={mobileNumberData.whatsapp_business_token} name='whatsapp_business_token' placeholder='Enter permanent access token' onChange={(event) => handleOnChange(event)}></textarea>
                        </Item>
                    </Grid>
                    <Grid item xs={12}>
                        <Item className='inneritems' sx={{ "textAlign": "left" }}>
                            <Button className='Api_credentials-btn'  onClick={configureMobileNo}>Validate and proceed</Button>
                        </Item>
                    </Grid>
                    <Grid item xs={12}>
                        <Item className='inneritems' sx={{ "textAlign": "left" }}>
                            <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 1 }} mt={2}>
                                <Grid item xs={12} md={6}>
                                    <Item className='inneritems' sx={{ "textAlign": "left" }}>
                                        <label for="Number-select" style={{ "fontWeight": "600", color: "#000" }}>Prefer wittten instructions?</label><br />
                                        <Typography style={{ margin: "15px 0px" }}>
                                            if you Prefer wittten instructions over watching a video click on the button below
                                        </Typography>
                                        <Button onClick={handleClickOpen('paper')} className='Api_credentials-btn'>React API setup instructions</Button>

                                    </Item>
                                </Grid>
                                <Grid item xs={12} md={6}>
                                    <Item className='inneritems' sx={{ "textAlign": "left" }}>
                                        <label for="Category-select" style={{ "fontWeight": "600", color: "#000", marginBottom: "10px" }}>Need help?</label><br />
                                        <Typography style={{ margin: "15px 0px" }}>
                                            if you Prefer wittten instructions over watching a video click on the button below
                                        </Typography>
                                        <Button className='Api_credentials-btn'>Chat With Us</Button>
                                    </Item>
                                </Grid>
                            </Grid>
                        </Item>
                    </Grid>
                </Grid>
            </Box>
            <div className="bgwhitemain" style={{ margin: "0 auto", width: "750px", padding: "12px 24px", marginBottom: "20px" }}>
                <div className="stepsw requirements">
                    <h4 style={{ "margin-bottom": "6px ", }}>
                        Want to connect using embedded signup instead?
                    </h4>
                    <p>If you want to connect your phone number using the simplified embedded singup flow, click on the button below.</p><br />
                    <Button className="btn Api_credentials-btn">
                        Connect using embedded signup flow
                    </Button>
                    <br />
                </div>
            </div>
            <React.Fragment>
                <Dialog
                    open={open}
                    onClose={handleClose}
                    scroll={scroll}
                    aria-labelledby="scroll-dialog-title"
                    aria-describedby="scroll-dialog-description"
                    className='credentailsBox'
                >
                    <DialogTitle id="scroll-dialog-title" sx={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>How to Generate your WhatsApp Cloud API Credentials?
                        <IconButton aria-label="delete" onClick={handleClose}>
                            <CloseIcon />
                        </IconButton>
                    </DialogTitle>
                    <Typography sx={{ padding: "0 24px 16px" }}>This in a one time setup Eatimated satup time: 15 - 20 min</Typography>
                    <DialogContent dividers={scroll === 'paper'} sx={{ maxWidth: "760px" }}>
                        <DialogContentText
                            id="scroll-dialog-description"
                            ref={descriptionElementRef}
                            tabIndex={-1}
                            sx={{ border: "0px" }}
                        >
                            <div className="notes">
                                <h3 style={{ marginTop: "0px", marginBottom: "5px" }}>Requirments</h3>
                                <p style={{ marginTop: "0px", fontSize: "14px", }}>Following are the must-have requirements to setup The offical WhatsApp Cloud APIs.</p>
                                <ul style={{ paddingLeft: "6px", fontSize: "13px", color: "#000", lineHeight: "2.2" }}>
                                    <li>1. Phone Number that's not currently connected with WhatsApp.</li>
                                    <li>2. Valid business registration document of your business. GSTIN mandatory for   Indian  businesses.</li>
                                    <li>3. Website with a privacy policy page.</li>
                                    <li>4. Payment method like a credit or debit card.</li>
                                </ul>
                                <p>If you do not have any of these, you will not be able to complete the setup.</p>
                                <p style={{ marginBottom: "12px" }}>Click on the button below and follow the instructions on-screen to connect your phone number with WhatsApp Cloud APIs.</p>
                            </div>
                            <Typography sx={{ padding: "16px 0px" }}><b style={{ color: "#000" }}>Important Note:</b> All steps given below are <b style={{ color: "#000" }}>mandatory.</b></Typography>
                            <h3 style={{ margin: "0", color: "#000", }}>Step 1: Create Meta account and an app for WhatsApp Cloud API</h3>
                            <List style={{ paddingLeft: '6px', fontSize: '13px', color: '#000', lineHeight: '2.2' }}>
                                {listItems.map((item, index) => (
                                    <ListItem key={index} sx={{ alignItems: "baseline", }}>
                                        <Typography variant="body1" style={{ marginRight: '8px' }}>
                                            {index + 1}.
                                        </Typography>
                                        <ListItemText primary={item} />
                                    </ListItem>
                                ))}
                                <img src="/firstSS.png" alt="" width={"100%"} />
                                <h3 style={{ margin: "0", color: "#000", }}>Step 2: Setup your phone number in WhatsApp Cloud API</h3>
                                <ol className='credentialsList'>
                                    <li> From the sidebar go to WhatsApp API Setup page, scroll down to the bottom of the screen and click on Add phone number button.</li>

                                </ol>
                                <img src="/SecondSS.png" alt="" width={"100%"} />
                                <ol className='credentialsList' start="2">
                                    <li>A new popup will open to Create a WhatsApp Business profile. Enter WhatsApp Business Profile Display Name that matches your business name, Also select appropriate Category. After filling the details, click Next.
                                        <ol>
                                            <li style={{ listStyleType: "circle" }}>
                                                <b>IMPORTANT:</b> Do not enter dummy name in Display Name field like Test Business or any other random text. The name must match your business name.
                                            </li>
                                        </ol>
                                    </li>
                                    <li>Next, you'll need to add your phone number to use it as your WhatsApp phone number. Enter your phone number and select a verification method to verify the number.
                                        <ol>
                                            <li style={{ listStyleType: "circle" }}>
                                                <b>NOTE:</b> Once a phone number is setup here it'll be connected with WhatsApp Cloud API and you will not be able to use it with the mobile app.
                                            </li>
                                        </ol>
                                    </li>
                                    <li>Enter the verification code and click Next to finish adding the number.</li>
                                </ol>
                                <h3 style={{ margin: "0", color: "#000", }}>Step 3: Configure webhooks</h3>
                                <ol className='credentialsList'>
                                    <li>From the left sidebar, click on Whatsapp  Configuration link.</li>
                                    <li>Click on the Edit button. That'll open a popup. Enter the following in the Callback URL field:
                                        <a href='#' > https://app.wanotifier.local/webhooks/80X2zl3ujtTEgzPug8Fg</a>
                                    </li>
                                    <li>In the Verify token field enter: wa-not ifier and click Verify and save.</li>
                                    <li>After this, under Webhook fields, click on the Manage button. In the popup that opens, click on the Subscribe button in front of all the fields.
                                        <ol>
                                            <li style={{ listStyleType: "circle" }}>
                                                Subscribing to all fields is mandatory for full integration with WANotifier.
                                            </li>
                                        </ol>

                                    </li>
                                    <li>Click on Done to complete your Webhook setup. It should look something like this:</li>
                                </ol>
                                <img src="/3.png" alt="" width={"100%"} style={{ marginTop: "5px" }} />
                                <h3 style={{ margin: "0", color: "#000", }}>Step 4: Generate permanent access token</h3>
                                <ol className='credentialsList'>
                                    <li>Scroll to the top of the page and click on App Dashboar next to the MetaforDevelopers logo. Then open the Business Settings link in a new tab.</li>
                                </ol>
                                <img src="/4.png" alt="" width={"100%"} style={{ marginTop: "5px" }} />
                                <ol className='credentialsList' start={2}>
                                    <li>On the Business Settings page, in left sidebar, click on System users under Users. Then click on Add to add a new user.</li>
                                    <li>Click on I Accept button if you see non-discrimation policy popup.</li>
                                    <li>Enter a name for your System user name (you can keep it systen). Select System user role as Admin and click on Create system user.</li>
                                    <li>Now click on the Add Assets button. Under Select asset type click on Apps, select your app from Select assets column and then enable Full control Manage App. Then click on Save Changes.</li>
                                </ol>
                                <img src="/5.png" alt="" width={"100%"} style={{ marginTop: "5px" }} />
                                <ol className='credentialsList' start={6}>
                                    <li>Now click on Generate New Token button. Select your app from the dropdown and from the list of permissions under Available Permissions: select whatsapp_business_messaging and whatsapp_business_management. Then click on the Generate Token button.</li>
                                    <li>Copy the Access token.</li>
                                </ol>
                                <Box sx={{margin:"0 auto", textAlign:"center",}}>
                                    <img src="/Token.png" alt="" width={"50%"} style={{ marginTop: "5px" }} />

                                </Box>
                                <h3 style={{ margin: "0", color: "#000", }}>Step 5: Add payment method</h3>
                                <ol className='credentialsList'>
                                    <li>Now from the left sidebar go to Accounts WhatsApp Accounts page, select your WhatsApp account from the list and click on the Settings tab.</li>
                                    <li>Then click on the Payment Settings button. On the Payment Settings page, click on Add payment method button.</li>
                                    <li>Select your Country, Currency and Payment Method and click Next.</li>
                                    <li>Enter your payment details and hit Save. Youll see a screen like the following after your payment method is added successfully:</li>
                                </ol>
<img src="/6.png" alt="" width={"100%"} style={{ marginTop: "5px" }} />
                            </List>
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>

                    </DialogActions>
                </Dialog>
            </React.Fragment>
        </>
    )
}