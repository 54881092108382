import { styled } from '@mui/material/styles';
import Paper from '@mui/material/Paper';
import { Typography, Grid, Box, Card, Button, CardActions, CardContent, CardMedia, Stack, Divider, } from '@mui/material';
import { NavLink ,useNavigate} from 'react-router-dom';
import SendOutlinedIcon from '@mui/icons-material/SendOutlined';
import { useLocation } from 'react-router-dom';
import { UserApi } from '../service/axios';
import { Helpers } from '../service';
import React, { useState, useEffect } from 'react';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';


import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
    ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: 'center',
    color: theme.palette.text.secondary,
}));



export default function TemplateView() {
    const [headerValues, setHeaderValues] = useState([]);
    const [bodyValues, setBodyValues] = useState([]);
    const [bodytext, setBodyText] = useState([]);
    const [headertext, setHeaderText] = useState([]);
    const [footertext, setFooterText] = useState([]);
    const [headerType, setHeaderType] = useState('');
    const [templateName, setTemplateName] = useState('');
    const [button, setButton] = useState(null);
    const [templateLoading, setTemplateLoading] = useState(true);
    const location = useLocation();
    const templateId = location.state;
    const [popupType, setPopupType] = useState('delete');

    const navigate = useNavigate();
    const deleteTemplate = async () => {
        try {

            const resp = await UserApi.deleteTemplate(templateId);
            if (resp.status === 200) {
                navigate('/dashboard/MassageTemplate');
                Helpers.toastSuccess(
                    `Template ${popupType === 'delete' ? 'deleted' : 'deleted'} successfully`
                );
            }

        } catch (error) {
            console.error('Error deleting template:', error);
            Helpers.error401(error);
        }
    }
    useEffect(() => {
        const fetchTemplateDetails = async () => {
            try {
                const resp = await UserApi.gettemplatebyId(templateId);
                if (resp.status === 200) {
                    setTemplateName(resp.data.template.name);
                    // setTemplate(resp.data.template);
                    setHeaderType(resp.data.template.header_type);
                    const bodyvalues = resp.data.template.example_body_text ? resp.data.template.example_body_text.split(',') : [];
                    const headervalues = resp.data.template.example_header_text ? resp.data.template.example_header_text.split(',') : [];
                    const bodytext = resp.data.template.body_text ? resp.data.template.body_text.split(',') : [];
                    const headertext = resp.data.template.header_text ? resp.data.template.header_text.split(',') : [];
                    const footertext = resp.data.template.footer_text ? resp.data.template.footer_text.split(',') : [];
                    setHeaderText(headertext);
                    setBodyText(bodytext);
                    setHeaderValues(headervalues);
                    setBodyValues(bodyvalues);
                    setButton(resp.data.button);
                    setFooterText(footertext);
                    // ... (rest of your code to set other state variables)
                } else {
                    console.error('Error sending template:', resp.data.error_msg);
                    alert(resp.data.error_msg);
                }
            } catch (error) {
                console.error('Error sending template:', error);
                Helpers.error401(error);
            } finally {
                // Code inside this block will run regardless of success or failure
                setTemplateLoading(false);
            }
        };

        fetchTemplateDetails();
    }, [templateId]);

    return (
        <>
            <div style={{

            }}>

                <Box sx={{ width: '100%' }}>
                    <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
                        <Grid item xs={12}>
                            <Item className='griditems'>
                                <Box className='mainBox'>
                                    <NavLink to='/dashboard/MassageTemplate' style={{backgroundColor:"transparent", display:"block", width:"100%",}}>
                                        <IconButton aria-label="delete" className='Temdeletebtn'>
                                            <CloseIcon />
                                        </IconButton>
                                    </NavLink>
                                    <Box sx={{ width: '100%' }}>
                                        <div class='mobile-phone'>
                                            <div class='brove'><span class='speaker'></span></div>
                                            <div class='screen'>
                                                <Item className='' sx={{ mt: 0, borderRadius: 0, boxShadow: "0px 2px 1px -1px rgb(0 0 0 / 0%), 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 1px 3px 0px rgb(0 0 0 / 6%)", borderTopLeftRadius: 13, borderTopRightRadius: 13, }}>
                                                    <Stack direction='row' spacing={1} sx={{ pt: "14px", pr: 2, alignItems: "center", justifyContent: "space-between", }}>
                                                        <NavLink to = '/dashboard/massagetemplate'> 
                                                        <ArrowBackIosNewIcon sx={{ fontSize: 12, }} /></NavLink>
                                                        <h2 style={{ fontSize: 12, marginRight: "5em", wordBreak: "break-all", textAlign: "left" }}>{templateName}</h2>
                                                        <Stack direction='row' spacing={1} style={{ alignItems: "baseline" }}>
                                                            <NavLink to='/dashboard/massagetemplate/massagetemplateformedit' replace={true} state={templateId} className={Helpers.cmAccess('TEMPLATES_EDIT')}>
                                                                <img src='/edit.png' width={14} height={14} />
                                                            </NavLink>
                                                            <img src='/delete.png' style={{ cursor: "pointer" }} width={14} height={14} onClick={deleteTemplate} className={Helpers.cmAccess('TEMPLATES_DELETE')}/>
                                                        </Stack>
                                                    </Stack>
                                                </Item>
                                                <Box className="mainscreen" id='style-4'>
                                                    <Card sx={{ maxWidth: 345, width: 200, }} className="cardmessage">
                                                        {headerType === 'IMAGE' ? (<Box style={{
                                                            "background-color": "#D3E8E5", "height": "100px", "align-items": "center", "display": " flex", "justify-content": "center",
                                                        }}>

                                                            <CardMedia>
                                                                <img src='/permotion.jpg' alt='Image Alt Text' style={{ width: "100%" }} />
                                                            </CardMedia>

                                                        </Box>) : headerType === 'VIDEO' ? (<Box style={{
                                                            "background-color": "#D3E8E5", "height": "100px", "align-items": "center", "display": " flex", "justify-content": "center",
                                                        }}>

                                                            <CardMedia>
                                                                <img src='/video_permotion.jpg' alt='Image Alt Text' style={{ width: "100%" }} />
                                                            </CardMedia>

                                                        </Box>) : headerType === 'DOCUMENT' ? (<Box style={{
                                                            "background-color": "#D3E8E5", "height": "100px", "align-items": "center", "display": " flex", "justify-content": "center",
                                                        }}>

                                                            <CardMedia>
                                                                <img src='/document1.png' alt='Image Alt Text' style={{ width: "30%" }} />
                                                            </CardMedia>

                                                        </Box>) : headerType === 'LOCATION' ? (<Box style={{
                                                            "background-color": "#D3E8E5", "height": "100px", "align-items": "center", "display": " flex", "justify-content": "center",
                                                        }}>

                                                            <CardMedia>
                                                                <img src='/map.png' alt='Image Alt Text' style={{ width: "40%" }} />
                                                            </CardMedia>

                                                        </Box>) : null}
                                                        <Divider />
                                                        <CardContent>
                                                            <Stack direction='column' spacing={3}>
                                                                {headerType === 'TEXT' ? <Typography variant="body2" color="text.secondary" sx={{
                                                                    "textAlign": "left",
                                                                    "font-weight": "600",
                                                                    color: "#000",
                                                                }}>
                                                                    {headertext}
                                                                </Typography> : null}
                                                                <Typography variant="body2" color="text.secondary" sx={{
                                                                    "textAlign": "left",
                                                                    "font-weight": "500",
                                                                    fontSize: "11px",
                                                                    color: "#000",
                                                                }}>
                                                                    {bodytext}
                                                                </Typography>
                                                                <Typography variant="body2" color="text.secondary" sx={{
                                                                    "textAlign": "left",
                                                                    "font-weight": "400",
                                                                    color: "#000",
                                                                    fontSize: "9px",

                                                                }}>
                                                                    {footertext}
                                                                </Typography>
                                                                {!templateLoading && button && (
                                                                    <div style={{ textAlign: 'left' }}>
                                                                        {button.map((buttonInfo, index) => (
                                                                            <div key={index}>
                                                                                {/* Display button information */}
                                                                                <Button
                                                                                    // fullWidth
                                                                                    style={{ marginTop: '0px', textTransform: "capitalize", "borderTop": ".5px solid #ddd", "borderRadius": "0 !important", fontSize: "8px" }}
                                                                                > <img src="/arrow-left.png" style={{ width: "4%" }} /> {buttonInfo.button_text}</Button>
                                                                            </div>
                                                                        ))}
                                                                    </div>
                                                                )}
                                                            </Stack>
                                                        </CardContent>
                                                        {/* <Divider />
                                                        <CardActions sx={{ float: "right", }}>
                                                            <a href="#" style={{ "color": "#E27606", "fontSize": "12px", "textAlign": "right", "marginTop": "8px", padding: "9px 9px", }}>Visit Website</a>
                                                        </CardActions> */}
                                                    </Card>
                                                </Box>
                                                <Box className='chatsendsection' sx={{ p: 0, }}>
                                                    <div className="messagesend">
                                                        <input readOnly type="text" placeholder='Enter Message...' style={{ backgroundColor: "#fff", }} />
                                                        <Button class="formsend-submit">
                                                            <SendOutlinedIcon className='svg-icon svg-icon--send' sx={{ transform: "rotate(333deg)" }} />
                                                        </Button>
                                                    </div>
                                                </Box>
                                            </div>
                                        </div>
                                    </Box>
                                </Box>
                            </Item>
                        </Grid>
                    </Grid>
                </Box>
            </div>
        </>
    );
}
