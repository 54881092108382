import * as React from 'react';
import { useState, useEffect } from 'react';
import { NavLink } from 'react-router-dom';
import { Helpers } from '../service';
import {
    Box,
    Button,
    Grid,
    Paper,
    Stack,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Typography,
    Accordion,
    AccordionDetails,
    AccordionSummary,
} from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import { PieChart, Pie, Cell } from 'recharts';
import SearchIcon from '@mui/icons-material/Search';
import InputBase from '@mui/material/InputBase';
import { styled, alpha } from '@mui/material/styles';
import { Link } from 'react-router-dom';
import { UserApi } from '../service/axios';
import { useLocation } from 'react-router-dom';
import CircularProgress from '@mui/material/CircularProgress';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { padding } from '@mui/system';

const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
    ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: 'center',
    color: theme.palette.text.secondary,
}));

const rows = [
    { id: 1, name: 'John Doe', age: 25, city: 'New York' },
    { id: 2, name: 'Jane Smith', age: 30, city: 'Los Angeles' },
    { id: 3, name: 'Bob Johnson', age: 22, city: 'Chicago' },
];

const COLORS = ['#D2175A', '#FF6600', '#FF9650', '#59BE1B', '#6856D7'];
const Search = styled('div')(({ theme }) => ({
    position: 'relative',
    display: 'flex',
    borderRadius: theme.shape.borderRadius,
    backgroundColor: alpha(theme.palette.common.white, 0.15),
    '&:hover': {
        backgroundColor: alpha(theme.palette.common.white, 0.25),
    },
    marginRight: theme.spacing(2),
    marginLeft: 0,
    width: '100%',
    [theme.breakpoints.up('sm')]: {
        marginLeft: theme.spacing(3),
        width: 'auto',
    },
}));

const SearchIconWrapper = styled('div')(({ theme }) => ({
    padding: theme.spacing(0, 2),
    height: '100%',
    position: 'absolute',
    pointerEvents: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
}));

const StyledInputBase = styled(InputBase)(({ theme }) => ({
    color: 'inherit',
    '& .MuiInputBase-input': {
        padding: theme.spacing(1, 1, 1, 0),
        paddingLeft: `calc(1em + ${theme.spacing(4)})`,
        transition: theme.transitions.create('width'),
        width: '100%',
        [theme.breakpoints.up('md')]: {
            width: '32ch',
            border: '1px solid #E9E9E9',
            borderRadius: '6px',
            fontSize: '14px',
        },
        [theme.breakpoints.up('sm')]: {
            border: '1px solid #E9E9E9',
            borderRadius: '6px',
            fontSize: '14px',
            backgroundColor: '#fff',
            height: '1.5em',
        },
        [theme.breakpoints.up('xs')]: {
            border: '1px solid #E9E9E9',
            borderRadius: '6px',
            fontSize: '14px',
            backgroundColor: '#fff',
            height: '1.5em',
        },
    },
}));

const Notification = () => {
    const [expanded, setExpanded] = React.useState(false);

    const handleChange = (panel) => (event, isExpanded) => {
        setExpanded(isExpanded ? panel : false);
    };
    const location = useLocation();
    const [contactData, setContactData] = useState([]);
    const [templateMapping, setTemplateMapping] = useState({});
    const [searchTerm, setSearchTerm] = useState('');
    const [templateName, setTemplateName] = useState([]);
    const [templateSendData, setTemplateSendData] = useState([]);
    const [deliveredPercentage, setDeliveredPercentage] = useState(0);
    const [sendPercentage, setSendPercentage] = useState(0);
    const [readPercentage, setReadPercentage] = useState(0);
    const [failedPercentage, setFailedPercentage] = useState(0);
    const [pendingPercentage, setPendingPercentage] = useState(0);
    const [loading, setLoading] = useState(true);
    const [responseData, setResponseData] = useState(null);
    const [error, setError] = useState(null);
    const [sendCounts, setSendCounts] = useState({
        totalAttempts: 0,
        sent: 0,
        read: 0,
        failed: 0,
        delivered: 0,
    });
    const templateId = location.state;
    const calculateTotal = (contact) => {
        const { messagesRead, messagesSent, messagesFailed, messagesPending, messagesDelivered } = contact;
        return messagesRead + messagesSent + messagesFailed + messagesPending + messagesDelivered;
    };

    const data = [
        // { name: 'Total Attempts', value: sendCounts.totalAttempts },
        { name: 'Sent', value: sendCounts.totalSent },
        { name: 'Failed', value: sendCounts.failed },
        { name: 'Read', value: sendCounts.read },
        { name: 'Delivered', value: sendCounts.delivered },
    ];

    const transformResponse = (data) => {
        if (data && data.messagess) {
            const transformedArray = data.messagess.reduce((acc, message) => {
                const existingTempMsg = acc.find(item => item.temp_msg_id === message.temp_msg_id);

                const contactInfo = {
                    name: message.contact[0].name,
                    mobile_no: message.contact[0].mobile_no,
                    group_id: message.contact[0].group_id,
                    message_time: message.message_time,
                    status: message.status,
                };

                if (existingTempMsg) {
                    existingTempMsg.contact.push(contactInfo);
                } else {
                    acc.push({
                        temp_msg_id: message.temp_msg_id,
                        contact: [contactInfo],
                    });
                }

                return acc;
            }, []);
            setTemplateSendData(transformedArray);
        }
    };

    const ReceiversByTemplate = async () => {
        try {
            const resp = await UserApi.ReceiversByTemplate(templateId);
            if (resp.status === 200) {
                setResponseData(resp.data);
                transformResponse(resp.data);
            } else {
                console.error('Error getting template:', resp.data.error_msg);
                alert(resp.data.error_msg);
            }
        } catch (error) {
            console.error('Error fetching data:', error);
        }
        finally {
            setLoading(false);
        }
    };

    const fetchData = async () => {
        try {
            const resp = await UserApi.getReportTemplateWithContact(templateId);
            if (resp.status === 200) {
                setContactData(resp.data.contacts);
                setTemplateName(resp.data.template.name);

                // Calculate total counts
                const totalAttempts =
                    resp.data.contacts.reduce((accumulator, contact) => accumulator + contact.messagesSent, 0) +
                    resp.data.contacts.reduce((accumulator, contact) => accumulator + contact.messagesRead, 0) +
                    resp.data.contacts.reduce((accumulator, contact) => accumulator + contact.messagesFailed, 0) +
                    resp.data.contacts.reduce((accumulator, contact) => accumulator + contact.messagesDelivered, 0) +
                    resp.data.contacts.reduce((accumulator, contact) => accumulator + contact.messagesPending, 0);

                setSendCounts({
                    totalAttempts,
                    totalSent: resp.data.contacts.reduce((accumulator, contact) => accumulator + contact.messagesSent, 0),
                    read: resp.data.contacts.reduce((accumulator, contact) => accumulator + contact.messagesRead, 0),
                    failed: resp.data.contacts.reduce((accumulator, contact) => accumulator + contact.messagesFailed, 0),
                    delivered: resp.data.contacts.reduce((accumulator, contact) => accumulator + contact.messagesDelivered, 0),
                    pending: resp.data.contacts.reduce((accumulator, contact) => accumulator + contact.messagesPending, 0),
                });

            } else {
                console.error('Error getting template:', resp.data.error_msg);
                alert(resp.data.error_msg);
            }
        } catch (error) {
            console.error('Error fetching data:', error);
        }
        finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        const sentPercentage = ((sendCounts.totalSent / sendCounts.totalAttempts) * 100).toFixed(2);
        const deliveredPercentage = ((sendCounts.delivered / sendCounts.totalAttempts) * 100).toFixed(2);
        const readPercentage = ((sendCounts.read / sendCounts.totalAttempts) * 100).toFixed(2);
        const failedPercentage = ((sendCounts.failed / sendCounts.totalAttempts) * 100).toFixed(2);
        const pendingPercentage = ((sendCounts.pending / sendCounts.totalAttempts) * 100).toFixed(2);

        setSendPercentage(Number(sentPercentage));
        setDeliveredPercentage(Number(deliveredPercentage));
        setReadPercentage(Number(readPercentage));
        setFailedPercentage(Number(failedPercentage));
        setPendingPercentage(Number(pendingPercentage));
    }, [sendCounts]);

    // Use the useEffect hook to fetch data on component mount
    useEffect(() => {
        fetchData();
        ReceiversByTemplate();
    }, [templateId]);

    const handleSearchChange = (event) => {
        setSearchTerm(event.target.value);
    };

    // Filter the report data based on the search term
    const filteredContactData = contactData
        ? contactData.filter((contact) =>
            contact.name.toLowerCase().includes(searchTerm.toLowerCase()) ||
            contact.mobile_no.toLowerCase().includes(searchTerm.toLowerCase())
        )
        : [];

    const mappedContactData = contactData || [];


    if (loading) {
        return (
            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
                <CircularProgress />
            </div>
        );
    }

    // Render error state
    if (error) {
        return <div>Error: {error}</div>;
    }
    // Accordion Function


    return (
        <>
            <div style={{
                margin: '24px 0px 0px',
                borderRadius: '9px',

            }}>
                <Box sx={{ width: '100%' }}>
                    <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
                        <Grid item xs={12}>
                            <Item className='inneritems'>
                                <Stack direction="row" spacing={2} sx={{ "align-items": "center" }}>
                                    <Stack direction="column" spacing={2} sx={{ "textAlign": "left" }}>
                                        <Typography variant='h4' sx={{ "fontSize": "21px", "color": "#000", "fontWeight": "600" }}>{templateName}</Typography>
                                    </Stack>
                                </Stack>
                            </Item>
                        </Grid>
                    </Grid>
                </Box>
                <Box sx={{ width: '100%' }} className={Helpers.cmAccess('NOTIFICATION_RESULTS_BOX')}>
                    <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }} columns={12} mt={2}>
                        <Grid item xs={2}>
                            <Item className='griditems' sx={{ "padding": "11px 13px", }}>
                                <Stack direction="column" spacing={2} sx={{ "textAlign": "left" }}>
                                    <Box aria-label="Example" style={{
                                        "width": "fit-content",
                                        "background": "#FAE3E3",
                                        "border-radius": "7px",
                                        "padding": "7px 8px",
                                        "justify-content": "center",
                                        "display": "flex",
                                    }}>
                                        <img src='/phone.png' />
                                    </Box>
                                    <Typography style={{ fontSize: 10, color: "#949494", marginTop: 13, }}>Total Messages</Typography>
                                    <h4 style={{ fontSize: 18, marginBottom: 0, marginTop: 8, color: "#1C1C1C" }}>{sendCounts.totalAttempts}(100%)</h4>
                                </Stack>
                            </Item>
                        </Grid>
                        <Grid item xs={2}>
                            <Item className='griditems' sx={{ "padding": "11px 13px", }}>
                                <Stack direction="column" spacing={2} sx={{ "textAlign": "left" }}>
                                    <Box aria-label="Example" style={{
                                        "width": "fit-content",
                                        "background": "#E8FAE3",
                                        "border-radius": "7px",
                                        "padding": "7px 8px",
                                        "justify-content": "center",
                                        "display": "flex",
                                    }}>
                                        <img src='/msg.png' />
                                    </Box>
                                    <Typography style={{ fontSize: 10, color: "#949494", marginTop: 13, }}>Sent</Typography>
                                    <h4 style={{ fontSize: 18, marginBottom: 0, marginTop: 8, color: "#1C1C1C" }}>{sendCounts.totalSent}({sendPercentage.toFixed(2)}%)</h4>
                                </Stack>
                            </Item>
                        </Grid>
                        <Grid item xs={2}>
                            <Item className='griditems' sx={{ "padding": "11px 13px", }}>
                                <Stack direction="column" spacing={2} sx={{ "textAlign": "left" }}>
                                    <Box aria-label="Example" style={{
                                        "width": "fit-content",
                                        "background": "#E0F4FF",
                                        "border-radius": "7px",
                                        "padding": "7px 8px",
                                        "justify-content": "center",
                                        "display": "flex",
                                    }}>
                                        <img src='/message.png' />
                                    </Box>
                                    <Typography style={{ fontSize: 10, color: "#949494", marginTop: 13, }}>Read</Typography>
                                    <h4 style={{ fontSize: 18, marginBottom: 0, marginTop: 4, color: "#1C1C1C" }}>{sendCounts.read}({readPercentage.toFixed(2)}%)</h4>
                                </Stack>
                            </Item>
                        </Grid>
                        <Grid item xs={2}>
                            <Item className='griditems' sx={{ "padding": "11px 13px", }}>
                                <Stack direction="column" spacing={2} sx={{ "textAlign": "left" }}>
                                    <Box aria-label="Example" style={{
                                        "width": "fit-content",
                                        "background": "#FFF8E0",
                                        "border-radius": "7px",
                                        "padding": "7px 8px",
                                        "justify-content": "center",
                                        "display": "flex",
                                    }}>
                                        <img src='/setting.png' />
                                    </Box>
                                    <Typography style={{ fontSize: 10, color: "#949494", marginTop: 13, }}>Failed</Typography>
                                    <h4 style={{ fontSize: 18, marginBottom: 0, marginTop: 4, color: "#1C1C1C" }}>{sendCounts.failed}({failedPercentage.toFixed(2)}%)</h4>
                                </Stack>
                            </Item>
                        </Grid>
                        <Grid item xs={2}>
                            <Item className='griditems' sx={{ "padding": "11px 13px", }}>
                                <Stack direction="column" spacing={2} sx={{ "textAlign": "left" }}>
                                    <Box aria-label="Example" style={{
                                        "width": "fit-content",
                                        "background": "#FFF8E0",
                                        "border-radius": "7px",
                                        "padding": "7px 8px",
                                        "justify-content": "center",
                                        "display": "flex",
                                    }}>
                                        <img src='/setting.png' />
                                    </Box>
                                    <Typography style={{ fontSize: 10, color: "#949494", marginTop: 13, }}>Delivered</Typography>
                                    <h4 style={{ fontSize: 18, marginBottom: 0, marginTop: 4, color: "#1C1C1C" }}>{sendCounts.delivered}({deliveredPercentage.toFixed(2)}%)</h4>
                                </Stack>
                            </Item>
                        </Grid>
                        <Grid item xs={2}>
                            <Item className='griditems' sx={{ "padding": "11px 13px", }}>
                                <Stack direction="column" spacing={2} sx={{ "textAlign": "left" }}>
                                    <Box aria-label="Example" style={{
                                        "width": "fit-content",
                                        "background": "#FFF8E0",
                                        "border-radius": "7px",
                                        "padding": "7px 8px",
                                        "justify-content": "center",
                                        "display": "flex",
                                    }}>
                                        <img src='/setting.png' />
                                    </Box>
                                    <Typography style={{ fontSize: 10, color: "#949494", marginTop: 13, }}>Pending</Typography>
                                    <h4 style={{ fontSize: 18, marginBottom: 0, marginTop: 4, color: "#1C1C1C" }}>{sendCounts.pending}({pendingPercentage.toFixed(2)}%)</h4>
                                </Stack>
                            </Item>
                        </Grid>
                    </Grid>
                </Box>
                <Box sx={{ width: '100%' }}>
                    <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }} mt={2}>
                        <Grid item xs={6} className={Helpers.cmAccess('NOTIFICATION_GRAPH')}>
                            <Item className='griditems'>
                                <Stack direction='row' spacing={1}>
                                    <Box aria-label="Example" style={{
                                        "width": "fit-content",
                                        "background": "#E8FAE3",
                                        "border-radius": "7px",
                                        "padding": "7px 8px 2px",
                                        "marginRight": "9px",
                                    }}>
                                        <img src='/msg.png' />

                                    </Box>
                                    <Typography variant="h6" style={{ fontSize: 16, color: "#000", }}>Message Status</Typography>
                                </Stack>
                                <Box sx={{ width: '100%' }}>
                                    <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
                                        <Grid item xs={12}>
                                            <Item className='inneritems'>
                                                <div>
                                                    <PieChart width={400} height={280} style={{ "outline": "none" }}>
                                                        <Pie
                                                            data={data}
                                                            dataKey="value"
                                                            cx={250}
                                                            cy={135}
                                                            fill="#8884d8"
                                                            startAngle={0}
                                                            endAngle={360}
                                                            style={{ "outline": "none" }}
                                                            label
                                                        >
                                                            {data.map((entry, index) => (
                                                                <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} style={{ "outline": "none" }} />
                                                            ))}
                                                        </Pie>
                                                    </PieChart>
                                                    <div style={{ marginTop: 40 }}>
                                                        <ul style={{ "display": "flex", "padding": "10px 45px", "listStyle": "none", justifyContent: "space-around" }}>
                                                            {data.map((item, index) => (
                                                                <li key={index} style={{ "margin-right": "10px", "color": "#000", "display": "flex", "alignItems": "center", "flexDirection": "column", "gap": "8px" }}>
                                                                    <span style={{ "width": "15px", "height": "15px", "borderRadius": "50%", "background": COLORS[index % COLORS.length], "marginRight": "5px" }}></span>
                                                                    <Typography sx={{ "fontSize": "10px", "color": "#000", "fontWeight": "500" }}>{item.name}:</Typography><b>{item.value}</b>
                                                                </li>
                                                            ))}
                                                        </ul>
                                                    </div>
                                                </div>
                                            </Item>
                                        </Grid>
                                    </Grid>
                                </Box>
                            </Item>
                        </Grid>
                        <Grid item xs={6} className={Helpers.cmAccess('NOTIFICATION_TEMPLATE_HISTORY')}>
                            <Item className='griditems'>
                                <Stack direction='row' spacing={1}>
                                    <Box aria-label="Example" style={{
                                        "width": "fit-content",
                                        "background": "#E3EAFA",
                                        "border-radius": "7px",
                                        "padding": "7px 8px 2px",
                                        "marginRight": "9px",
                                    }}>
                                        <img src='/message.png' />

                                    </Box>
                                    <Typography variant="h6" style={{ fontSize: 16, color: "#000", }}>Receivers</Typography>
                                </Stack>
                                <Box sx={{ width: '100%' }}>
                                    <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }} mt={2}>
                                        <Grid item xs={12}>
                                            <Item className='inneritems'>
                                                <Box
                                                    sx={{
                                                        "border": " 1px solid #F5F5F5",
                                                        "border-radius": "9px",
                                                    }}
                                                >
                                                    <TableContainer component={Paper}>
                                                        <Table>
                                                            <TableHead>
                                                                <TableRow>
                                                                    <TableCell>ID</TableCell>
                                                                    <TableCell>Name</TableCell>
                                                                    <TableCell>Mobile no</TableCell>
                                                                    <TableCell>Attempts</TableCell>
                                                                </TableRow>
                                                            </TableHead>
                                                            <TableBody>
                                                                {contactData.map((contact, index) => (
                                                                    <TableRow key={contact.id}>
                                                                        <TableCell>{index + 1}</TableCell>
                                                                        <TableCell><NavLink to={`/dashboard/contactus/userdashboard`} style={{ "background-color": "transparent", "text-decoration": "none", "color": "#0889FF" }} replace={true} state={contact.id} className={Helpers.cmAccess('CONTACTS_VIEW')}>
                                                                            {contact.name}
                                                                        </NavLink></TableCell>
                                                                        <TableCell>+{contact.mobile_no}</TableCell>
                                                                        <TableCell>{calculateTotal(contact)}</TableCell>
                                                                    </TableRow>
                                                                ))}
                                                            </TableBody>
                                                        </Table>
                                                    </TableContainer>
                                                </Box>
                                            </Item>
                                        </Grid>
                                    </Grid>
                                </Box>
                            </Item>
                        </Grid>
                    </Grid>
                </Box>
                <Box sx={{ width: '100%' }} className={Helpers.cmAccess('NOTIFICATION_TEMPLATE_SENDING_HISTORY')}>
                    <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }} mt={2} mb={2}>
                        <Grid item xs={12}>
                            <Item className='griditems'>
                                <Box sx={{ width: '100%' }}>
                                    <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }} mb={2}>
                                        <Grid item xs={6}>
                                            <Item className='inneritems'>
                                                <Stack direction='row' spacing={1}>
                                                    <Box aria-label="Example" style={{
                                                        "width": "fit-content",
                                                        "background": "#E3EAFA",
                                                        "border-radius": "7px",
                                                        "padding": "7px 8px 2px",
                                                        "marginRight": "9px",
                                                    }}>
                                                        <img src='/message.png' />

                                                    </Box>
                                                    <Typography variant="h6" style={{ fontSize: 16, color: "#000", }}>Template Sent History</Typography>
                                                </Stack>
                                            </Item>
                                        </Grid>
                                    </Grid>
                                </Box>
                                <div>
                                    {templateSendData.map((template, index) => (
                                        <Accordion expanded={expanded === index} onChange={handleChange(index)}>
                                            <AccordionSummary
                                                expandIcon={<ExpandMoreIcon />}
                                                aria-controls="panel1bh-content"
                                                id="panel1bh-header"
                                            >
                                                <Typography sx={{ width: '33%', flexShrink: 0, textAlign: "left", }}>
                                                    Context {index + 1}
                                                </Typography>
                                            </AccordionSummary>
                                            <AccordionDetails>
                                                <Box sx={{ width: '100%' }}>
                                                    <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
                                                        <Grid item xs={12} sx={{ padding: 0, }}>
                                                            <Item className='inneritems'>
                                                                <Box
                                                                    sx={{
                                                                        "border": " 1px solid #F5F5F5",
                                                                        "border-radius": "9px",
                                                                    }}
                                                                >
                                                                    <TableContainer>
                                                                        <Table>
                                                                            <TableHead>
                                                                                <TableRow>
                                                                                    <TableCell>Sr No</TableCell>
                                                                                    <TableCell>Name</TableCell>
                                                                                    <TableCell>Message Time</TableCell>
                                                                                    <TableCell>Message Status</TableCell>
                                                                                </TableRow>
                                                                            </TableHead>
                                                                            <TableBody>
                                                                                {template.contact
                                                                                .sort((a, b) => new Date(b.message_time) - new Date(a.message_time))
                                                                                .map((contact, contactIndex) => (
                                                                                    <TableRow>
                                                                                        <TableCell>{contactIndex + 1}</TableCell>
                                                                                        <TableCell>{contact.name}</TableCell>
                                                                                        <TableCell>{contact.message_time}</TableCell>
                                                                                        <TableCell>{contact.status}</TableCell>
                                                                                    </TableRow>
                                                                                ))}
                                                                            </TableBody>
                                                                        </Table>
                                                                    </TableContainer>
                                                                </Box>
                                                            </Item>
                                                        </Grid>
                                                    </Grid>
                                                </Box>
                                            </AccordionDetails>
                                        </Accordion>
                                    ))}
                                </div>
                            </Item>
                        </Grid>
                    </Grid>
                </Box>

            </div>
        </>
    );
};

export default Notification;
