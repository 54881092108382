import * as React from 'react';
import { styled } from '@mui/material/styles';
import Paper from '@mui/material/Paper';
import { Helpers } from '../service';
import { UserApi } from '../service/axios';
import { useNavigate } from 'react-router-dom';
import { useLocation } from 'react-router-dom';
import {
    Typography,
    Grid,
    Box,
    Button,
    TextField,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Stack,
    Accordion,
    AccordionDetails,
    AccordionSummary,
    Select,
    MenuItem,
} from '@mui/material';
import { useEffect, useState } from 'react';
import { PieChart, Pie, Cell, ResponsiveContainer } from 'recharts';
import CircularProgress from '@mui/material/CircularProgress';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';



const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
    ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: 'center',
    color: theme.palette.text.secondary,
}));
const COLORS = ['#D2175A', '#FF6600', '#FF9650', '#59BE1B', '#6856D7'];


export default function ResponseData() {
    const [tempId, setTempId] = useState('');
    const navigate = useNavigate();
    const { state } = useLocation();
    const [tempData, setTempData] = useState([]);
    const [graphData, setGraphData] = useState([]);
    const [contactData, setContactData] = useState([]);
    const [uniquemsgData, setUniquemsgData] = useState([]);
    const [totalMessages, setTotalMessages] = useState('');
    const [respondMessages, setRespondMessages] = useState('');
    const [nonRespondMessages, setNonRespondMessages] = useState('');
    const [loading, setLoading] = useState(true);
    const [accordionVisible, setAccordionVisible] = useState(false);
    const [expanded, setExpanded] = React.useState(false);
    const [selectedOption, setSelectedOption] = useState('Not Responded');
    const [filteredData, setFilteredData] = useState([]);
    const [buttons, setButtons] = useState([]);
    const [initialLoad, setInitialLoad] = useState(true);


    const handleChange = (panel) => (event, isExpanded) => {
        setExpanded(isExpanded ? panel : false);
    };

    useEffect(() => {
        if (state) {
            setTempId(state);
            setTempData([]);
            setGraphData([]);
            setContactData([]);
            setTotalMessages('');
            setRespondMessages('');
            setNonRespondMessages('');
            setLoading(true);
            setAccordionVisible(false);
            setExpanded(false);

            getResponseDetail(state);
        }
    }, [state]);

    const getResponseDetail = (id) => {
        setLoading(true);
        UserApi.TemplateResponseDatawithid(id).then((resp) => {
            if (resp.status === 200) {
                var responsecount = 0;
                var totalcount = 0;
                const uniqueNamesSet = new Set();
                const uniqueMobileNoSet = new Set();
                const uniquegraphSet = [];
                const quickButton = resp.data.template_contexts.template.buttons;
                for (let i = 0; i < quickButton.length; i++) {
                    const quicks = quickButton[i];
                    const updatedGraphData = {
                        message: quicks.button_text || '',
                        count: 0,
                    };
                    uniquegraphSet.push(updatedGraphData);
                }

                const messages = resp.data.template_contexts.messages;
                const buttons = resp.data.template_contexts.template.buttons;
                setButtons(buttons);
                const messageCounts = {};
                messages.forEach((message) => {
                    const messageId = message.message_id;
                    messageCounts[messageId] = (messageCounts[messageId] || 0) + 1;
                });
                const nonRepeatedMessages = messages.filter((message) => {
                    const messageId = message.message_id;
                    return messageCounts[messageId] === 1;
                });

                setUniquemsgData(nonRepeatedMessages);
                for (let i = 0; i < messages.length; i++) {
                    const message = messages[i];
                    if (message.type === "SEND" && message.status !== "failed" && message.status !== "pending") {
                        totalcount++;
                    }

                    if (message.type === "TEMP_REPLY") {
                        const updatedContactData = {
                            name: message.contact[0]?.name || '',
                            mobile: message.sender || '',
                            message_time: message.message_time || '',
                            message: message.message || '',
                        };
                        if (!uniqueNamesSet.has(updatedContactData.name) && !uniqueMobileNoSet.has(updatedContactData.mobile)) {
                            const index = uniquegraphSet.findIndex(item => item.message === updatedContactData.message);
                            if (index !== -1) {
                                uniquegraphSet[index].count += 1;
                            }
                            setContactData((prevData) => [...prevData, updatedContactData]);
                            uniqueNamesSet.add(updatedContactData.name);
                            uniqueMobileNoSet.add(updatedContactData.mobile);
                            responsecount++;
                        } else {
                        }
                    }
                }
                setGraphData(uniquegraphSet);
                setNonRespondMessages(totalcount - responsecount);
                setGraphData((prevData) => [...prevData, {
                    message: "Not Responded",
                    count: totalcount - responsecount,
                }]);
                setRespondMessages(responsecount);
                setTotalMessages(totalcount);
                if (contactData.length > 6) {
                    setAccordionVisible(true);
                }
            } else {
                alert(resp.data.error_msg);
            }
        }).catch((error) => {
            Helpers.error401(error);
        })
            .finally(() => {
                setLoading(false);
            });
    };

    const handleChanges = (selectedValue) => {
        if (selectedValue === "Not Responded") {
        } else {
            const filteredContacts = contactData.filter((contact) => contact.message === selectedValue);
            setFilteredData(filteredContacts);
        }
    };

    const data = [
        { name: 'Sent', value: 20 },
        { name: 'Failed', value: 30 },
        { name: 'Read', value: 55 },
    ];
    return (
        <>
            {loading ? (
                <div style={{ textAlign: 'center', paddingTop: '150px' }}>
                    <CircularProgress />
                </div>
            ) : (
                <div style={{
                    "padding": "20px 20px 0",
                    "borderRadius": "10px",
                    "marginTop": "10px",
                }}>
                    <Box sx={{ width: '100%' }}>
                        <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }} columns={12} mt={2}>
                            <Grid item xs={8} md={3} sx={3}>
                                <Item className='griditems' sx={{ "padding": "11px 13px", }}>
                                    <Stack direction="column" spacing={2} sx={{ "textAlign": "left" }}>
                                        <Box aria-label="Example" style={{
                                            "width": "fit-content",
                                            "background": "#FAE3E3",
                                            "border-radius": "7px",
                                            "padding": "7px 8px",
                                            "justify-content": "center",
                                            "display": "flex",
                                        }}>
                                            <img src='/phone.png' />
                                        </Box>
                                        <Typography style={{ fontSize: 10, color: "#949494", marginTop: 13, }}>Total Send</Typography>
                                        <h4 style={{ fontSize: 18, marginBottom: 0, marginTop: 8, color: "#1C1C1C" }}>{totalMessages}</h4>
                                    </Stack>
                                </Item>
                            </Grid>
                            <Grid item xs={8} md={3} sx={3}>
                                <Item className='griditems' sx={{ "padding": "11px 13px", }}>
                                    <Stack direction="column" spacing={2} sx={{ "textAlign": "left" }}>
                                        <Box aria-label="Example" style={{
                                            "width": "fit-content",
                                            "background": "#E8FAE3",
                                            "border-radius": "7px",
                                            "padding": "7px 8px",
                                            "justify-content": "center",
                                            "display": "flex",
                                        }}>
                                            <img src='/msg.png' />
                                        </Box>
                                        <Typography style={{ fontSize: 10, color: "#949494", marginTop: 13, }}>Responded</Typography>
                                        <h4 style={{ fontSize: 18, marginBottom: 0, marginTop: 8, color: "#1C1C1C" }}>{respondMessages}</h4>
                                    </Stack>
                                </Item>
                            </Grid>
                            <Grid item xs={8} md={3} sx={3}>
                                <Item className='griditems' sx={{ "padding": "11px 13px", }}>
                                    <Stack direction="column" spacing={2} sx={{ "textAlign": "left" }}>
                                        <Box aria-label="Example" style={{
                                            "width": "fit-content",
                                            "background": "#E0F4FF",
                                            "border-radius": "7px",
                                            "padding": "7px 8px",
                                            "justify-content": "center",
                                            "display": "flex",
                                        }}>
                                            <img src='/message.png' />
                                        </Box>
                                        <Typography style={{ fontSize: 10, color: "#949494", marginTop: 13, }}>Non Responded</Typography>
                                        <h4 style={{ fontSize: 18, marginBottom: 0, marginTop: 4, color: "#1C1C1C" }}>{nonRespondMessages}</h4>
                                    </Stack>
                                </Item>
                            </Grid>
                        </Grid>
                    </Box>
                    {/* Pie Chart */}
                    <Box sx={{ width: '100%' }}>
                        <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }} mt={2} mb={2}>
                            <Grid item xs={12} md={6} sx={6}>
                                <Item className='griditems'>
                                    <div>
                                        <ResponsiveContainer width="100%" height={280}>
                                            <PieChart width={400} height={280} style={{ "outline": "none" }}>
                                                <Pie
                                                    data={graphData}
                                                    dataKey="count"
                                                    cx="50%"
                                                    cy="50%"
                                                    fill="#8884d8"
                                                    startAngle={0}
                                                    endAngle={360}
                                                    style={{ "outline": "none" }}
                                                    label
                                                >
                                                    {graphData.map((entry, index) => (
                                                        <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} style={{ "outline": "none" }} />
                                                    ))}
                                                </Pie>
                                            </PieChart>
                                        </ResponsiveContainer>
                                        <div style={{ marginTop: 20 }}>
                                            <ul style={{ "display": "flex", "padding": "10px 45px", "listStyle": "none", justifyContent: "space-around" }}>
                                                {graphData.map((item, index) => (
                                                    <li key={index} style={{ "margin-right": "10px", "color": "#000", "display": "flex", "alignItems": "center", "flexDirection": "column", "gap": "8px" }}>
                                                        <span style={{ "width": "15px", "height": "15px", "borderRadius": "50%", "background": COLORS[index % COLORS.length], "marginRight": "5px" }}></span>
                                                        <Typography sx={{ "fontSize": "10px", "color": "#000", "fontWeight": "500" }}>{item.message}:</Typography><b>{item.count}</b>
                                                    </li>
                                                ))}
                                            </ul>
                                        </div>
                                    </div>
                                </Item>
                            </Grid>
                            <Grid item xs={12} md={6} sx={6}>
                                <Item className='griditems'>
                                    <Box
                                        sx={{
                                            "border": " 1px solid #F5F5F5",
                                            "border-radius": "9px",
                                        }}
                                    >
                                        <Grid item xs={12} md={6} >
                                            <TextField
                                                select
                                                label="Response Option"
                                                value={selectedOption}
                                                onChange={(e) => {
                                                    setSelectedOption(e.target.value);
                                                    handleChanges(e.target.value);
                                                }}
                                                fullWidth
                                                variant="outlined"
                                                margin="normal"
                                            >
                                                {buttons.map((button, index) => (
                                                    <MenuItem key={index} value={button.button_text}>
                                                        {button.button_text}
                                                    </MenuItem>
                                                ))}
                                                <MenuItem value="Not Responded">Not Responded</MenuItem>
                                            </TextField>
                                        </Grid>
                                        <TableContainer>
                                            <Table sx={{ minWidth: '100%' }} aria-label="simple table">
                                                <TableHead>
                                                    <TableRow>
                                                        <TableCell>Name</TableCell>
                                                        <TableCell>Mobile No.</TableCell>
                                                        <TableCell>Response</TableCell>
                                                        <TableCell>Response Time</TableCell>
                                                    </TableRow>
                                                </TableHead>
                                                <TableBody>
                                                    {selectedOption === "Not Responded" ? (
                                                        uniquemsgData.map((uniquemsg, index) => (
                                                            <TableRow key={index}>
                                                                <TableCell style={{ color: '#0889FF' }}>{uniquemsg.contact[0] ? uniquemsg.contact[0].name : ''}</TableCell>
                                                                <TableCell style={{ color: '#51921F', fontWeight: 700 }}>{uniquemsg.reciever}</TableCell>
                                                                <TableCell style={{ color: 'red', fontWeight: 700 }}>Not Responded</TableCell>
                                                                <TableCell style={{ color: '#51921F', fontWeight: 700, textAlign: "center" }}>➖</TableCell>
                                                            </TableRow>
                                                        ))
                                                    ) : (
                                                        filteredData.map((contact, index) => (
                                                            <TableRow key={index}>
                                                                <TableCell style={{ color: '#0889FF' }}>{contact.name}</TableCell>
                                                                <TableCell style={{ color: '#51921F', fontWeight: 700 }}>{contact.mobile}</TableCell>
                                                                <TableCell style={{ color: '#51921F', fontWeight: 700 }}>{contact.message}</TableCell>
                                                                <TableCell style={{ color: '#51921F', fontWeight: 700 }}>{contact.message_time}</TableCell>
                                                            </TableRow>
                                                        ))
                                                    )}
                                                </TableBody>
                                            </Table>
                                        </TableContainer>
                                    </Box>
                                </Item>
                            </Grid>
                        </Grid>
                    </Box>
                    <Box sx={{ width: '100%' }} >
                        <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }} mt={2} mb={2}>
                            <Grid item xs={12}>
                                <Item className='griditems'>
                                    <Box sx={{ width: '100%' }}>
                                        <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }} mb={2}>

                                        </Grid>
                                    </Box>
                                    <div>
                                        {contactData && contactData.length > 0 && (
                                            <Accordion expanded={expanded === 'panel1'} onChange={handleChange('panel1')}>
                                                <AccordionSummary
                                                    expandIcon={<ExpandMoreIcon />}
                                                    expandIconPosition="start"
                                                    aria-controls="panel1bh-content"
                                                    id="panel1bh-header"
                                                >
                                                    <Item className='inneritems'>
                                                        <Stack direction='row' spacing={1}>
                                                            <Box aria-label="Example" style={{
                                                                "width": "fit-content",
                                                                "background": "#E3EAFA",
                                                                "border-radius": "7px",
                                                                "padding": "7px 8px 2px",
                                                                "marginRight": "9px",
                                                            }}>
                                                                <img src='/message.png' />
                                                            </Box>
                                                            <Typography variant="h6" style={{ fontSize: 16, color: "#000", }}>Campaign Response History</Typography>
                                                        </Stack>
                                                    </Item>
                                                </AccordionSummary>
                                                <AccordionDetails>
                                                    <Box sx={{ width: '100%' }}>
                                                        <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
                                                            <Grid item xs={12} sx={{ padding: 0, }}>
                                                                <Item className='inneritems'>
                                                                    <Box
                                                                        sx={{
                                                                            "border": " 1px solid #F5F5F5",
                                                                            "border-radius": "9px",
                                                                        }}
                                                                    >
                                                                        <TableContainer>
                                                                            <Table>
                                                                                <TableHead>
                                                                                    <TableRow>
                                                                                        <TableCell>Sr No</TableCell>
                                                                                        <TableCell>Name</TableCell>
                                                                                        <TableCell>Mobile No.</TableCell>
                                                                                        <TableCell>Response</TableCell>
                                                                                        <TableCell>Response Time</TableCell>
                                                                                    </TableRow>
                                                                                </TableHead>
                                                                                <TableBody>
                                                                                    {contactData
                                                                                        .sort((a, b) => new Date(b.message_time) - new Date(a.message_time))
                                                                                        .map((contact, index) => (
                                                                                            <TableRow key={index}>
                                                                                                <TableCell>{index + 1}</TableCell>
                                                                                                <TableCell style={{ color: '#0889FF' }}>{contact.name} </TableCell>
                                                                                                <TableCell style={{ color: '#0889FF' }}> {contact.mobile}</TableCell>
                                                                                                <TableCell style={{ color: '#51921F', fontWeight: 700 }}>{contact.message}</TableCell>
                                                                                                <TableCell style={{ color: '#51921F', fontWeight: 700 }}>{contact.message_time}</TableCell>
                                                                                            </TableRow>
                                                                                        ))}
                                                                                </TableBody>
                                                                            </Table>
                                                                        </TableContainer>
                                                                    </Box>
                                                                </Item>
                                                            </Grid>
                                                        </Grid>
                                                    </Box>
                                                </AccordionDetails>
                                            </Accordion>
                                        )}
                                    </div>
                                </Item>
                            </Grid>
                        </Grid>
                    </Box>
                </div>
            )}
        </>
    )
}