import { AuthApi, UserApi } from '../../service/index';
import React from 'react';
import { toast } from 'react-toastify';
// import Papa from 'papaparse';

class Helper {
    
	
    loggedUserInfo() {
	    let userInfo = "";
		if(localStorage.getItem('userInfo'))
		{
			userInfo = JSON.parse(localStorage.getItem('userInfo'));
		}
		return userInfo
    }
	
	setAdminAccess(roles) {
	    let loggedUser = this.loggedUserInfo();
		let access = 'HIDDEN';
		if (roles.includes(loggedUser.role.role_key)) {
			access = "READ_WRITE";
		}
		
		return access;
    }
	
	getLSByName(lsName) {
	    let info = "";
		if(localStorage.getItem(lsName))
		{
			info = JSON.parse(localStorage.getItem(lsName));
		}
		return info;
    }
	
	createLSByName(lsName,value) {
	    let info = "";
		if(value)
		{
			info = localStorage.setItem(lsName,JSON.stringify(value));
		}
		return info
    }
	
	checkLogin() {
		
		let response = true;
	    let userInfo = this.getLSByName('userInfo');
	    let token = this.getLSByName('token');
		if(!userInfo || !token)
		{
			response = false;
		}
		return response
    }
	
	logout(history) {
		
		let institution_id = this.getLSByName('institution_id');
		this.logoutApi();
	    localStorage.clear();
		if(institution_id)
		{
			this.createLSByName("institution_id",institution_id);
		}
	    
	    window.location.href="/";
    }
	
	logoutApi(){
        AuthApi.logout('logout?islogout=true',{}).then(
            (resp) => {
                
                    console.log("error", resp.data.error_msg);
                
            }
        ).catch((error) => {
            if (error.response) {
				console.log(error.response);
			}
        });
    };

	getComponent(id){
        UserApi.getRoleWiseComponents(id).then(
            (resp) => {
                if (resp.status === 200) {
                    this.createLSByName('components',resp.data.data.component);
					window.location.href="/dashboard/app";
                } else {
                    console.log("error", resp.data.error_msg);
                }
            }
        ).catch((error) => {
            if (error.response) {
				console.log(error.response);
			}
        });
    };
	
	cmAccess(key)
	{
		const components = this.getLSByName('components');
		let access = 'READ_WRITE';
		if (components) {
		  for (const item of components) {
			if (item.component_key === key) {
			  access = item.access ? item.access : 'READ_WRITE';
			  break;
			}
		  }
		}

		return access;
	}
	
	error401(error)
	{
		if (error.response) {
			if(error.response.status == 401)
			{
				this.toastError("Logout");
				
				setTimeout(() => {
				  this.logout("Token expairy");
				}, 2000);
			}
			else if(error.response.status == 400)
			{
				this.toastError(error.response.data.error_msg);
			}
		}
	}
	
	toastSuccess(msg)
	{
		toast.success(msg);
	}
	toastError(msg)
	{
		toast.error(msg);
	}

	

	

	
	
}

export default Helper;