import { useState, useEffect } from 'react';
import { Outlet, useNavigate } from 'react-router-dom';
// material
import { styled } from '@mui/material/styles';
//

import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import SideNavBar from './SideNavBar';
import Login from '../pages/Login';
// ----------------------------------------------------------------------

const APP_BAR_MOBILE = 64;
const APP_BAR_DESKTOP = 92;

const RootStyle = styled('div')({
  display: 'flex',
  minHeight: '100%',
  overflow: 'hidden'
});

const MainStyle = styled('div')(({ theme }) => ({
  flexGrow: 1,
  overflow: 'auto',
  minHeight: '100%',
  paddingTop: APP_BAR_MOBILE + 24,
  paddingBottom: theme.spacing(10),
  [theme.breakpoints.up('lg')]: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2)
  }
}));

// ----------------------------------------------------------------------

export default function DashboardLayout() {
  const [open, setOpen] = useState(false);
  // const navigate = useNavigate();

  // useEffect(() => {
  //   // Redirect to the dashboard when the component mounts
  //   navigate('/dashboard');
  // }, ['']);

  return (
    <RootStyle style={{ height: "100vh" }}>
      <SideNavBar />
      <MainStyle style={{"background": " #f1f1f1","padding-left":" 44px",}}>
        <ToastContainer />
        <Outlet />
      </MainStyle>
    </RootStyle>
  );
}
