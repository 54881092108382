import { Box, Typography, TextField, Button } from '@mui/material'
import { useState, useEffect } from 'react';
import { ThemeProvider, createTheme } from '@mui/material/styles'
import { Link, useNavigate } from 'react-router-dom'
import { AuthApi, Helpers } from '../service/index'
import { ToastContainer, toast } from 'react-toastify';
import Fade from '@mui/material/Fade';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import 'react-toastify/dist/ReactToastify.css';


const images = ['scr1m.png', 'scr2m.png', 'scr3m.png'];
const imagess = ['scr1l.png', 'scr2l.png', 'scr3l.png'];

// Create a theme for the component
const theme = createTheme({
  palette: {
    primary: {
      main: '#E27606',
    },
    secondary: {
      main: '#9e9e9e',
    },
  },
})

// Forgotpass component
const Forgotpass = () => {

  
  const [index, setIndex] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      setIndex((prevIndex) => (prevIndex + 1) % imagess.length);
    }, 6000);

    return () => clearInterval(interval);
  }, []);

  useEffect(() => {
    const interval = setInterval(() => {
      setIndex((prevIndex) => (prevIndex + 1) % imagess.length);
    }, 6000);

    return () => clearInterval(interval);
  }, []);

  // Navigate to different pages
  const navigate = useNavigate()

  // State to handle errors
  const [errors, setErrors] = useState({ 'key': false, 'message': '' })

  // Function to handle forgot password
  const handleforgotpass = async (event) => {
    setErrors({ 'key': '', 'message': '' })
    event.preventDefault()
    try {
      const response = await AuthApi.forgotpass({
        email: event.target.email.value, // Assuming you have an input field with the name 'email'
      })


      if (response.status === 200) {
        toast.success('Email successfully Send!');
        // alert('Success') // You may want to replace this with a more user-friendly notification
        navigate('/login') // Redirect to the login page upon success
      } else {
        console.log("Error:", response)
      }
    } catch (error) {
      console.error('Error during forgotpass:', error)
    }
  }

  // Render the component
  return (
    <ThemeProvider theme={theme}>
      <div>
      <ToastContainer />
      <Grid container spacing={2}>
  <Grid item xs={7} style={{background: "linear-gradient(rgb(255 102 0 / 14%) 0%, rgb(255 102 0 / 0%) 100%)",height: "100vh",padding: 0,}}>
  <div>
          <img src="/shape1.png" className="shapess" />
            
          </div>
          <div className="textmainimg">
            <h2>Welcome To Bel N<span className="notifireee"><img src="/bell_fire2.gif" /></span>tifier</h2>
            <p>Lorem Isum is a dummy text for all dummy websites.</p>
          </div>
          <div className="relativemain">

            <img src="/login1.png" className="imglogin" />
            <img src="/login2.png" className="imglogin2" />
            
            <div className="mokeup image-container">
            <Container>
      {images.map((image, i) => (
        <Fade
        key={i}
        in={i === index}
        timeout={3000}
        mountOnEnter
        unmountOnExit
          className="imglogin3"
          style={{transition:"all 0.3s ease-in-out"}}
        >
          <div>
            <img style={{ width: "100%",transition:"all 0.3s ease-in-out"}} src={image} alt={`Slide ${i + 1}`}  />
          </div>
        </Fade>
      ))}
    </Container>
    <Container>
      {imagess.map((imagesss, i) => (
        <Fade
        key={i}
        in={i === index}
        timeout={3000}
        mountOnEnter
        unmountOnExit
          className="imglogin4"
          style={{transition:"all 0.3s ease-in-out"}}
        >
          <div>
            <img style={{ width: "100%",transition:"all 0.3s ease-in-out"}} src={imagesss} alt={`Slide ${i + 1}`}  />
          </div>
        </Fade>
      ))}
    </Container>
            </div>
          </div>
          
  </Grid>
  <Grid item xs={5}>
  <form onSubmit={handleforgotpass}>
          <Box
            display="flex"
            flexDirection={'column'}
            maxWidth={320}
            height={390}
            justifyContent={"center"}
            margin="auto"
            marginTop={7}
            padding={3}
            borderRadius={2}
            boxShadow={"1px 1px 10px #ccc"}
          >
            <Box display="flex" justifyContent={"space-between"} marginBottom={2}>
              <Typography variant='h5'> Forgot password </Typography>
            </Box>
            <Typography marginBottom={3} color={'grey'}>
              To Rest your password <br />
              Please Enter Your Email Address
            </Typography>
            <Typography>Email</Typography>
            <TextField name="email" type='email' variant='outlined' placeholder='Email' /><br />
            <Button type="submit" marginTop={10} variant="contained" disableElevation color="primary">
             Submit
            </Button>
            <Link
              to="/login"
              style={{
                textDecoration: "none",
                color: "#9e9e9e",
                display: 'flex',
                justifyContent: "center",
                alignItems: "center",
                marginTop: "20px",
              }}
            >
             Login
            </Link>
          </Box>
        </form>
  </Grid>

</Grid>
      
      </div>
    </ThemeProvider>
  )
}

export default Forgotpass
