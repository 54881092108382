import React, { useState, useEffect } from 'react';
import { styled, alpha } from '@mui/material/styles';
import Paper from '@mui/material/Paper';
import {
    Typography,
    Grid,
    Box,
    Button,
    Toolbar,
    IconButton,
    Stack,
    FormControl,
    InputLabel,
    Menu,
    MenuItem,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Accordion,
    AccordionSummary,
    AccordionDetails,
    Pagination,
    Dialog,
    DialogContent,
    DialogActions,
    TextField,
    Select,
    DialogContentText,
    DialogTitle,

} from '@mui/material';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';
import CloseIcon from '@mui/icons-material/Close';
import AddIcon from '@mui/icons-material/Add';
import SearchIcon from '@mui/icons-material/Search';
import InputBase from '@mui/material/InputBase';
import Checkbox from '@mui/material/Checkbox';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { NavLink, useNavigate } from 'react-router-dom/dist';
import { UserApi } from '../service/axios';
import { toast } from 'react-toastify';
import { Helpers } from '../service';
import { display } from '@mui/system';
import Popover from '@mui/material/Popover';
import CloudDownloadIcon from '@mui/icons-material/CloudDownload';
import CircularProgress from '@mui/material/CircularProgress';




const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
    ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: 'center',
    color: theme.palette.text.secondary,
}));

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
    '& .MuiDialogContent-root': {
        padding: theme.spacing(2),
    },
    '& .MuiDialogActions-root': {
        padding: theme.spacing(1),
    },
}));

const Search = styled("div")(({ theme }) => ({
    position: "relative",
    display: "flex",
    borderRadius: theme.shape.borderRadius,
    backgroundColor: alpha(theme.palette.common.white, 0.15),
    "&:hover": {
        backgroundColor: alpha(theme.palette.common.white, 0.25)
    },
    marginRight: theme.spacing(2),
    marginLeft: 0,
    width: "100%",
    [theme.breakpoints.up("sm")]: {
        marginLeft: theme.spacing(3),
        width: "auto"
    }
}));

const SearchIconWrapper = styled("div")(({ theme }) => ({
    padding: theme.spacing(0, 2),
    height: "100%",
    position: "absolute",
    pointerEvents: "none",
    display: "flex",
    alignItems: "center",
    justifyContent: "center"
}));

const StyledInputBase = styled(InputBase)(({ theme }) => ({
    color: "inherit",
    "& .MuiInputBase-input": {
        padding: theme.spacing(1, 1, 1, 0),
        paddingLeft: `calc(1em + ${theme.spacing(4)})`,
        transition: theme.transitions.create("width"),
        width: "100%",
        [theme.breakpoints.up("md")]: {
            "width": "32ch",
            "border": " 1px solid #E9E9E9",
            "border-radius": "6px",
            "font-size": "14px",
        },
        [theme.breakpoints.up("sm")]: {
            "border": " 1px solid #E9E9E9",
            "border-radius": "6px",
            "font-size": "14px",
            "marginTop": "9px",
            backgroundColor: "#fff",
            "height": "2em",

        },
        [theme.breakpoints.up("xs")]: {
            "border": " 1px solid #E9E9E9",
            "border-radius": "6px",
            "font-size": "14px",
            "marginTop": "9px",
            backgroundColor: "#fff",
            "height": "2em",

        }
    }
}));
const label = { inputProps: { 'aria-label': 'Checkbox demo' } };
export default function ContactUs() {
    const [auth, setAuth] = React.useState(true);
    const [anchorEl, setAnchorEl] = React.useState(null);
    const [errors, setErrors] = useState({});
    const [valid, setValid] = useState(true);
    const [contactData, setContactData] = useState([]);
    const [selected, setSelected] = useState([]);
    const [selectedContact, setSelectedContact] = useState(null);
    const navigate = useNavigate();
    const [searchTerm, setSearchTerm] = useState('');
    const [groupData, setGroupData] = useState([]);
    const [selectedGroup, setSelectedGroup] = useState('');
    const [openPopup, setOpenPopup] = useState(false);
    const [popupTitle, setPopupTitle] = useState('');
    const [popupType, setPopupType] = useState('add');
    const [inputValue, setInputValue] = useState('');
    const [error, setError] = useState('');
    const [loading, setLoading] = useState(true);
    const initialFormData = {
        name: '',
        mobile_no: '',
        group_id: '',
    };


    const [dummyCsvData, setDummyCsvData] = useState('');

    const generateDummyCsv = () => {
        const columns = ['name', 'mobile_no'];
        const dummyRows = [
            ['John carter', '123-456-7890'],
            ['John Cena', '123-456-4940'],
        ];

        const csvContent = [columns.join(','), ...dummyRows.map(row => row.map(value => `"${value}"`).join(','))].join('\n');
        setDummyCsvData(csvContent);
    };

    const handleDownloadClick = () => {
        generateDummyCsv();

        const blob = new Blob([dummyCsvData], { type: 'text/csv' });
        const url = URL.createObjectURL(blob);

        const a = document.createElement('a');
        a.href = url;
        a.download = 'dummy_data.csv';
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
    };

    const uploadContact = async (csvFile) => {
        try {
            const formData = new FormData();

            formData.append('csv_file', csvFile);
            formData.append('group_id', selectedGroup);
            const response = await UserApi.uploadContact(formData);
            if (response.status === 200) {
            }
        } catch (error) {
            console.error('Error updating contact:', error);
            throw error;
        };
    }

    const handleSearchChange = (event) => {
        const searchTerm = event.target.value.toLowerCase();

        if (searchTerm === '') {
            getContact();
        } else {
            const filteredData = contactData.map((group) => ({
                ...group,
                contacts: group.contacts.filter(
                    (contact) =>
                        contact.name.toLowerCase().includes(searchTerm) ||
                        contact.mobile_no.toLowerCase().includes(searchTerm)
                ),
            }));

            setContactData(filteredData);
        }

        setSearchTerm(searchTerm);
    };


    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
        setInputValue('');
        setError('');
    };

    const handleMenu = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const [page, setPage] = React.useState(1);
    const pagehandleChange = (event, value) => {
        setPage(value);
    };

    const data = {
        group_name: inputValue.trim(),
        status: 'active'
    };
    const handleSave = async () => {
        if (!inputValue.trim()) {
            setError('Please enter a valid value.');
            return;
        }
        try {

            const response = await UserApi.createOrEditGroup(data);

            if (response.status === 200) {
                Helpers.toastSuccess(
                    `Group ${popupType === 'add' ? 'added' : 'updated'} successfully`
                );
                handleClose();

            } else {
                console.error('Error:', response.data.error_msg);
                handleClose();
            }
        } catch (error) {
            console.error('Error updating contact:', error);
            throw error;
        }
    };
    const handleChange = (e) => {
        setInputValue(e.target.value);
        setError('');
    };

    const [open, setOpen] = React.useState(false);

    const handleClickOpen = () => {
        setOpenPopup(true);
        setPopupTitle('Add Contact');
        setPopupType('add');
        setFormData({ ...initialFormData, group_id: 'defaultGroupId' });
    };
    const FormhandleClose = () => {
        setOpenPopup(false);
    };



    const [formData, setFormData] = useState({
        name: '',
        mobile_no: ''
    });

    const handleSubmit = async (e) => {
        e.preventDefault();
        let isValid = true;
        let validationError = {};

        if (formData.name === '' || formData.name === null) {
            isValid = false;
            validationError.name = 'Name required';
        }
        if (formData.mobile_no === '' || formData.mobile_no === null) {
            isValid = false;
            validationError.mobile_no = 'Mobile Number required';
        }
        setErrors(validationError);
        setValid(isValid);

        if (isValid) {
            try {
                const response =
                    popupType === 'add'
                        ? await addContact({
                            name: formData.name,
                            mobile_no: formData.mobile_no,
                            group_id: selectedGroup,
                        })
                        : await updateContact(selectedContact.id, {
                            name: formData.name,
                            mobile_no: formData.mobile_no,
                            group_id: selectedGroup,
                        });

                if (response.status === 200) {
                    Helpers.toastSuccess(
                        `Contact ${popupType === 'add' ? 'added' : 'updated'} successfully`
                    );
                    FormhandleClose();

                    getContact();

                } else {
                    console.error('Error:', response.data.error_msg);
                }
            } catch (error) {

                console.error('Error:', error.message);
            }
        }
    };


    const updateContact = async (contactId, data) => {
        try {
            const response = await UserApi.Updatecontact(`${contactId}`, data);
            return response;
        } catch (error) {
            console.error('Error updating contact:', error);
            throw error;
        }
    };

    const addContact = async (data) => {
        try {
            const response = await UserApi.addcontact(data);
            return response;
        } catch (error) {
            console.error('Error updating contact:', error);
            throw error;
        }
    };

    const getContact = () => {
        setLoading(true);
        const formData = new FormData();
        UserApi.getGroup().then(
            (resp) => {
                if (resp.status === 200) {
                    setContactData(resp.data.group);
                } else {
                }
            }
        ).catch((error) => {
            Helpers.error401(error);
        }).finally(() => {
            setLoading(false);
        });
    };
    const handleCheckboxChange = (id) => {
        const selectedIndex = selected.indexOf(id);
        let newSelected = [];

        if (selectedIndex === -1) {
            newSelected = newSelected.concat(selected, id);
        } else if (selectedIndex === 0) {
            newSelected = newSelected.concat(selected.slice(1));
        } else if (selectedIndex === selected.length - 1) {
            newSelected = newSelected.concat(selected.slice(0, -1));
        } else if (selectedIndex > 0) {
            newSelected = newSelected.concat(
                selected.slice(0, selectedIndex),
                selected.slice(selectedIndex + 1)
            );
        }

        setSelected(newSelected);
    };

    const [groupdropdown, setGroupdropdown] = useState(null);
    const btnhandleClick = (event) => {
        setGroupdropdown(event.currentTarget);
    };

    const btnhandleClose = () => {
        setGroupdropdown(null);

    };
    const handleFileUpload = (event) => {
        const file = event.target.files[0];
    };

    const handleEditContact = (contact) => {
        setSelectedContact(contact);
        setFormData({
            name: contact.name,
            mobile_no: contact.mobile_no,
            group_id: contact.group_id,
        });
        setSelectedGroup(contact.group_id);
        setPopupTitle('Edit Contact');
        setPopupType('edit');
        setOpenPopup(true);
    };


    const handleDeleteContact = async (contactId) => {
        try {
            const response = await UserApi.deleteContact(contactId);

            if (response.status === 200) {
                Helpers.toastSuccess('Contact deleted successfully');
                getContact();
            } else {
                console.error('Error deleting contact:', response.data.error_msg);
            }
        } catch (error) {
            console.error('Error:', error.message);
        }
    };

    const getGroup = () => {
        UserApi.getGroup()
            .then((resp) => {
                if (resp.status === 200) {
                    setGroupData(resp.data.group);
                } else {
                }
            })
            .catch((error) => {
                console.error('Error fetching groups:', error);
                Helpers.error401(error);
            });
    };


    useEffect(() => {
        getContact();
        getGroup();
    }, []);


    const [opendailog, setOpenDialog] = React.useState(false);
    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down('md'));

    const handleClickOpenDialog = () => {
        setOpenDialog(true);
    };

    const handleCloseDialog = () => {
        setOpenDialog(false);
    };

    const [selectedFile, setSelectedFile] = useState(null);
    const [values, setValues] = useState('');
    const handleFileChange = (event) => {
        const file = event.target.files[0];
        setSelectedFile(file);
    };

    const handleUpload = async () => {
        try {
            if (!selectedFile) {
                return;
            }
            await uploadContact(selectedFile);
            handleCloseDialog();
            setSelectedFile(null);
            const fileInput = document.getElementById('myfile');
            if (fileInput) {
                fileInput.value = '';
            }
        } catch (error) {
            console.error('Error uploading contact:', error);
        }
    }

    const isFileSelected = !!selectedFile && !!selectedGroup;


    const handleChangeValues = (event) => {
        setValues(event.target.value);
    };

    return (
        <>
            {loading ? (
                <div style={{ textAlign: 'center', paddingTop: '150px' }}>
                    <CircularProgress />
                </div>
            ) : (
                <div style={{
                    "background": "#fff",
                    "margin": "24px 0px 0px",
                    "border-radius": "9px",
                    "box-shadow": "0px 3px 1px 0px #EBEBEB",
                    "padding": "24px",
                }}>
                    <Grid container spacing={2} columns={12}>
                        <Grid Item xs={4}>
                            <Item style={{ "box-shadow": "none", background: "transparent", "margin-top": "9px", "display": "flex", "gap": "10px", }}>
                                <Box aria-label="Example" style={{
                                    "width": "fit-content",
                                    "background": "#FFF8E0",
                                    "border-radius": "7px",
                                    "padding": "7px 8px",
                                    "justify-content": "center",
                                    "display": "flex",
                                }}>
                                    <img src='/setting.png' />
                                </Box>
                                <h4 className='Templateheading2'>Contact Us</h4>
                            </Item>
                        </Grid>
                        <Grid xs={8}>
                            <Item style={{ "box-shadow": "none", background: "transparent", "float": "inline-end" }}>
                                <Grid container spacing={2} columns={9}>
                                    <Grid Item xs={9}>
                                        <Item className='inneritems'>
                                            <Stack direction="row" spacing={2}>
                                                <div className={Helpers.cmAccess('CONTACTS_FILTER')}>
                                                    <Toolbar style={{ "width": "min-content", "padding": "0px" }}>
                                                        {auth && (
                                                            <div>
                                                                <IconButton
                                                                    size="large"
                                                                    aria-label="Filter"
                                                                    aria-controls="menu-appbar"
                                                                    aria-haspopup="true"
                                                                    color="inherit"
                                                                    className='Filterbtn'
                                                                > <img src='/filter.png' />
                                                                </IconButton>
                                                            </div>
                                                        )}
                                                    </Toolbar>
                                                </div>
                                                <div className={Helpers.cmAccess('CONTACTS_SEARCH')}>
                                                    <Search>
                                                        <SearchIconWrapper style={{ color: "#757575", "zIndex": "99", "top": "4px", }}>
                                                            <SearchIcon style={{ fontSize: 16, }} />
                                                        </SearchIconWrapper>
                                                        <StyledInputBase
                                                            placeholder="Search…"
                                                            inputProps={{ "aria-label": "search" }}
                                                            value={searchTerm}
                                                            onChange={handleSearchChange}
                                                        />
                                                    </Search>
                                                </div>
                                            </Stack>
                                        </Item>
                                    </Grid>
                                </Grid>
                            </Item>
                        </Grid>
                    </Grid>
                    <Box sx={{ width: '100%' }}>
                        <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
                            <Grid item xs={12}>
                                <Item className='inneritems'>
                                    <Stack direction="row" spacing={2}>
                                        <label className={Helpers.cmAccess('CONTACTS_IMPORT_EXPORT')}>
                                            <Button component="span" variant="outlined" className='contactBtn' onClick={handleClickOpenDialog}>
                                                Import & Export
                                            </Button>
                                        </label>
                                        <Dialog
                                            fullScreen={fullScreen}
                                            open={opendailog}
                                            onClose={handleCloseDialog}
                                            aria-labelledby="responsive-dialog-title"
                                        >
                                            <DialogTitle id="responsive-dialog-title">
                                                Import & Export
                                            </DialogTitle>
                                            <DialogContent>
                                                <DialogContentText>
                                                    <label htmlFor="csv-upload">
                                                        <input
                                                            type="file"
                                                            id="myfile"
                                                            name="myfile"
                                                            multiple
                                                            className='importExportBtn'
                                                            onChange={handleFileChange}
                                                        />
                                                    </label><br />
                                                    <Box sx={{ minWidth: 120, mt: 2 }}>
                                                        <FormControl fullWidth>
                                                            <InputLabel id="demo-simple-select-label" htmlFor="group-select">Select Group</InputLabel>
                                                            <Select
                                                                label="Select Group"
                                                                value={selectedGroup}
                                                                onChange={(e) => setSelectedGroup(e.target.value)}
                                                                fullWidth
                                                                margin="normal"
                                                                required
                                                            >
                                                                {groupData.map((group) => (
                                                                    <MenuItem key={group.id} value={group.id}>
                                                                        {group.group_name}
                                                                    </MenuItem>
                                                                ))}
                                                            </Select>
                                                        </FormControl>
                                                    </Box>
                                                    <Typography variant='p' sx={{ marginTop: "10px", display: "block" }}>
                                                    </Typography>
                                                </DialogContentText>
                                            </DialogContent>
                                            <DialogActions>
                                                <Button className='contactBtn2' onClick={handleCloseDialog} autoFocus>
                                                    Cancel
                                                </Button>
                                                <Button className='submitbtn' autoFocus onClick={handleUpload}
                                                    disabled={!isFileSelected}
                                                    style={{
                                                        background: isFileSelected ? " #ff6600" : "#f1f1f199",
                                                        color: isFileSelected ? "#fff" : "#dbdbdb",
                                                        padding: "6px 20px",
                                                        cursor: isFileSelected ? "pointer" : "not-allowed",
                                                    }}
                                                >
                                                    Submit
                                                </Button>
                                            </DialogActions>
                                        </Dialog>
                                        <input
                                            type="file"
                                            accept=".csv"
                                            id="csv-upload"
                                            style={{ display: 'none' }}
                                            onChange={handleFileUpload}
                                        />
                                        <div className={Helpers.cmAccess('CONTACTS_ADD_GROUP')}>
                                            <Button
                                                aria-controls="simple-menu"
                                                aria-haspopup="true"
                                                onClick={handleClick}
                                                variant="contained"
                                                className={`contactBtn2`}
                                            >
                                                Add Group
                                            </Button>

                                            <Popover
                                                open={Boolean(anchorEl)}
                                                anchorEl={anchorEl}
                                                onClose={handleClose}
                                                anchorOrigin={{
                                                    vertical: 'bottom',
                                                    horizontal: 'left',
                                                }}
                                                transformOrigin={{
                                                    vertical: 'top',
                                                    horizontal: 'left',
                                                }}
                                            >
                                                <Box p={2}>
                                                    <TextField
                                                        label="Group Name"
                                                        variant="outlined"
                                                        fullWidth
                                                        value={inputValue}
                                                        onChange={handleChange}
                                                        error={Boolean(error)}
                                                        helperText={error}
                                                    />
                                                    <Box mt={2} display="flex" justifyContent="space-between">
                                                        <Button variant="contained" color="primary" onClick={handleSave}>
                                                            Save
                                                        </Button>
                                                        <Button variant="contained" color="secondary" onClick={handleClose}>
                                                            Cancel
                                                        </Button>
                                                    </Box>
                                                </Box>
                                            </Popover>
                                        </div>
                                        <div className={Helpers.cmAccess('CONTACTS_ADD_NEW')}>
                                            <Button
                                                variant="outlined"
                                                onClick={handleClickOpen}
                                                className={`contactBtn`}
                                                startIcon={<AddIcon />}
                                            >
                                                Add New
                                            </Button>
                                        </div>
                                        <BootstrapDialog
                                            onClose={FormhandleClose}
                                            aria-labelledby="customized-dialog-title"
                                            open={openPopup}
                                        >
                                            <form onSubmit={handleSubmit}>
                                                <DialogTitle sx={{ m: 0, p: 2 }} id="customized-dialog-title">
                                                    {popupTitle}
                                                </DialogTitle>
                                                <IconButton
                                                    aria-label="close"
                                                    onClick={FormhandleClose}
                                                    sx={{
                                                        position: 'absolute',
                                                        right: 8,
                                                        top: 8,
                                                        color: (theme) => theme.palette.grey[500],
                                                    }}
                                                >
                                                    <CloseIcon />
                                                </IconButton>
                                                <DialogContent dividers>
                                                    <FormControl fullWidth>
                                                        <InputLabel htmlFor="group-select">Select Group</InputLabel>
                                                        <Select
                                                            label="Select Group"
                                                            value={selectedGroup}
                                                            onChange={(e) => setSelectedGroup(e.target.value)}
                                                            fullWidth
                                                            margin="normal"
                                                            required
                                                        >
                                                            {groupData.map((group) => (
                                                                <MenuItem key={group.id} value={group.id}>
                                                                    {group.group_name}
                                                                </MenuItem>
                                                            ))}
                                                        </Select>
                                                    </FormControl>
                                                    <TextField
                                                        label="Name"
                                                        name="name"
                                                        value={formData.name}
                                                        onChange={(e) => setFormData({ ...formData, name: e.target.value })}
                                                        fullWidth
                                                        margin="normal"
                                                        required
                                                        error={Boolean(errors.name)}
                                                        helperText={errors.name}
                                                    />
                                                    <TextField
                                                        label="Mobile Number"
                                                        name="mobile_no"
                                                        value={formData.mobile_no}
                                                        onChange={(e) => setFormData({ ...formData, mobile_no: e.target.value })}
                                                        fullWidth
                                                        margin="normal"
                                                        required
                                                        error={Boolean(errors.mobile_no)}
                                                        helperText={errors.mobile_no}
                                                    />
                                                </DialogContent>
                                                <DialogActions>
                                                    <Button onClick={FormhandleClose} color="primary" className="CancelBtn">
                                                        Cancel
                                                    </Button>
                                                    <Button type="submit" color="primary" className="SaveBtn">
                                                        Save
                                                    </Button>
                                                </DialogActions>
                                            </form>
                                        </BootstrapDialog>
                                        <div className={Helpers.cmAccess('CONTACTS_DOWNLOAD_SAMPLE_CSV')}>
                                            <Button
                                                variant="outlined"
                                                onClick={handleDownloadClick}
                                                className={`contactBtn`}
                                                startIcon={<CloudDownloadIcon />}
                                            >
                                                Download Sample Csv
                                            </Button>
                                        </div>
                                    </Stack>
                                </Item>
                            </Grid>
                        </Grid>
                    </Box>
                    <Box sx={{ width: '100%' }}>
                        <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
                            <Grid item xs={12}>
                                <Item className='inneritems'>
                                    <TableContainer className={Helpers.cmAccess('CONTACTS_TABLE')}>
                                        <Table className='ContactTable' sx={{ minWidth: "100%" }} aria-label="simple table" >
                                            <TableHead>
                                                <TableRow>
                                                    <TableCell>ID</TableCell>
                                                    <TableCell>Name</TableCell>
                                                    <TableCell>Phone Number</TableCell>
                                                    <TableCell>Group Name</TableCell>
                                                    <TableCell>Created</TableCell>
                                                    <TableCell>Last Modified</TableCell>
                                                    <TableCell>Action</TableCell>
                                                </TableRow>
                                            </TableHead>
                                            <TableBody>
                                                {contactData &&
                                                    contactData.map((group) => (
                                                        <React.Fragment key={group.id}>
                                                            {group.contacts &&
                                                                group.contacts.map((contact, index) => (
                                                                    <TableRow key={contact.id}>
                                                                        <TableCell>{index + 1}</TableCell>
                                                                        <TableCell>
                                                                            <NavLink to={`/dashboard/contactus/userdashboard`} style={{ "background-color": "transparent", "text-decoration": "none", "color": "#0889FF" }} replace={true} state={contact.id} className={Helpers.cmAccess('CONTACTS_VIEW')}>
                                                                                {contact.name}
                                                                            </NavLink>
                                                                        </TableCell>
                                                                        <TableCell>{contact.mobile_no}</TableCell>
                                                                        <TableCell>{group.group_name}</TableCell>
                                                                        <TableCell>{new Date(contact.created_at).toLocaleDateString('en-GB')}</TableCell>
                                                                        <TableCell>{new Date(contact.updated_at).toLocaleDateString('en-GB')}</TableCell>
                                                                        <TableCell>
                                                                            <img
                                                                                src="/edit.png"
                                                                                alt=""
                                                                                width={16}
                                                                                style={{ "cursor": "pointer" }}
                                                                                className={Helpers.cmAccess('CONTACTS_EDIT')}
                                                                                onClick={() => handleEditContact(contact)}
                                                                            />
                                                                            <img
                                                                                src="/delete.png"
                                                                                alt=""
                                                                                width={16}
                                                                                className={Helpers.cmAccess('CONTACTS_DELETE')}
                                                                                style={{ "margin": "0 9px 0px 6px", "cursor": "pointer" }}
                                                                                onClick={() => handleDeleteContact(contact.id)}
                                                                            />
                                                                        </TableCell>
                                                                    </TableRow>
                                                                ))}
                                                        </React.Fragment>
                                                    ))}
                                            </TableBody>
                                        </Table>
                                    </TableContainer>
                                </Item>
                            </Grid>
                        </Grid>
                    </Box>
                </div>
            )}
        </>
    )
}