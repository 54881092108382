import React from 'react';
import { useNavigate } from 'react-router-dom';
import { useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import { Typography, Stack, Button, CircularProgress} from '@mui/material';
import { Helpers } from '../service';
import { UserApi } from '../service/axios';
import AddIcon from '@mui/icons-material/Add';
import { NavLink } from 'react-router-dom';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper } from '@mui/material';

const SimpleTable = () => {
  const [roleData, setRoleData] = useState([]);
  const [userData, setUserData] = useState([]);
  const [roleid, setRoleId] = useState('');
  const [user, setUserId] = useState('');
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  
  useEffect(() => {
    getUsers();
  }, []);

  const getUsers = () => {
    UserApi.getUsers().then(
      (resp) => {
        if (resp.status === 200) {
          setUserData(resp.data.data.users);
        } else {
          console.log("error", resp.data.error_msg);
          alert(resp.data.error_msg);
        }
      }
    ).catch((error) => {
      Helpers.error401(error);
    })
    .finally(() => {
      setLoading(false); 
    });
  };

  const getUserDeatail = (id) => {
    navigate('/dashboard/admin/RegisterForm', { replace: true, state: id });
  };

  return (
    <>
    {loading ? ( // Render CircularProgress while loading
        <Box display="flex" justifyContent="center" alignItems="center" height="300px">
          <CircularProgress />
        </Box>
      ) : (
    <TableContainer component={Paper} className={Helpers.cmAccess('ADMIN_USER_LIST_TABLE')}>
      <NavLink to="/dashboard/admin/RegisterForm" style={{ textDecoration: "none", alignContent:'flex-end'}} className={Helpers.cmAccess('ADMIN_USER_ADDNEW')}>
          <Button variant="outlined" className='contactBtn' startIcon={<AddIcon />} style={{ "height": "38px", }}>
            Add New
          </Button>
      </NavLink>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>Name</TableCell>
            <TableCell>Mobile</TableCell>
            <TableCell>Email</TableCell>
            <TableCell>Action</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {userData.map((user) => (
            <TableRow key={user.id}>
              <TableCell>{user.first_name} {user.last_name}</TableCell>
              <TableCell>{user.mobile_no}</TableCell>
              <TableCell>{user.email}</TableCell>
              <div className={Helpers.cmAccess('ADMIN_USER_EDIT')}>
              <TableCell style={{cursor:"pointer"}} onClick={() => getUserDeatail(user.id)}>edit</TableCell>
              </div>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
      )}
    </>
  );
};

export default SimpleTable;
