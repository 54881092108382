import { Box, Button, Card, CardActions, CardContent, CardMedia, Divider, FormControlLabel, Grid, Input, Menu, MenuItem, Radio, RadioGroup, Stack, Typography, Checkbox, List, ListItem, IconButton } from '@mui/material';
import Paper from '@mui/material/Paper';
import { styled } from '@mui/material/styles';
import React, { useEffect, useState } from 'react';
import TextField from '@mui/material/TextField';
import PropTypes from 'prop-types';
import { AuthApi } from '../service';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import ReportProblemIcon from '@mui/icons-material/ReportProblem';
import DeleteIcon from '@mui/icons-material/Delete';
import { debounce } from 'lodash';
import { UserApi } from '../service/axios';




const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
    ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: 'center',
    color: theme.palette.text.secondary,
}));


const label = { inputProps: { 'aria-label': 'Checkbox demo' } };
export default function MassageTemplateForm() {

    const [bodyContent, setBodyContent] = useState('');
    const [footerContent, setFooterContent] = useState('');
    


    const handleBodyContentChange = (event) => {
        setBodyContent(event.target.value);
    };

    const handleFooterContentChange = (event) => {
        setFooterContent(event.target.value);
    };

    const [selectedCategory, setSelectedCategory] = useState({
        header_text: '',
        header_type: '',
        media: '',
    });

    const handleCategoryChange = (event) => {
        const { name, value } = event.target;
        setSelectedCategory((prev) => {
            return { ...prev, [name]: value };
        });
    };


    const [data, setData] =useState ({
        category: '',
        name: '',
        language_code: '',
        body_text: '',
        footer_text: '',
        example_body_text: '',
        button: [{}]
    });


    const handlequickReply = (event) => {
        const { name, value } = event.target;
      };

    var count=0;

    const handlebuttonDataChange = (event, index,item) => {
        if (item==='QUICK_REPLY') {
            index=index+selectedItems2.length;
        }else{
            index=index+selectedItems.length;
        }
        const { name, value } = event.target;
        setData((prev) => {
            const newData = { ...prev };
            const updatedButton = {
                ...newData.button[index],
                [name]: value,
            };
            if (name === 'button_text' && !updatedButton.button_type && !updatedButton.button_action && item==='QUICK_REPLY') {
                updatedButton.button_type = 'QUICK_REPLY';
                updatedButton.button_action = 'Custom';
            }
            if (name === 'button_text' && !updatedButton.button_type && item==='URL') {
                updatedButton.button_type = 'URL';
            }
            if (name === 'button_text' && !updatedButton.button_type && item==='PHONE_NUMBER') {
                updatedButton.button_type = 'PHONE_NUMBER';
            }
            if (name === 'button_text' && !updatedButton.button_type && !updatedButton.button_action && item==='COPY_CODE') {
                updatedButton.button_type = 'COPY_CODE';
            }
            newData.button[index] = updatedButton;
            return newData;
        });
    };

    const handleDataChange = (event) => {
        const { name, value } = event.target;
        setData((prev) => {
            const [firstPart, secondPart] = name.split('.');
        
            if (secondPart) {
              return {
                ...prev,
                [firstPart]: {
                  ...prev[firstPart],
                  [secondPart]: value,
                },
              };
            }
        
            return {
              ...prev,
              [name]: value,
            };
          });
      };

      const handleSubmit = async (e) => {
        e.preventDefault();
        if(selectedMediaOption ==='Location'){
            selectedCategory.header_type="LOCATION"
        }
        var count=0;
        if (inputFields && inputFields.length > 0) {        
            for (const field of inputFields) {
                if(count==0){
                    data.example_body_text=field;
                    count++;
                }else{
                    data.example_body_text+=",";
                    data.example_body_text+=field;
                    count++;
                }
            }
        }else{
            delete data.example_body_text;
        }
        if (!data.button || !data.button[0] || !data.button[0].button_text) {
            delete data.button;
          }else{
            data.button = JSON.stringify(data.button, null, 2);
          }
        const combinedData = {
          ...data,
          ...selectedCategory,
        };
      
        try {
          const response = await UserApi.template(combinedData);
        } catch (error) {
          console.error('Error submitting data:', error);
        }
      };

    const [selectedHeaderType, setSelectedHeaderType] = useState('');
    const [selectedMediaOption, setSelectedMediaOption] = useState('');

    const handleHeaderTypeChange = (event) => {
        setSelectedHeaderType(event.target.value);
    };

    const handleMediaOptionChange = (event) => {
        setSelectedMediaOption(event.target.value);
        
    };
    // image upload
    const [selectedImage, setSelectedImage] = useState(null);
    const [inputKey, setInputKey] = useState(0);

    const handleImageChange = (event) => {
        const file = event.target.files[0];
        if (file && (file.type === 'image/jpeg' || file.type === 'image/png')) {
            setSelectedImage(file);
            selectedCategory.media=file;
            selectedCategory.header_type="IMAGE"
        } else {
            alert('Please select a valid jpg or png image.');
        }
    };
    const handleRemoveImage = () => {
        setSelectedImage(null);
        setInputKey((prevKey) => prevKey + 1);
    };
    // Video upload
    const [selectedVideo, setSelectedVideo] = useState(null);
    const [inputKey2, setInputKey2] = useState(0);

    const handleRemoveVideo = () => {
        setSelectedVideo(null);
        setInputKey2((prevKey) => prevKey + 1);
    };
    const handleVideoChange = (event) => {
        const file = event.target.files[0];

        if (file) {
            try {
                const videoUrl = URL.createObjectURL(file);
                setSelectedVideo(videoUrl);
                selectedCategory.media=file;
                selectedCategory.header_type="VIDEO"
            } catch (error) {
                console.error('Error creating object URL:', error);
            }
        }
    };
    // Document upload
    const [selectedDocument, setSelectedDocument] = useState(null);
    const [inputdocumentKey, setInputdocumentKey] = useState(0);

    const handleRemoveDocument = () => {
        setSelectedDocument(null);
        setInputdocumentKey((prevKey) => prevKey + 1);
    };
    const handleFileChange = (event) => {
        const selectedFile = event.target.files[0];

        if (selectedFile && selectedFile.type === 'application/pdf') {
            selectedCategory.media=selectedFile;
            selectedCategory.header_type="DOCUMENT"
            setSelectedDocument(selectedFile);
        } else {
            alert('Please select a PDF file.');
        }
    };
    // Input Field
    const [inputFields, setInputFields] = useState([]);

    const addInputField = () => {
        setInputFields([...inputFields, '']);
    };

    const handleInputChange = (index, value) => {
        const newInputFields = [...inputFields];
        newInputFields[index] = value;
        setInputFields(newInputFields);
    };

    const convertJSONToFormData = (selectedCategory) => {
        const newFormData = new FormData();

        for (const key in selectedCategory) {
            if (selectedCategory.hasOwnProperty(key)) {
                const value = selectedCategory[key];

                if (typeof value === 'object' && value !== null) {
                    for (const nestedKey in value) {
                        if (value.hasOwnProperty(nestedKey)) {
                            const nestedValue = value[nestedKey];
                            newFormData.append(`${key}_${nestedKey}`, nestedValue);
                        }
                    }
                } else {
                    newFormData.append(key, value);
                }
            }
        }

        return newFormData;
    };

    const [formBlocks, setFormBlocks] = useState([
        { button_type: "", button_text: "", button_action: "", },
    ]);

    const handleAddBlock = () => {
        setFormBlocks([
            ...formBlocks,
            { button_type: "", button_text: "", button_action: "", },
        ]);
    };

    const handleRemoveBlock = (index) => {
        const updatedBlocks = [...formBlocks];
        updatedBlocks.splice(index, 1);
        setFormBlocks(updatedBlocks);
    };

    const handleFieldChange = (index, fieldName, value) => {
        const updatedBlocks = [...formBlocks];
        updatedBlocks[index][fieldName] = value;
        setFormBlocks(updatedBlocks);
    };

    const [anchorEl, setAnchorEl] = useState(null);
    const [selectedItems, setSelectedItems] = useState([]);
    const [selectedItems2, setSelectedItems2] = useState([]);
    const [isButtontypeBoxCreated, setButtontypeBoxCreated] = useState(false);
    const [isBoxCreated, setBoxCreated] = useState(false);

    const [clickCount, setClickCount] = useState(0);

    const [disabledItems, setDisabledItems] = useState([]);

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleMenuItemClick = (item) => {
        if (!isButtontypeBoxCreated) {
            setButtontypeBoxCreated(true);
        }
        if (!selectedItems.includes(item)) {
            setSelectedItems((prevItems) => [...prevItems, item]);
        }
        setDisabledItems([...disabledItems, item]);


        handleClose();
    };

    const handleMenuItem2Click = (item) => {
        if (!isBoxCreated) {
            setBoxCreated(true);
        }

        if (!selectedItems2.includes(item)) {
            setSelectedItems2((prevItems) => [...prevItems, item]);
        }
        setDisabledItems([...disabledItems, item]);


        handleClose();
    };
    const handleDeleteButtonClick = (item, type, index) => {
        if (type === 'Buttontype') {
            setSelectedItems((prevItems) => prevItems.filter((selectedItem, i) => i !== index));
            if (selectedItems.length === 1) {
                setButtontypeBoxCreated(false);
            }
        } else {
            setSelectedItems2((prevItems) => prevItems.filter((selectedItem, i) => i !== index));
            if (selectedItems2.length === 1) {
                setBoxCreated(false);
            }
        }

        if (item === 'URL') {
            setIsBoxDeleted((prevIsBoxDeleted) => {
                const remainingItemsCount = Math.max(selectedItems2.length - 1, 0);

                setClickCount(remainingItemsCount);

                return true;
            });
        }
        setDisabledItems((prevDisabledItems) =>
            prevDisabledItems.filter((disabledItem) => disabledItem !== item)
        );
    };
    const [isBoxDeleted, setIsBoxDeleted] = useState(false);
    const getItem2Content = (item,index) => {
        return (
            <>
                <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 1 }} columns={16}>
                    <Grid item xs={4}>
                        <Item className='inneritems' sx={{ "textAlign": "left" }}>
                            <label htmlFor="Type-select" style={{ "fontWeight": "600", color: "#000" }}>Type</label><br />
                            <input id="tac" className='inputfield' type="text" name={`button_type`} placeholder='Custom'
                           value="QUICK_REPLY"
                            onChange={(event) => handlebuttonDataChange(event, index,item)}
                                
                            />
                        </Item>
                    </Grid>
                    <Grid item xs={8}>
                        <Item className='inneritems' sx={{ "textAlign": "left" }}>
                            <label htmlFor="Category-select" style={{ "fontWeight": "600", color: "#000" }}>Button Text</label><br />
                            <input id="tac" className='inputfield' type="text" name={`button_text`} placeholder='Button Text'
                             value={item.button_text}
                             onChange={(event) => handlebuttonDataChange(event, index,item)}
                            />
                            <label className='inputlenght' style={{ "width": "100%", "display": "block", }}>12/25</label>
                        </Item>
                    </Grid>
                    <Grid item xs={4}>
                        <Item className='inneritems' sx={{ "textAlign": "left" }}>
                            <label htmlFor="Type-select" style={{ "fontWeight": "600", color: "#000" }}>Type of Action</label><br />
                            <input id="tac" className='inputfield' type="text" name={`button_action`} placeholder='Button Action'
                           value="Custom"
                            onChange={(event) => handlebuttonDataChange(event, index,item)}                                
                            />
                        </Item>
                    </Grid>
                </Grid>
            </>
        );
    };
    const getItem3Content = (item,index) => {
        return (
            <>
                <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 1 }} columns={16}>
                    <Grid item xs={4}>
                        <Item className='inneritems' sx={{ "textAlign": "left" }}>
                            <label for="Type-select" style={{ "fontWeight": "600", color: "#000" }}>Type of action</label><br />
                            <input id="tac" className='inputfield' type="text" name="button_type" placeholder='Visit Website' maxLength='25'
                             onChange={(event) => handlebuttonDataChange(event, index,item)}
                            />
                        </Item>
                    </Grid>
                    <Grid item xs={4}>
                        <Item className='inneritems' sx={{ "textAlign": "left" }}>
                            <label for="Category-select" style={{ "fontWeight": "600", color: "#000" }}>Button Text</label><br />
                            <input id="tac" className='inputfield' type="text" name="button_text" placeholder='Button Text' maxLength='25'
                             value={item.button_text}
                             onChange={(event) => handlebuttonDataChange(event, index,item)}
                            />
                            <label className='inputlenght' style={{ "width": "100%", "display": "block", }}>12/25</label>
                        </Item>
                    </Grid>
                    <Grid item xs={3}>
                        <Item className='inneritems' sx={{ "textAlign": "left" }}>
                            <label for="Type-select" style={{ "fontWeight": "600", color: "#000" }}>URL Type</label><br />
                            <select className='selectBox' name="language" id="Type-select" style={{ "padding": "0px 0px 0px 6px", height: "30px", }}>
                                <option value="URL_TYPE">URL Type</option>
                            </select>
                        </Item>
                    </Grid>
                    <Grid item xs={5}>
                        <Item className='inneritems' sx={{ "textAlign": "left" }}>
                            <label for="Category-select" style={{ "fontWeight": "600", color: "#000" }}>Website URL</label><br />
                            <input id="tac" className='inputfield' type="text" name="button_action" placeholder='https://example.com' maxLength='60'
                             value={item.button_text}
                             onChange={(event) => handlebuttonDataChange(event, index,item)}
                            />
                            <label className='inputlenght' style={{ "width": "100%", "display": "block", }}>0/2000</label>
                        </Item>
                    </Grid>
                </Grid>
            </>
        );
    };
    const handlesecondMenuItemClick = (item) => {
        if (!isButtontypeBoxCreated) {
            setButtontypeBoxCreated(true);
        }
        setSelectedItems((prevItems) => [...prevItems, item]);
    }
    const handleVistiWebsiteClick = (item) => {
        if (!isBoxCreated) {
            setBoxCreated(true);
        }

        if (clickCount < 2) {
            setSelectedItems2((prevItems) => [...prevItems, item]);
            setClickCount((prevCount) => prevCount + 1);
        }
        handleClose();

    }
    // Submenu Click Function
    return (
        <>
            <div style={{
                "padding": "20px 20px 0",
                "borderRadius": "10px",
                "marginTop": "10px",
            }}>
                <form autoComplete="off" noValidate onSubmit={handleSubmit}>
                    {/* Add Message Section */}
                    <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
                        <Grid item xs={8}>
                            <Item className='griditems'>
                                <Box style={{ "padding": "10px 0px 0px 17px", }}>
                                    <h2 className='formheading'>Add Message Templates</h2>
                                </Box>
                                <div style={{ "padding": "16px 10px", }}>
                                    <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
                                        <Grid item xs={12}>
                                            <Item className='inneritems'>
                                                <Box
                                                    sx={{
                                                        maxWidth: '100%',
                                                        "textAlign": "left",
                                                    }}
                                                >
                                                    <label for="tac" className='inputlabel'>
                                                        Template Name<br />
                                                        <input id="tac" className='inputfield' type="text" name="name" placeholder='AdvancedMarketing'
                                                     onChange={handleDataChange}
                                                        />
                                                    </label>
                                                    <label className='errormsg'>Spaces and special characters are not allowed</label>

                                                </Box>
                                            </Item>
                                        </Grid>
                                    </Grid>
                                    <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
                                        <Grid item xs={6}>
                                            <Item className='inneritems'>
                                                <Box
                                                    sx={{
                                                        maxWidth: '100%',
                                                        "textAlign": "left",
                                                    }}
                                                >
                                                    <label for="Category-select">Category:</label><br />
                                                    <select className='selectBox' name="category" id="Category-select"
                                                 onChange={handleDataChange}
                                                    >
                                                        <option value="">Please choose Category</option>
                                                        <option value="MARKETING">Marketing</option>
                                                        {/* <option value="Marketing">Utility / Transctional</option> */}
                                                    </select>
                                                    <label className='errormsg'>Select Template category</label>
                                                </Box>
                                            </Item>
                                        </Grid>
                                        <Grid item xs={6}>
                                            <Item className='inneritems'>
                                                <Box
                                                    sx={{
                                                        maxWidth: '100%',
                                                        "textAlign": "left",
                                                    }}
                                                >
                                                    <label for="language-select">Language:</label><br />
                                                    <select className='selectBox' name="language_code" id="language-select"
                                                 onChange={handleDataChange}
                                                    >
                                                        <option value="">Please choose Language</option>
                                                        <option value="en">English </option>
                                                        <option value="en_UN">English (US)</option>
                                                        <option value="en_GB">English (UK)</option>
                                                    </select>
                                                    <label className='errormsg'>Select Template Language</label>
                                                </Box>

                                            </Item>
                                        </Grid>
                                    </Grid>
                                </div>
                            </Item>
                            {/* Header Section */}
                            <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }} mt={3}>
                                <Grid item xs={12}>
                                    <Item className='griditems'>
                                        <Box style={{ "padding": "10px 0px 0px 17px", }}>
                                            <h2 className='formheading'>Header</h2>
                                            <p style={{ "color": "#939393", "fontSize": "10px", "textAlign": "left", "margin-top": "2px", }}>Add a message header to show in message in top</p>
                                        </Box>
                                        <div style={{ "padding": "0 9px 10px", }}>
                                            <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
                                                <Grid item xs={12}>
                                                    <Item className='inneritems'>
                                                        <Box
                                                            sx={{
                                                                maxWidth: '100%',
                                                                "textAlign": "left",
                                                            }}
                                                        >
                                                            <label for="Category-select">Header Type</label><br />
                                                            <select
                                                                className='selectBox'
                                                                name="header_type"
                                                                id="Category-select"
                                                                style={{ "width": "50%" }}
                                                                value={selectedCategory.header_type}
                                                                onChange={handleCategoryChange}
                                                            // {...register("header_type")}
                                                            >
                                                                <option value="">None</option>
                                                                <option value="TEXT">Text</option>
                                                                <option value="Media">Media</option>
                                                            </select>
                                                            {/* Conditionally render the input box based on the selected category */}
                                                            {selectedCategory.header_type === 'TEXT' && (
                                                                <input id="tac" type="text" name="header_text" placeholder='Header Text' className='selectBox' style={{ "width": "48%", "marginLeft": "9px", }}
                                                                    // {...register("header_text")}
                                                                    value={selectedCategory.header_text}
                                                                    onChange={handleCategoryChange}
                                                                />
                                                            )}

                                                            {selectedCategory.header_type !== 'TEXT' && selectedCategory.header_type !== '' && (
                                                                <Grid container spacing={2} columns={12} mt={2}>
                                                                    <Grid item xs={6}>
                                                                        <Item className='inneritems' style={{ "text-align": "left", }}>
                                                                            <RadioGroup name="use-radio-group" value={selectedMediaOption} onChange={handleMediaOptionChange}>
                                                                                <Box style={{ "position": "relative" }}>
                                                                                    {selectedMediaOption === 'Image' ? (
                                                                                        <img src='/png.png' style={{ "margin": "19px 22px 0 22px", "position": "absolute" }} alt="4th Option" />
                                                                                    ) : (
                                                                                        <img src='/images.png' style={{ "margin": "19px 22px 0 22px", "position": "absolute" }} alt="4th Option" />
                                                                                    )}
                                                                                    <FormControlLabel className='RadioBox' value="Image" label="Choose Images" labelPlacement="start" control={<Radio />}
                                                                                        style={{
                                                                                            color: selectedMediaOption === 'Image' ? 'blue' : 'black',
                                                                                            backgroundColor: selectedMediaOption === 'Image' ? '#FAFDFF' : '#fff',
                                                                                            border: selectedMediaOption === 'Image' ? '2px solid #0889FF' : '1px solid #E4E4E4',
                                                                                        }}
                                                                                    />
                                                                                </Box>
                                                                            </RadioGroup>
                                                                        </Item>
                                                                    </Grid>
                                                                    <Grid item xs={6}>
                                                                        <Item className='inneritems'>
                                                                            <RadioGroup name="use-radio-group" value={selectedMediaOption} onChange={handleMediaOptionChange}>
                                                                                <Box style={{ "position": "relative" }}>
                                                                                    {selectedMediaOption === 'Video' ? (
                                                                                        <img src='/activevideo.png' style={{ "margin": "19px 22px 0 22px", "position": "absolute" }} alt="4th Option" />
                                                                                    ) : (
                                                                                        <img src='/video.png' style={{ "margin": "19px 22px 0 22px", "position": "absolute" }} alt="4th Option" />
                                                                                    )}
                                                                                    <FormControlLabel className='RadioBox' value="Video" label="Choose Video" labelPlacement="start" control={<Radio />}
                                                                                        style={{
                                                                                            color: selectedMediaOption === 'Video' ? 'blue' : 'black',
                                                                                            backgroundColor: selectedMediaOption === 'Video' ? '#FAFDFF' : '#fff',
                                                                                            border: selectedMediaOption === 'Video' ? '2px solid #0889FF' : '1px solid #E4E4E4',
                                                                                        }}
                                                                                    />
                                                                                </Box>
                                                                            </RadioGroup>
                                                                        </Item>
                                                                    </Grid>
                                                                    <Grid item xs={6}>
                                                                        <Item className='inneritems'>
                                                                            <RadioGroup name="use-radio-group" value={selectedMediaOption} onChange={handleMediaOptionChange}>
                                                                                <Box style={{ "position": "relative" }}>
                                                                                    {selectedMediaOption === 'Document' ? (
                                                                                        <img src='/activedocument.png' style={{ "margin": "19px 22px 0 22px", "position": "absolute" }} alt="4th Option" />
                                                                                    ) : (
                                                                                        <img src='/document.png' style={{ "margin": "19px 22px 0 22px", "position": "absolute" }} alt="4th Option" />
                                                                                    )}
                                                                                    <FormControlLabel className='RadioBox' value="Document" label="Choose Document" labelPlacement="start" control={<Radio />}
                                                                                        style={{
                                                                                            color: selectedMediaOption === 'Document' ? 'blue' : 'black',
                                                                                            backgroundColor: selectedMediaOption === 'Document' ? '#FAFDFF' : '#fff',
                                                                                            border: selectedMediaOption === 'Document' ? '2px solid #0889FF' : '1px solid #E4E4E4',
                                                                                        }}
                                                                                    />
                                                                                </Box>
                                                                            </RadioGroup>
                                                                        </Item>
                                                                    </Grid>
                                                                    <Grid item xs={6}>
                                                                        <Item className='inneritems'>
                                                                            <RadioGroup name="use-radio-group" value={selectedMediaOption} onChange={handleMediaOptionChange}>
                                                                                <Box style={{ "position": "relative" }}>
                                                                                    {selectedMediaOption === 'Location' ? (
                                                                                        <img src='/activelocation.png' style={{ "margin": "19px 22px 0 22px", "position": "absolute" }} alt="4th Option" />
                                                                                    ) : (
                                                                                        <img src='/location.png' style={{ "margin": "19px 22px 0 22px", "position": "absolute" }} alt="4th Option" />
                                                                                    )}
                                                                                    <FormControlLabel className='RadioBox' value="Location" label="Choose Location" labelPlacement="start" control={<Radio />}
                                                                                        style={{
                                                                                            color: selectedMediaOption === 'Location' ? 'blue' : 'black',
                                                                                            backgroundColor: selectedMediaOption === 'Location' ? '#FAFDFF' : '#fff',
                                                                                            border: selectedMediaOption === 'Location' ? '2px solid #0889FF' : '1px solid #E4E4E4',
                                                                                        }}
                                                                                    />
                                                                                </Box>
                                                                            </RadioGroup>
                                                                        </Item>
                                                                    </Grid>
                                                                    {selectedMediaOption === 'Image' && (
                                                                        <div style={{ "margin-left": "26px" }}>
                                                                            <Box className='SelectImagesection'>
                                                                                <Input
                                                                                    key={inputKey}
                                                                                    type="file"
                                                                                    inputProps={{ accept: 'image/*' }}
                                                                                    onChange={handleImageChange}

                                                                                    style={{ display: 'none' }}
                                                                                    id="image-upload-input"
                                                                                />
                                                                                <p style={{ "font-size": "16px", "font-weight": "500", }}>Upload Image</p>
                                                                                <div style={{ "display": "flex", }}>
                                                                                    <label htmlFor="image-upload-input">
                                                                                        <Button variant="contained" component="span" className='ImagesBtn'>
                                                                                            Choose Image
                                                                                        </Button>
                                                                                    </label>
                                                                                    {selectedImage && (
                                                                                        <div>
                                                                                            <Button className='RemoveBtn' variant="contained" color="secondary" onClick={handleRemoveImage}>
                                                                                                Remove Image
                                                                                            </Button>
                                                                                        </div>
                                                                                    )}
                                                                                </div>
                                                                            </Box>
                                                                        </div>
                                                                    )}
                                                                    {selectedMediaOption === 'Video' && (
                                                                        <div style={{ "margin-left": "26px" }}>
                                                                            <Box className='SelectImagesection'>
                                                                                <Input
                                                                                    key={inputKey2}
                                                                                    type="file"
                                                                                    inputProps={{ accept: 'video/*' }}
                                                                                    onChange={handleVideoChange}

                                                                                    style={{ display: 'none' }}
                                                                                    id="video-upload-input"
                                                                                />
                                                                                <p style={{ "font-size": "16px", "font-weight": "500", }}>Upload Vieo</p>
                                                                                <div style={{ "display": "flex", }}>
                                                                                    <label htmlFor="video-upload-input">
                                                                                        <Button variant="contained" component="span" className='ImagesBtn'>
                                                                                            Choose Video
                                                                                        </Button>
                                                                                    </label>
                                                                                    {selectedVideo && (
                                                                                        <div>
                                                                                            <Button className='RemoveBtn' variant="contained" color="secondary" onClick={handleRemoveVideo}>
                                                                                                Remove Video
                                                                                            </Button>
                                                                                        </div>
                                                                                    )}
                                                                                </div>
                                                                            </Box>
                                                                        </div>
                                                                    )}
                                                                    {selectedMediaOption === 'Document' && (
                                                                        <div style={{ "margin-left": "26px" }}>
                                                                            <Box className='SelectImagesection'>
                                                                                <Input
                                                                                    key={inputdocumentKey}
                                                                                    type="file"
                                                                                    inputProps={{ accept: '.pdf' }}  // Accept only PDF files
                                                                                    onChange={handleFileChange}
                                                                                    style={{ display: 'none' }}
                                                                                    id="document-upload-input"
                                                                                />
                                                                                <p style={{ "font-size": "16px", "font-weight": "500", }}>Upload Document</p>
                                                                                <div style={{ "display": "flex", }}>
                                                                                    <label htmlFor="document-upload-input">
                                                                                        <Button variant="contained" component="span" className='ImagesBtn'>
                                                                                            Choose Document
                                                                                        </Button>
                                                                                    </label>
                                                                                    {selectedDocument && (
                                                                                        <Button className='RemoveBtn' variant="outlined" onClick={handleRemoveDocument}>
                                                                                            Remove Document
                                                                                        </Button>
                                                                                    )}
                                                                                </div>
                                                                            </Box>
                                                                        </div>
                                                                    )}
                                                                </Grid>
                                                            )}
                                                        </Box>
                                                    </Item>
                                                </Grid>
                                            </Grid>
                                        </div>
                                    </Item>
                                </Grid>
                            </Grid>
                            {/* Body Section */}
                            <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }} mt={3}>
                                <Grid item xs={12}>
                                    <Item className='griditems'>
                                        <Box style={{ "padding": "10px 0px 0px 17px", }}>
                                            <h2 className='formheading'>Body</h2>
                                            <p style={{ "color": "#939393", "fontSize": "10px", "textAlign": "left", "margin-top": "2px", "marginBottom": "0px", }}>Add a message header to show in message in top</p>
                                            <Button className='addInput' variant="contained" color="primary" onClick={addInputField}>
                                                <img src='/add.png' />
                                            </Button>
                                        </Box>
                                        <div style={{ "padding": "0 9px 10px", }}>
                                            <textarea className='textareafield' rows="8" name='body_text' placeholder='None'
                                         onChange={handleDataChange} 
                                            >
                                            </textarea>
                                            <label className='errormsg' style={{ "width": "100%", "textAlign": "left", "display": "block", }}>Enter body content HTML not allowed</label>
                                        </div>
                                        <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
                                            <Grid item xs={12}>
                                                <Item className='inneritems'>
                                                    <Grid container spacing={2}>
                                                        {inputFields.map((value, index) => (
                                                            <Grid item xs={6} key={index} sx={{ "marginBottom": "8px", }}>
                                                                <label for="Category-select"
                                                                    style={{
                                                                        "text-align": "left",
                                                                        "width": "100%",
                                                                        "display": "block",
                                                                    }}
                                                                >Sample text for variable {index + 1}</label>
                                                                <input
                                                                    label={`Input Field ${index + 1}`}
                                                                    variant="outlined"
                                                                    fullWidth
                                                                    value={value}
                                                                    onChange={(e) => handleInputChange(index, e.target.value)}
                                                                    className='inputfield' type="text"
                                                                    placeholder={`Sample text for variable ${index + 1}`}
                                                                />
                                                            </Grid>
                                                        ))}
                                                    </Grid>
                                                </Item>
                                            </Grid>
                                        </Grid>
                                    </Item>
                                </Grid>
                            </Grid>
                            {/* Footer Section */}
                            <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }} mt={3}>
                                <Grid item xs={12}>
                                    <Item className='griditems' sx={{ "height": "16em", }}>
                                        <Box style={{ "padding": "10px 0px 0px 17px", }}>
                                            <h2 className='formheading'>Footer</h2>
                                            <p style={{ "color": "#939393", "fontSize": "10px", "textAlign": "left", "margin-top": "2px", }}>Add a message header to show in message in top</p>
                                        </Box>
                                        <div style={{ "padding": "0 9px 10px", }}>
                                            <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
                                                <Grid item xs={12}>
                                                    <Item className='inneritems'>
                                                        <Box
                                                            sx={{
                                                                maxWidth: '100%',
                                                                "textAlign": "left",
                                                            }}
                                                        >
                                                            <label for="Category-select">Footer Text</label><br />
                                                            <input id="tac" className='inputfield' type="text" name="footer_text" placeholder='Enter Footer Text'
                                                         onChange={handleDataChange} 
                                                            />
                                                            <label className='errormsg' style={{ "width": "100%", "textAlign": "left", "display": "block", }}>Enter footer Text</label>
                                                        </Box>
                                                    </Item>
                                                </Grid>
                                            </Grid>
                                        </div>
                                    </Item>
                                </Grid>
                            </Grid>
                            {/* Button Section */}
                            <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }} mt={3}>
                                <Grid item xs={12}>
                                    <Item className='griditems'>
                                        <Box style={{ "padding": "10px 0px 0px 17px", }}>
                                            <h2 className='formheading'>Button</h2>
                                            <p style={{ "color": "#939393", "fontSize": "10px", "textAlign": "left", "margin-top": "2px", }}>Add a message header to show in message in top</p>
                                        </Box>
                                        <div style={{ "padding": "0 9px 10px", }}>
                                            <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
                                                <Grid item xs={12}>
                                                    <Item className='inneritems'>
                                                        <Box
                                                            sx={{
                                                                maxWidth: '100%',
                                                                "textAlign": "left",
                                                            }}
                                                        >
                                                            <div>
                                                                <Box className='recommendBox'>
                                                                    <Stack direction="row" spacing={2}>
                                                                        <img src='/lightbulb.png' height={17} /><span><h4 style={{ "margin": "0px 0px 0px 0", "font-size": "17px", "font-weight": "500", }}>We recommend adding the marketing opt-out Buttons.</h4></span>
                                                                    </Stack>
                                                                    <p style={{ "margin-left": "30px", "margin-bottom": "0", }}>Allow customers to request to opt out of all marketing messages. this an help reduce blocks from customers and increase your quality rating. <a href='#' style={{ "textDecoration": "none", "color": "blue", "font-weight": "500", }}>Learn more.</a></p>
                                                                </Box>
                                                            </div>
                                                        </Box>
                                                    </Item>
                                                </Grid>
                                            </Grid>
                                            <Box sx={{ flexGrow: 1 }}>
                                                <Grid container spacing={2} columns={12}>
                                                    <Grid item xs={12} className=''>
                                                        <Item className='inneritems'>
                                                            <Box
                                                                sx={{
                                                                    maxWidth: '100%',
                                                                    textAlign: 'left',
                                                                }}
                                                            >
                                                                <div>
                                                                    <Button
                                                                        aria-controls="simple-menu"
                                                                        aria-haspopup="true"
                                                                        onClick={handleClick}
                                                                        variant="contained"
                                                                        className="Buttontype"
                                                                    >
                                                                        Add a Button
                                                                    </Button>
                                                                    <Menu
                                                                        id="simple-menu"
                                                                        anchorEl={anchorEl}
                                                                        open={Boolean(anchorEl)}
                                                                        onClose={handleClose}
                                                                    >
                                                                        <Typography style={{ "padding": "6px 13px", "font-size": "16px", "font-weight": "500", }}>Quick reply buttons</Typography>
                                                                        {/* <MenuItem onClick={() => handleMenuItemClick('Item 1')} className='marketing' sx={{
                                                                            "display": "grid", cursor: disabledItems.includes('Item 1') ? 'not-allowed' : 'pointer',
                                                                            opacity: disabledItems.includes('Item 1') ? 0.5 : 1,
                                                                            pointerEvents: disabledItems.includes('Item 1') ? 'none' : 'auto',
                                                                        }}>Marketing opt-out
                                                                            <span style={{ "fontSize": "12px", }}>Recommended</span>
                                                                        </MenuItem> */}
                                                                        <MenuItem onClick={() => handlesecondMenuItemClick('QUICK_REPLY')} className='custom' sx={{ "display": "grid" }}>Custom</MenuItem>
                                                                        <Divider />
                                                                        <Typography style={{ "padding": "6px 13px", "font-size": "16px", "font-weight": "500", }}>
                                                                            Call-to-action buttons
                                                                        </Typography>
                                                                        <MenuItem onClick={() => handleVistiWebsiteClick('URL')} sx={{ "display": "grid" }} disabled={clickCount >= 2}>Visit Website
                                                                            <span style={{ "fontSize": "12px", }}>2 Buttons maximum</span>
                                                                        </MenuItem>
                                                                        <MenuItem onClick={() => handleMenuItem2Click('PHONE_NUMBER')} sx={{
                                                                            "display": "grid", cursor: disabledItems.includes('PHONE_NUMBER') ? 'not-allowed' : 'pointer',
                                                                            opacity: disabledItems.includes('PHONE_NUMBER') ? 0.5 : 1,
                                                                            pointerEvents: disabledItems.includes('PHONE_NUMBER') ? 'none' : 'auto',
                                                                        }}>Call Phone Number
                                                                            <span style={{ "fontSize": "12px", }}>1 Buttons maximum</span>
                                                                        </MenuItem>
                                                                        <MenuItem onClick={() => handleMenuItem2Click('COPY_CODE')} sx={{
                                                                            "display": "grid", cursor: disabledItems.includes('COPY_CODE') ? 'not-allowed' : 'pointer',
                                                                            opacity: disabledItems.includes('COPY_CODE') ? 0.5 : 1,
                                                                            pointerEvents: disabledItems.includes('COPY_CODE') ? 'none' : 'auto',
                                                                        }}>Copy offer code
                                                                            <span style={{ "fontSize": "12px", }}>1 Buttons maximum</span>
                                                                        </MenuItem>
                                                                    </Menu>
                                                                </div>
                                                                <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }} mt={1}>
                                                                    <Grid item xs={12}>
                                                                        <Item className='inneritems'>
                                                                            <Box
                                                                                sx={{
                                                                                    maxWidth: '100%',
                                                                                    "textAlign": "left",
                                                                                }}
                                                                            >
                                                                                <Stack direction="row" spacing={2}>
                                                                                    <img src='/lightbulb.png' height={17} />
                                                                                    <p>if you add more than three buttons, they will appear in a list</p>
                                                                                </Stack>
                                                                            </Box>
                                                                        </Item>
                                                                    </Grid>
                                                                </Grid>
                                                                {/* Marketing or Custom */}
                                                                {isButtontypeBoxCreated && (
                                                                    <Box className='ButtontypeBox'>
                                                                        <Stack direction="row" spacing={1}>
                                                                            <img src='/arrows.png' width={23} />
                                                                            <Typography className='Buttonheading'>Quick reply buttons</Typography>
                                                                        </Stack>
                                                                        {selectedItems.map((item, index) => (
                                                                            <Box key={index} className='Marketingitembox' sx={{ flexGrow: 1 }}>
                                                                                {item === 'Item 1' && (
                                                                                    <>
                                                                                        <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 1 }}>
                                                                                            <Grid item xs={4}>
                                                                                                <Item className='inneritems' sx={{ "textAlign": "left" }}>
                                                                                                    <label for="Type-select" style={{ "fontWeight": "600", color: "#000" }}>Type</label><br />
                                                                                                    <input id="tac" className='inputfield' type="text" name="terms-and-conditions" placeholder='Markting opt-out' />
                                                                                                </Item>
                                                                                            </Grid>
                                                                                            <Grid item xs={8}>
                                                                                                <Item className='inneritems' sx={{ "textAlign": "left" }}>
                                                                                                    <label for="Category-select" style={{ "fontWeight": "600", color: "#000" }}>Button Text</label><br />
                                                                                                    <input id="tac" className='inputfield' type="text" name="terms-and-conditions" placeholder='Stop Promotions' maxLength='25' />
                                                                                                    <label className='inputlenght' style={{ "width": "100%", "display": "block", }}>12/25</label>
                                                                                                </Item>
                                                                                            </Grid>
                                                                                            <Grid item xs={12}>
                                                                                                <Item className='inneritems' sx={{ "textAlign": "left", "padding": "0px" }}>
                                                                                                    <Stack direction='row' spacing={1}>
                                                                                                        <Checkbox {...label} />
                                                                                                        <p style={{ "font-size": "12px", "font-weight": "500", "marginTop": "13px", marginLeft: 0, }}>I understand that it's Test Whatsapp Bussiness Account's responsibility to stop sending marketing message to customers who opt out.</p>
                                                                                                    </Stack>
                                                                                                </Item>
                                                                                            </Grid>

                                                                                        </Grid>
                                                                                        <IconButton onClick={() => handleDeleteButtonClick(item, 'Buttontype', index)}>
                                                                                            <DeleteIcon />
                                                                                        </IconButton>
                                                                                    </>
                                                                                )}
                                                                                {item === 'QUICK_REPLY' && (
                                                                                    <>
                                                                                        {getItem2Content(item,index)}
                                                                                        <IconButton onClick={() => handleDeleteButtonClick(item, 'Buttontype', index)}>
                                                                                            <DeleteIcon />
                                                                                        </IconButton>
                                                                                    </>
                                                                                )}

                                                                            </Box>
                                                                        ))}
                                                                    </Box>
                                                                )}
                                                                {/* Call To Action Section */}
                                                                {isBoxCreated && (
                                                                    <Box className='ButtontypeBox'>
                                                                        <Stack direction="row" spacing={1}>
                                                                            <img src='/arrows.png' width={23} />
                                                                            <Typography className='Buttonheading'>Call-to-action buttons</Typography>
                                                                        </Stack>
                                                                        {selectedItems2.map((item, index) => (
                                                                            <Box key={index} className='Marketingitembox' sx={{ flexGrow: 1 }}>
                                                                                {item === 'URL' && (
                                                                                    <>
                                                                                        {getItem3Content(item,index)}
                                                                                        <IconButton onClick={() => handleDeleteButtonClick(item, 'CallToAction', index)}>
                                                                                            <DeleteIcon />
                                                                                        </IconButton>
                                                                                    </>
                                                                                )}
                                                                                {item === 'PHONE_NUMBER' && (
                                                                                    <>
                                                                                        <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 1 }} columns={16}>
                                                                                            <Grid item xs={4}>
                                                                                                <Item className='inneritems' sx={{ "textAlign": "left" }}>
                                                                                                    <label for="Type-select" style={{ "fontWeight": "600", color: "#000" }}>Type of action</label><br />
                                                                                                    <select className='selectBox' name="button_type" id="Type-select" style={{ "padding": "0px 0px 0px 6px", height: "30px", }}
                                                                                                     onChange={(event) => handlebuttonDataChange(event, index,item)}
                                                                                                    >
                                                                                                        <option value="Call Phone Number">Call Phone Number</option>
                                                                                                    </select>
                                                                                                </Item>
                                                                                            </Grid>
                                                                                            <Grid item xs={4}>
                                                                                                <Item className='inneritems' sx={{ "textAlign": "left" }}>
                                                                                                    <label for="Category-select" style={{ "fontWeight": "600", color: "#000" }}>Button Text</label><br />
                                                                                                    <input id="tac" className='inputfield' type="text" name="button_text" placeholder='Button Text' maxLength='25'
                                                                                                     onChange={(event) => handlebuttonDataChange(event, index,item)}
                                                                                                    />
                                                                                                    <label className='inputlenght' style={{ "width": "100%", "display": "block", }}>12/25</label>
                                                                                                </Item>
                                                                                            </Grid>
                                                                                            <Grid item xs={5}>
                                                                                                <Item className='inneritems' sx={{ "textAlign": "left" }}>
                                                                                                    <label for="Category-select" style={{ "fontWeight": "600", color: "#000" }}>Phone Number</label><br />
                                                                                                    <input id="tac" className='inputfield' type="text" name="button_action" placeholder='Enter number' maxLength='25'
                                                                                                     onChange={(event) => handlebuttonDataChange(event, index,item)}
                                                                                                    />
                                                                                                    <label className='inputlenght' style={{ "width": "100%", "display": "block", }}>0/10</label>
                                                                                                </Item>
                                                                                            </Grid>
                                                                                        </Grid>
                                                                                        <IconButton onClick={() => handleDeleteButtonClick(item, 'CallToAction', index)}>
                                                                                            <DeleteIcon />
                                                                                        </IconButton>
                                                                                    </>
                                                                                )}
                                                                                {item === 'COPY_CODE' && (
                                                                                    <>
                                                                                        <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 1 }} columns={16}>
                                                                                            <Grid item xs={4}>
                                                                                                <Item className='inneritems' sx={{ "textAlign": "left" }}>
                                                                                                    <label for="Type-select" style={{ "fontWeight": "600", color: "#000" }}>Type of action</label><br />
                                                                                                    <select className='selectBox' name="button_type" id="Type-select" style={{ "padding": "0px 0px 0px 6px", height: "30px", }}
                                                                                                     onChange={(event) => handlebuttonDataChange(event, index,item)}
                                                                                                    >
                                                                                                        <option value="Copy Offer Code">Copy Offer Code</option>
                                                                                                    </select>
                                                                                                </Item>
                                                                                            </Grid>
                                                                                            <Grid item xs={4}>
                                                                                                <Item className='inneritems' sx={{ "textAlign": "left" }}>
                                                                                                    <label for="Category-select" style={{ "fontWeight": "600", color: "#000" }}>Button Text</label><br />
                                                                                                    <input id="tac" className='inputfield' type="text" name="button_text" placeholder='Copy Offer Code' maxLength='25'
                                                                                                     onChange={(event) => handlebuttonDataChange(event, index,item)}
                                                                                                    />
                                                                                                </Item>
                                                                                            </Grid>
                                                                                        </Grid>
                                                                                        <Stack direction='column' spacing={1} mt={1} sx={{ marginLeft: "8px", }}>
                                                                                            <label style={{ "fontWeight": "600", color: "#000", fontSize: 14, }}>Add sample offer code </label>
                                                                                            <Typography>To help us review your message template, please add an example for your offer code.</Typography>
                                                                                        </Stack>
                                                                                        <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 1 }}>
                                                                                            <Grid item xs={12}>
                                                                                                <Item className='inneritems' sx={{ "textAlign": "left" }}>
                                                                                                    <label for="Type-select" style={{ "fontWeight": "600", color: "#000" }}>Offer code</label><br />
                                                                                                    <input id="tac" className='inputfield' type="text" name="button_action" placeholder='Enter sample' maxLength='15'
                                                                                                     onChange={(event) => handlebuttonDataChange(event, index,item)}
                                                                                                    />
                                                                                                    <label className='inputlenght' style={{ "width": "100%", "display": "block", }}>0/15</label>
                                                                                                </Item>
                                                                                            </Grid>
                                                                                        </Grid>
                                                                                        <Box className='sampletexterror'>
                                                                                            <Stack direction='row' spacing={2}>
                                                                                                <ReportProblemIcon sx={{ color: "#fd0909a3", fontSize: 20 }} />
                                                                                                <Typography sx={{ fontSize: 14 }}>Add Smaple Text</Typography>
                                                                                            </Stack>
                                                                                        </Box>
                                                                                        <IconButton onClick={() => handleDeleteButtonClick(item, 'CallToAction', index)}>
                                                                                            <DeleteIcon />
                                                                                        </IconButton>
                                                                                    </>
                                                                                )}
                                                                            </Box>
                                                                        ))}
                                                                    </Box>
                                                                )}
                                                            </Box>
                                                        </Item>
                                                    </Grid>
                                                </Grid>
                                            </Box>
                                        </div>
                                    </Item>
                                </Grid>
                            </Grid>
                        </Grid>
                        {/* Action Section */}
                        <Grid item xs={4}>
                            <Item className='actionpreview'>
                                <div className='actionBox'>
                                    <Box style={{ "padding": "10px 0px 0px 17px", }}>
                                        <h2 className='formheading'>Action</h2>
                                    </Box>
                                    <div style={{ "padding": "16px 10px", }}>
                                        <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
                                            <Grid item xs={12}>
                                                <Item className='inneritems'>
                                                    <p style={{ "fontSize": "10px", "color": "#9E9E9E", "textAlign": "left", "margin": "0px" }} >Status</p>
                                                </Item>
                                            </Grid>
                                        </Grid>
                                        <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
                                            <Grid item xs={3}>
                                                <Item className='inneritems'>
                                                    <Box style={{
                                                        "font-size": "12px",
                                                        "background": "#EAEAEA",
                                                        "border-radius": "64px",
                                                        "text-align": "center",
                                                        "width": "72px",
                                                        "height": "26px",
                                                        "font-weight": "500",
                                                        "display": "flex",
                                                        "align-items": "center",
                                                        "justify-content": "center",

                                                    }}><p style={{ "margin": "0px", }}>Draft</p>
                                                    </Box>
                                                </Item>
                                            </Grid>
                                            <Grid item xs={3}>
                                                <Item className='inneritems'>
                                                    <Box style={{
                                                        "font-size": "12px",
                                                        "background": "#FF4E4E",
                                                        "border-radius": "64px",
                                                        "text-align": "center",
                                                        "width": "76px",
                                                        "height": "26px",
                                                        "font-weight": "500",
                                                        "display": "flex",
                                                        "align-items": "center",
                                                        "justify-content": "center",

                                                    }}><p style={{ "margin": "0px", }}>Pending</p>
                                                    </Box>
                                                </Item>
                                            </Grid>
                                            <Grid item xs={3}>
                                                <Item className='inneritems'>
                                                    <Box style={{
                                                        "font-size": "12px",
                                                        "background": "#14F81D",
                                                        "border-radius": "64px",
                                                        "text-align": "center",
                                                        "width": "86px",
                                                        "height": "26px",
                                                        "font-weight": "500",
                                                        "display": "flex",
                                                        "align-items": "center",
                                                        "justify-content": "center",

                                                    }}><p style={{ "margin": "0px", }}>Complete</p>
                                                    </Box>
                                                </Item>
                                            </Grid>
                                        </Grid>
                                        <Divider variant="middle" style={{ "margin": "14px 0" }} />
                                        <Grid container spacing={2}>
                                            <Grid item xs={12}>
                                                <Item className='inneritems'>
                                                    <Button variant="contained" className='approvelbtn' type='submit' >Save for approvel</Button>
                                                </Item>
                                            </Grid>
                                        </Grid>
                                    </div>
                                </div>
                                <div className='previewBox' style={{ "padding": " 19px 16px", }}>
                                    <Box>

                                        <Card sx={{ maxWidth: "100%", "background": "#F1F8F7", "padding": "19px 16px", }}>
                                            <Box style={{
                                                "background-color": "#D3E8E5", "border-top-right-radius": "21px", "border-top-left-radius": "21px", "height": "150px", "align-items": "center", "display": " flex", "justify-content": "center",
                                            }}>
                                                <CardMedia
                                                    sx={{}}
                                                    title="green iguana"
                                                >
                                                    {selectedMediaOption === 'Image' && selectedImage && (
                                                        <div>
                                                            <img
                                                                src={URL.createObjectURL(selectedImage)}
                                                                alt="Selected Preview"
                                                                style={{ width: "100px", marginTop: "9px", }}
                                                            />
                                                        </div>
                                                    )}
                                                    {selectedMediaOption === 'Video' && selectedVideo && (
                                                        <div>
                                                            <video controls width="200">
                                                                <source src={selectedVideo} type="video/mp4" />
                                                                Your browser does not support the video tag.
                                                            </video>
                                                        </div>
                                                    )}
                                                    {selectedMediaOption === 'Document' && selectedDocument && (
                                                        <Typography variant="body1">{selectedDocument.name}</Typography>
                                                    )}
                                                </CardMedia>
                                            </Box>
                                            <CardContent>
                                                <Typography type="text" display={'ruby-text-container'}>{bodyContent}</Typography>
                                                <Typography variant="body2" color="text.secondary" sx={{
                                                    "textAlign": "left",
                                                    "font-weight": "600",
                                                }}>

                                                    Hey ( 1 ),

                                                    Thank you for shipping with us, ( 2 ) ( 3 ) ( 4 ) ( 5 )
                                                </Typography>
                                            </CardContent>
                                            <CardActions>
                                                <a href="#" style={{ "color": "#E27606", "fontSize": "12px", "textAlign": "right", "width": "100%", "marginTop": "32px", }}>Visit Website</a>
                                            </CardActions>
                                        </Card>
                                    </Box>
                                </div>
                            </Item>
                        </Grid>
                    </Grid>
                </form>
            </div>

        </>
    )
}

