import { Box, Button, Card, CardActions, CardContent, CardMedia, Divider, FormControlLabel, Grid, Input, Menu, MenuItem, Radio, RadioGroup, Stack, Typography, Checkbox, List, ListItem, IconButton } from '@mui/material';
import Paper from '@mui/material/Paper';
import { styled } from '@mui/material/styles';
import React, { useEffect, useState } from 'react';
import TextField from '@mui/material/TextField';
import PropTypes from 'prop-types';
import { AuthApi } from '../service';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import ReportProblemIcon from '@mui/icons-material/ReportProblem';
import { ToastContainer, toast } from 'react-toastify';
import DeleteIcon from '@mui/icons-material/Delete';
import { debounce } from 'lodash';
import { UserApi } from '../service/axios';
import { useLocation, useNavigate } from 'react-router-dom';
import { Helpers } from '../service';




const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
    ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: 'center',
    color: theme.palette.text.secondary,
}));


const label = { inputProps: { 'aria-label': 'Checkbox demo' } };
export default function MassageTemplateForm() {

    const [bodyContent, setBodyContent] = useState('');
    const [footerContent, setFooterContent] = useState('');
    const { state } = useLocation();
    const [selectedHeaderType, setSelectedHeaderType] = useState('');
    const [selectedMediaOption, setSelectedMediaOption] = useState('');
    const [selectedDocument, setSelectedDocument] = useState(null);
    const [inputdocumentKey, setInputdocumentKey] = useState(0);
    const [selectedImage, setSelectedImage] = useState(null);
    const [inputKey, setInputKey] = useState(0);
    const [selectedVideo, setSelectedVideo] = useState(null);
    const [inputKey2, setInputKey2] = useState(0);
    const [inputFields, setInputFields] = useState([]);
    const [anchorEl, setAnchorEl] = useState(null);
    const [selectedItems, setSelectedItems] = useState([]);
    const [selectedItems2, setSelectedItems2] = useState([]);
    const [isButtontypeBoxCreated, setButtontypeBoxCreated] = useState(false);
    const [isBoxCreated, setBoxCreated] = useState(false);
    const [clickCount, setClickCount] = useState(0);
    const [disabledItems, setDisabledItems] = useState([]);
    const [isBoxDeleted, setIsBoxDeleted] = useState(false);
    const [colorPending, setcolorPending] = useState('#EAEAEA');
    const [colorRejected, setcolorRejected] = useState('#EAEAEA');
    const [colorApproved, setcolorApproved] = useState('#EAEAEA');
    const navigate = useNavigate();

    const [selectedCategory, setSelectedCategory] = useState({
        header_text: '',
        header_type: '',
        media: '',
    });
    const [data, setData] = useState({
        category: '',
        name: '',
        language_code: '',
        body_text: '',
        footer_text: '',
        example_body_text: '',
        button: [{}]
    });

    useEffect(() => {
        if (state) {
            fetchTemplateDetails(state);
        }
    }, []);

    const fetchTemplateDetails = async (id) => {
        try {
            const resp = await UserApi.gettemplatebyId(id);
            if (resp.status === 200) {
                const updatedData = {
                    ...data,
                    category: resp.data.template.category,
                    name: resp.data.template.name,
                    language_code: resp.data.template.language_code,
                    body_text: resp.data.template.body_text,
                    footer_text: resp.data.template.footer_text,
                    example_body_text: resp.data.template.example_body_text,
                    button: resp.data.button
                };
                if (resp.data.template.status === "PENDING") {
                    setcolorPending("#FFA500");
                } else if (resp.data.template.status === "APPROVED") {
                    setcolorApproved("#14F81D");
                } else if (resp.data.template.status === "REJECTED") {
                    setcolorRejected("#FF0000");
                }
                for (let i = 0; i < resp.data.button.length; i++) {
                    const buttonType = resp.data.button[i];
                    if (buttonType.button_type === "QUICK_REPLY") {
                        setSelectedItems(prevItems => [...prevItems, buttonType]);
                        setButtontypeBoxCreated(true);
                    } else {
                        setSelectedItems2(prevItems => [...prevItems, buttonType]);
                        setBoxCreated(true);
                    }
                }
                setData(updatedData);
                if (resp.data.template.example_body_text !== null) {
                    const inputString = resp.data.template.example_body_text;
                    const stringArray = inputString.split(',');
                    setInputFields(stringArray);
                }
                const updatedheaderData = {
                    ...selectedCategory,
                    header_text: resp.data.template.header_text,
                    header_type: resp.data.template.header_type,
                };
                setSelectedCategory(updatedheaderData);
                var headertype = resp.data.template.header_type;
                setSelectedMediaOption(headertype.charAt(0).toUpperCase() + headertype.slice(1).toLowerCase());
            } else {
                console.error('Error sending template:', resp.data.error_msg);
                alert(resp.data.error_msg);
            }
        } catch (error) {
            console.error('Error sending template:', error);
            Helpers.error401(error);
        } finally {
        }
    };

    const handleCategoryChange = (event) => {
        const { name, value } = event.target;
        setSelectedCategory((prev) => {
            return { ...prev, [name]: value };
        });
    };


    const handlebuttonDataChange = (event, index, item) => {
        const { name, value } = event.target;
        if (item === 'QUICK_REPLY') {
            if (selectedItems[index].template_id === "NEW") {
                const updatedItems = [...selectedItems];
                updatedItems[index][name] = value;
                setSelectedItems(updatedItems);
                index = index + selectedItems2.length;
            } else {
                const updatedItems = [...selectedItems];
                updatedItems[index][name] = value;
                setSelectedItems(updatedItems);
            }
        } else {
            if (selectedItems2[index].template_id === "NEW") {
                const updatedItems2 = [...selectedItems2];
                updatedItems2[index][name] = value;
                setSelectedItems2(updatedItems2);
                index = index + selectedItems.length;
            } else {
                const updatedItems2 = [...selectedItems2];
                updatedItems2[index][name] = value;
                setSelectedItems2(updatedItems2);
            }
        }
        setData((prev) => {
            const newData = { ...prev };
            const updatedButton = {
                ...newData.button[index],
                [name]: value,
            };
            if (name === 'button_text' && !updatedButton.button_type && !updatedButton.button_action && item === 'QUICK_REPLY') {
                updatedButton.button_type = 'QUICK_REPLY';
                updatedButton.button_action = 'Custom';
            }
            if (name === 'button_text' && !updatedButton.button_type && item === 'URL') {
                updatedButton.button_type = 'URL';
            }
            if (name === 'button_text' && !updatedButton.button_type && item === 'PHONE_NUMBER') {
                updatedButton.button_type = 'PHONE_NUMBER';
            }
            if (name === 'button_text' && !updatedButton.button_type && !updatedButton.button_action && item === 'COPY_CODE') {
                updatedButton.button_type = 'COPY_CODE';
            }
            newData.button[index] = updatedButton;
            return newData;
        });

    };

    const handleDataChange = (event) => {
        const { name, value } = event.target;
        setData((prev) => {
            const [firstPart, secondPart] = name.split('.');
            if (secondPart) {
                return {
                    ...prev,
                    [firstPart]: {
                        ...prev[firstPart],
                        [secondPart]: value,
                    },
                };
            }

            return {
                ...prev,
                [name]: value,
            };
        });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        if (selectedMediaOption === 'Location') {
            selectedCategory.header_type = "LOCATION"
        }
        var count = 0;
        if (inputFields && inputFields.length > 0) {
            for (const field of inputFields) {
                if (count == 0) {
                    data.example_body_text = field;
                    count++;
                } else {
                    data.example_body_text += ",";
                    data.example_body_text += field;
                    count++;
                }
            }
        } else {
            delete data.example_body_text;
        }
        if (!data.button || !data.button[0] || !data.button[0].button_text) {
            delete data.button;
        } else {
            data.button = JSON.stringify(data.button, null, 2);
        }
        const combinedData = {
            ...data,
            ...selectedCategory,
        };

        try {
            if (state) {
                const response = await UserApi.updatetemplate(combinedData, state);
                toast.success('Template updated');
                navigate('/dashboard/massagetemplate');
            } else {
                const response = await UserApi.template(combinedData);
                toast.success('Template created');
                navigate('/dashboard/massagetemplate');
            }
        } catch (error) {
            console.error('Error submitting data:', error);
            toast.error('Invalid Format');
        }
    };


    const chngebodytext = (bodyText) => {
        const exampleBodyText = data.example_body_text;
        if (bodyText.includes("{{")) {
            const replacedBodyText = bodyText.replace(
                /{{(\d+)}}/g,
                (match, index) => {
                    const exampleValues = exampleBodyText.split(",");
                    const numericalIndex = parseInt(index) - 1;
                    return exampleValues[numericalIndex] || match;
                }
            );
            return replacedBodyText;
        }
        return bodyText;
    };

    const handleMediaOptionChange = (event) => {
        setSelectedMediaOption(event.target.value);
    };

    const handleImageChange = (event) => {
        const file = event.target.files[0];
        if (file && (file.type === 'image/jpeg' || file.type === 'image/png')) {
            setSelectedImage(file);
            selectedCategory.media = file;
            selectedCategory.header_type = "IMAGE"
        } else {
            alert('Please select a valid jpg or png image.');
        }
    };
    const handleRemoveImage = () => {
        setSelectedImage(null);
        setInputKey((prevKey) => prevKey + 1);
    };

    const handleRemoveVideo = () => {
        setSelectedVideo(null);
        setInputKey2((prevKey) => prevKey + 1);
    };
    const handleVideoChange = (event) => {
        const file = event.target.files[0];

        if (file) {
            try {
                const videoUrl = URL.createObjectURL(file);
                setSelectedVideo(videoUrl);
                selectedCategory.media = file;
                selectedCategory.header_type = "VIDEO"
            } catch (error) {
                console.error('Error creating object URL:', error);
            }
        }
    };

    const handleRemoveDocument = () => {
        setSelectedDocument(null);
        setInputdocumentKey((prevKey) => prevKey + 1);
    };
    const handleFileChange = (event) => {
        const selectedFile = event.target.files[0];

        if (selectedFile && selectedFile.type === 'application/pdf') {
            selectedCategory.media = selectedFile;
            selectedCategory.header_type = "DOCUMENT"
            setSelectedDocument(selectedFile);
        } else {
            alert('Please select a PDF file.');
        }
    };

    const addInputField = () => {
        setInputFields([...inputFields, '']);
        const bodyDataexample = {
            ...data,
            body_text: `${data.body_text} {{${inputFields.length + 1}}}`,
        };
        setData(bodyDataexample);
    };

    const handleInputChange = (index, value) => {
        const newInputFields = [...inputFields];
        newInputFields[index] = value;
        setInputFields(newInputFields);
    };

    const convertJSONToFormData = (selectedCategory) => {
        const newFormData = new FormData();

        for (const key in selectedCategory) {
            if (selectedCategory.hasOwnProperty(key)) {
                const value = selectedCategory[key];

                if (typeof value === 'object' && value !== null) {
                    for (const nestedKey in value) {
                        if (value.hasOwnProperty(nestedKey)) {
                            const nestedValue = value[nestedKey];
                            newFormData.append(`${key}_${nestedKey}`, nestedValue);
                        }
                    }
                } else {
                    newFormData.append(key, value);
                }
            }
        }

        return newFormData;
    };

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleMenuItemClick = (item) => {
        if (!isButtontypeBoxCreated) {
            setButtontypeBoxCreated(true);
        }
        if (!selectedItems.includes(item)) {
            setSelectedItems((prevItems) => [...prevItems, item]);
        }
        setDisabledItems([...disabledItems, item]);


        handleClose();
    };

    const handleMenuItem2Click = (item) => {
        if (!isBoxCreated) {
            setBoxCreated(true);
        }

        if (!selectedItems2.includes(item)) {
            setSelectedItems2((prevItems) => [...prevItems, {
                button_text: "",
                button_type: item,
                button_action: "",
                template_id: "NEW"
            }]);
        }
        setDisabledItems([...disabledItems, item]);
        handleClose();
    };
    const handleDeleteButtonClick = (item, type, index) => {
        if (type === 'Buttontype') {
            setSelectedItems((prevItems) => prevItems.filter((selectedItem, i) => i !== index));
            if (selectedItems.length === 1) {
                setButtontypeBoxCreated(false);
            }
        } else {
            setSelectedItems2((prevItems) => prevItems.filter((selectedItem, i) => i !== index));
            if (selectedItems2.length === 1) {
                setBoxCreated(false);
            }
        }

        if (item === 'URL') {
            setIsBoxDeleted((prevIsBoxDeleted) => {
                const remainingItemsCount = Math.max(selectedItems2.length - 1, 0);
                setClickCount(remainingItemsCount);
                return true;
            });
        }
        setDisabledItems((prevDisabledItems) =>
            prevDisabledItems.filter((disabledItem) => disabledItem !== item)
        );
    };

    const handlesecondMenuItemClick = (item) => {
        if (!isButtontypeBoxCreated) {
            setButtontypeBoxCreated(true);
        }
        setSelectedItems((prevItems) => [...prevItems, {
            button_text: "",
            button_type: item,
            button_action: "",
            template_id: "NEW"
        }]);
        handleClose();
    }
    const handleVistiWebsiteClick = (item) => {
        if (!isBoxCreated) {
            setBoxCreated(true);
        }

        if (clickCount < 2) {
            setSelectedItems2((prevItems) => [...prevItems, {
                button_text: "",
                button_type: item,
                button_action: "",
                template_id: "NEW"
            }]);
            setClickCount((prevCount) => prevCount + 1);
        }
        handleClose();

    }
    const getItem2Content = (item, index) => {
        return (
            <>
                <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 1 }} columns={16}>
                    <Grid item xs={0}>
                        <Item className='inneritems' sx={{ "textAlign": "left" }}>
                            <input hidden id="tac" className='inputfield' type="text" name={`button_type`} placeholder='Custom'
                                value="QUICK_REPLY"
                                onChange={(event) => handlebuttonDataChange(event, index, item.button_type)}

                            />
                        </Item>
                    </Grid>
                    <Grid item xs={12}>
                        <Item className='inneritems' sx={{ "textAlign": "left" }}>
                            <label htmlFor="Category-select" style={{ "fontWeight": "600", color: "#000" }}>Button Text</label><br />
                            <input id="tac" className='inputfield' type="text" name={`button_text`} placeholder='Button Text'
                                value={item.button_text}
                                maxLength={25}
                                onChange={(event) => handlebuttonDataChange(event, index, item.button_type)} required
                            />
                        </Item>
                    </Grid>
                    <Grid item xs={0}>
                        <Item className='inneritems' sx={{ "textAlign": "left" }}>
                            <input hidden id="tac" className='inputfield' type="text" name={`button_action`} placeholder='Button Action'
                                value="Custom"
                                onChange={(event) => handlebuttonDataChange(event, index, item.button_type)}
                            />
                        </Item>
                    </Grid>
                </Grid>
            </>
        );
    };
    const getItem3Content = (item, index) => {
        return (
            <>
                <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 1 }} columns={16}>
                    <Grid item xs={0}>
                        <Item className='inneritems' sx={{ "textAlign": "left" }}>
                            <input hidden id="tac" className='inputfield' type="text" name="button_type" placeholder='Visit Website' maxLength='25'
                                onChange={(event) => handlebuttonDataChange(event, index, item.button_type)}
                            />
                        </Item>
                    </Grid>
                    <Grid item xs={4}>
                        <Item className='inneritems' sx={{ "textAlign": "left" }}>
                            <label for="Category-select" style={{ "fontWeight": "600", color: "#000" }}>Button Text</label><br />
                            <input id="tac" className='inputfield' type="text" name="button_text" placeholder='Button Text'
                                value={item.button_text}
                                onChange={(event) => handlebuttonDataChange(event, index, item.button_type)}
                                maxLength={25} required
                            />
                        </Item>
                    </Grid>
                    <Grid item xs={0}>
                        <Item className='inneritems' sx={{ "textAlign": "left" }}>
                            <select hidden className='selectBox' name="language" id="Type-select" style={{ "padding": "0px 0px 0px 6px", height: "30px", }}>
                                <option value="URL_TYPE">URL Type</option>
                            </select>
                        </Item>
                    </Grid>
                    <Grid item xs={8}>
                        <Item className='inneritems' sx={{ "textAlign": "left" }}>
                            <label for="Category-select" style={{ "fontWeight": "600", color: "#000" }}>Website URL</label><br />
                            <input id="tac" className='inputfield' type="text" name="button_action" placeholder='https://example.com' maxLength='60'
                                value={item.button_action}
                                onChange={(event) => handlebuttonDataChange(event, index, item.button_type)} required
                            />
                        </Item>
                    </Grid>
                </Grid>
            </>
        );
    };

    return (
        <>
            <div style={{
                "padding": "20px 20px 0",
                "borderRadius": "10px",
                "marginTop": "10px",
            }}>
                <form autoComplete="off" onSubmit={handleSubmit}>
                    <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
                        <Grid item xs={8}>
                            <Item className='griditems'>
                                <Box style={{ "padding": "10px 0px 0px 17px", }}>
                                    <h2 className='formheading'>Edit Message Templates</h2>
                                </Box>
                                <div style={{ "padding": "16px 10px", }}>
                                    <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
                                        <Grid item xs={12}>
                                            <Item className='inneritems'>
                                                <Box
                                                    sx={{
                                                        maxWidth: '100%',
                                                        "textAlign": "left",
                                                    }}
                                                >
                                                    <label for="tac" className='inputlabel'>
                                                        Template Name<br />
                                                        <input id="tac" className='inputfield' type="text" name="name" placeholder='AdvancedMarketing'
                                                            value={data.name} onChange={handleDataChange} pattern="[a-z,0-9]*" required {...(state ? { readOnly: true } : {})}
                                                        />
                                                    </label>
                                                    <label className='errormsg'>Spaces and special characters are not allowed</label>

                                                </Box>
                                            </Item>
                                        </Grid>
                                    </Grid>
                                    <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
                                        <Grid item xs={6}>
                                            <Item className='inneritems'>
                                                <Box
                                                    sx={{
                                                        maxWidth: '100%',
                                                        "textAlign": "left",
                                                    }}
                                                >
                                                    <label for="Category-select">Category:</label><br />
                                                    <select className='selectBox' name="category" id="Category-select"
                                                        onChange={handleDataChange} value={data.category} {...(state ? { disabled: true } : {})}
                                                        required>
                                                        <option value="">Please choose Category</option>
                                                        <option value="MARKETING" selected={data.category === "MARKETING"}>Marketing</option>
                                                    </select>
                                                    <label className='errormsg'>Select Template category</label>
                                                </Box>
                                            </Item>
                                        </Grid>
                                        <Grid item xs={6}>
                                            <Item className='inneritems'>
                                                <Box
                                                    sx={{
                                                        maxWidth: '100%',
                                                        "textAlign": "left",
                                                    }}
                                                >
                                                    <label for="language-select">Language:</label><br />
                                                    <select className='selectBox' name="language_code" id="language-select" value={data.language_code}
                                                        onChange={handleDataChange} {...(state ? { disabled: true } : {})}
                                                        required>
                                                        <option value="">Please choose Language</option>
                                                        <option value="en" selected={data.language_code === "en"}>English </option>
                                                        <option value="en_UN" selected={data.language_code === "en_UN"}>English (US)</option>
                                                        <option value="en_GB" selected={data.language_code === "en_GB"}>English (UK)</option>
                                                    </select>
                                                    <label className='errormsg'>Select Template Language</label>
                                                </Box>

                                            </Item>
                                        </Grid>
                                    </Grid>
                                </div>
                            </Item>
                            {/* Header Section */}
                            <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }} mt={3}>
                                <Grid item xs={12}>
                                    <Item className='griditems'>
                                        <Box style={{ "padding": "10px 0px 0px 17px", }}>
                                            <h2 className='formheading'>Header</h2>
                                            <p style={{ "color": "#939393", "fontSize": "10px", "textAlign": "left", "margin-top": "2px", }}>Add a message header to show in message in top</p>
                                        </Box>
                                        <div style={{ "padding": "0 9px 10px", }}>
                                            <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
                                                <Grid item xs={12}>
                                                    <Item className='inneritems'>
                                                        <Box
                                                            sx={{
                                                                maxWidth: '100%',
                                                                "textAlign": "left",
                                                            }}
                                                        >
                                                            <label for="Category-select">Header Type</label><br />
                                                            <select
                                                                className='selectBox'
                                                                name="header_type"
                                                                id="Category-select"
                                                                style={{ "width": "50%" }}
                                                                value={selectedCategory.header_type}
                                                                onChange={handleCategoryChange}
                                                            >
                                                                <option value="">None</option>
                                                                <option value="TEXT" selected={selectedCategory.header_type === "TEXT"}>Text</option>
                                                                <option value="Media" selected={["DOCUMENT", "VIDEO", "IMAGE", "LOCATION"].includes(selectedCategory.header_type)}>Media</option>
                                                            </select>
                                                            {selectedCategory.header_type === 'TEXT' && (
                                                                <input id="tac" type="text" name="header_text" placeholder='Header Text' className='selectBox' style={{ "width": "48%", "marginLeft": "9px", }}
                                                                    value={selectedCategory.header_text}
                                                                    onChange={handleCategoryChange}
                                                                />
                                                            )}

                                                            {selectedCategory.header_type !== 'TEXT' && selectedCategory.header_type !== '' && (
                                                                <Grid container spacing={2} columns={12} mt={2}>
                                                                    <Grid item xs={6}>
                                                                        <Item className='inneritems' style={{ "text-align": "left", }}>
                                                                            <RadioGroup name="use-radio-group" value={selectedMediaOption} onChange={handleMediaOptionChange}>
                                                                                <Box style={{ "position": "relative" }}>
                                                                                    {selectedMediaOption === 'Image' ? (
                                                                                        <img src='/png.png' style={{ "margin": "19px 22px 0 22px", "position": "absolute" }} alt="4th Option" />
                                                                                    ) : (
                                                                                        <img src='/images.png' style={{ "margin": "19px 22px 0 22px", "position": "absolute" }} alt="4th Option" />
                                                                                    )}
                                                                                    <FormControlLabel className='RadioBox' value="Image" label="Choose Images" labelPlacement="start" control={<Radio />}
                                                                                        style={{
                                                                                            color: selectedMediaOption === 'Image' ? 'blue' : 'black',
                                                                                            backgroundColor: selectedMediaOption === 'Image' ? '#FAFDFF' : '#fff',
                                                                                            border: selectedMediaOption === 'Image' ? '2px solid #0889FF' : '1px solid #E4E4E4',
                                                                                        }}
                                                                                    />
                                                                                </Box>
                                                                            </RadioGroup>
                                                                        </Item>
                                                                    </Grid>
                                                                    <Grid item xs={6}>
                                                                        <Item className='inneritems'>
                                                                            <RadioGroup name="use-radio-group" value={selectedMediaOption} onChange={handleMediaOptionChange}>
                                                                                <Box style={{ "position": "relative" }}>
                                                                                    {selectedMediaOption === 'Video' ? (
                                                                                        <img src='/activevideo.png' style={{ "margin": "19px 22px 0 22px", "position": "absolute" }} alt="4th Option" />
                                                                                    ) : (
                                                                                        <img src='/video.png' style={{ "margin": "19px 22px 0 22px", "position": "absolute" }} alt="4th Option" />
                                                                                    )}
                                                                                    <FormControlLabel className='RadioBox' value="Video" label="Choose Video" labelPlacement="start" control={<Radio />}
                                                                                        style={{
                                                                                            color: selectedMediaOption === 'Video' ? 'blue' : 'black',
                                                                                            backgroundColor: selectedMediaOption === 'Video' ? '#FAFDFF' : '#fff',
                                                                                            border: selectedMediaOption === 'Video' ? '2px solid #0889FF' : '1px solid #E4E4E4',
                                                                                        }}
                                                                                    />
                                                                                </Box>
                                                                            </RadioGroup>
                                                                        </Item>
                                                                    </Grid>
                                                                    <Grid item xs={6}>
                                                                        <Item className='inneritems'>
                                                                            <RadioGroup name="use-radio-group" value={selectedMediaOption} onChange={handleMediaOptionChange}>
                                                                                <Box style={{ "position": "relative" }}>
                                                                                    {selectedMediaOption === 'Document' ? (
                                                                                        <img src='/activedocument.png' style={{ "margin": "19px 22px 0 22px", "position": "absolute" }} alt="4th Option" />
                                                                                    ) : (
                                                                                        <img src='/document.png' style={{ "margin": "19px 22px 0 22px", "position": "absolute" }} alt="4th Option" />
                                                                                    )}
                                                                                    <FormControlLabel className='RadioBox' value="Document" label="Choose Document" labelPlacement="start" control={<Radio />}
                                                                                        style={{
                                                                                            color: selectedMediaOption === 'Document' ? 'blue' : 'black',
                                                                                            backgroundColor: selectedMediaOption === 'Document' ? '#FAFDFF' : '#fff',
                                                                                            border: selectedMediaOption === 'Document' ? '2px solid #0889FF' : '1px solid #E4E4E4',
                                                                                        }}
                                                                                    />
                                                                                </Box>
                                                                            </RadioGroup>
                                                                        </Item>
                                                                    </Grid>
                                                                    <Grid item xs={6}>
                                                                        <Item className='inneritems'>
                                                                            <RadioGroup name="use-radio-group" value={selectedMediaOption} onChange={handleMediaOptionChange}>
                                                                                <Box style={{ "position": "relative" }}>
                                                                                    {selectedMediaOption === 'Location' ? (
                                                                                        <img src='/activelocation.png' style={{ "margin": "19px 22px 0 22px", "position": "absolute" }} alt="4th Option" />
                                                                                    ) : (
                                                                                        <img src='/location.png' style={{ "margin": "19px 22px 0 22px", "position": "absolute" }} alt="4th Option" />
                                                                                    )}
                                                                                    <FormControlLabel className='RadioBox' value="Location" label="Choose Location" labelPlacement="start" control={<Radio />}
                                                                                        style={{
                                                                                            color: selectedMediaOption === 'Location' ? 'blue' : 'black',
                                                                                            backgroundColor: selectedMediaOption === 'Location' ? '#FAFDFF' : '#fff',
                                                                                            border: selectedMediaOption === 'Location' ? '2px solid #0889FF' : '1px solid #E4E4E4',
                                                                                        }}
                                                                                    />
                                                                                </Box>
                                                                            </RadioGroup>
                                                                        </Item>
                                                                    </Grid>
                                                                    {selectedMediaOption === 'Image' && (
                                                                        <div style={{ "margin-left": "26px" }}>
                                                                            <Box className='SelectImagesection'>
                                                                                <Input
                                                                                    key={inputKey}
                                                                                    type="file"
                                                                                    inputProps={{ accept: 'image/jpg, image/png' }}
                                                                                    onChange={handleImageChange}

                                                                                    style={{ display: 'none' }}
                                                                                    id="image-upload-input"
                                                                                />
                                                                                <p style={{ "font-size": "16px", "font-weight": "500", }}>Upload Image</p>
                                                                                <div style={{ "display": "flex", }}>
                                                                                    <label htmlFor="image-upload-input">
                                                                                        <Button variant="contained" component="span" className='ImagesBtn'>
                                                                                            Choose Image
                                                                                        </Button>
                                                                                    </label>
                                                                                    {selectedImage && (
                                                                                        <div>
                                                                                            <Button className='RemoveBtn' variant="contained" color="secondary" onClick={handleRemoveImage}>
                                                                                                Remove Image
                                                                                            </Button>
                                                                                        </div>
                                                                                    )}
                                                                                </div>
                                                                            </Box>
                                                                        </div>
                                                                    )}
                                                                    {selectedMediaOption === 'Video' && (
                                                                        <div style={{ "margin-left": "26px" }}>
                                                                            <Box className='SelectImagesection'>
                                                                                <Input
                                                                                    key={inputKey2}
                                                                                    type="file"
                                                                                    inputProps={{ accept: 'video/*' }}
                                                                                    onChange={handleVideoChange}

                                                                                    style={{ display: 'none' }}
                                                                                    id="video-upload-input"
                                                                                />
                                                                                <p style={{ "font-size": "16px", "font-weight": "500", }}>Upload Vieo</p>
                                                                                <div style={{ "display": "flex", }}>
                                                                                    <label htmlFor="video-upload-input">
                                                                                        <Button variant="contained" component="span" className='ImagesBtn'>
                                                                                            Choose Video
                                                                                        </Button>
                                                                                    </label>
                                                                                    {selectedVideo && (
                                                                                        <div>
                                                                                            <Button className='RemoveBtn' variant="contained" color="secondary" onClick={handleRemoveVideo}>
                                                                                                Remove Video
                                                                                            </Button>
                                                                                        </div>
                                                                                    )}
                                                                                </div>
                                                                            </Box>
                                                                        </div>
                                                                    )}
                                                                    {selectedMediaOption === 'Document' && (
                                                                        <div style={{ "margin-left": "26px" }}>
                                                                            <Box className='SelectImagesection'>
                                                                                <Input
                                                                                    key={inputdocumentKey}
                                                                                    type="file"
                                                                                    inputProps={{ accept: '.pdf' }}
                                                                                    onChange={handleFileChange}
                                                                                    style={{ display: 'none' }}
                                                                                    id="document-upload-input"
                                                                                />
                                                                                <p style={{ "font-size": "16px", "font-weight": "500", }}>Upload Document</p>
                                                                                <div style={{ "display": "flex", }}>
                                                                                    <label htmlFor="document-upload-input">
                                                                                        <Button variant="contained" component="span" className='ImagesBtn'>
                                                                                            Choose Document
                                                                                        </Button>
                                                                                    </label>
                                                                                    {selectedDocument && (
                                                                                        <Button className='RemoveBtn' variant="outlined" onClick={handleRemoveDocument}>
                                                                                            Remove Document
                                                                                        </Button>
                                                                                    )}
                                                                                </div>
                                                                            </Box>
                                                                        </div>
                                                                    )}
                                                                </Grid>
                                                            )}
                                                        </Box>
                                                    </Item>
                                                </Grid>
                                            </Grid>
                                        </div>
                                    </Item>
                                </Grid>
                            </Grid>
                            <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }} mt={3}>
                                <Grid item xs={12}>
                                    <Item className='griditems'>
                                        <Box style={{ "padding": "10px 0px 0px 17px", }}>
                                            <h2 className='formheading'>Body</h2>
                                            <p style={{ "color": "#939393", "fontSize": "10px", "textAlign": "left", "margin-top": "2px", "marginBottom": "0px", }}>Add a message header to show in message in top</p>
                                            <Button className='addInput' variant="contained" color="primary" onClick={addInputField}>
                                                <img src='/add.png' />
                                            </Button>
                                        </Box>
                                        <div style={{ "padding": "0 9px 10px", }}>
                                            <textarea className='textareafield' rows="8" name='body_text' placeholder='None'
                                                onChange={handleDataChange} value={data.body_text} required
                                            >
                                            </textarea>
                                            <label className='errormsg' style={{ "width": "100%", "textAlign": "left", "display": "block", }}>Enter body content HTML not allowed</label>
                                        </div>
                                        <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
                                            <Grid item xs={12}>
                                                <Item className='inneritems'>
                                                    <Grid container spacing={2}>
                                                        {inputFields.map((value, index) => (
                                                            <Grid item xs={6} key={index} sx={{ "marginBottom": "8px", }}>
                                                                <label for="Category-select"
                                                                    style={{
                                                                        "text-align": "left",
                                                                        "width": "100%",
                                                                        "display": "block",
                                                                    }}
                                                                >Sample text for variable {index + 1}</label>
                                                                <input
                                                                    label={`Input Field ${index + 1}`}
                                                                    variant="outlined"
                                                                    fullWidth
                                                                    value={value}
                                                                    onChange={(e) => handleInputChange(index, e.target.value)}
                                                                    className='inputfield' type="text"
                                                                    placeholder={`Sample text for variable ${index + 1}`}
                                                                />
                                                            </Grid>
                                                        ))}
                                                    </Grid>
                                                </Item>
                                            </Grid>
                                        </Grid>
                                    </Item>
                                </Grid>
                            </Grid>
                            <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }} mt={3}>
                                <Grid item xs={12}>
                                    <Item className='griditems' sx={{ "height": "16em", }}>
                                        <Box style={{ "padding": "10px 0px 0px 17px", }}>
                                            <h2 className='formheading'>Footer</h2>
                                            <p style={{ "color": "#939393", "fontSize": "10px", "textAlign": "left", "margin-top": "2px", }}>Add a message header to show in message in top</p>
                                        </Box>
                                        <div style={{ "padding": "0 9px 10px", }}>
                                            <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
                                                <Grid item xs={12}>
                                                    <Item className='inneritems'>
                                                        <Box
                                                            sx={{
                                                                maxWidth: '100%',
                                                                "textAlign": "left",
                                                            }}
                                                        >
                                                            <label for="Category-select">Footer Text</label><br />
                                                            <input id="tac" className='inputfield' type="text" name="footer_text" placeholder='Enter Footer Text'
                                                                onChange={handleDataChange} value={data.footer_text}
                                                            />
                                                            <label className='errormsg' style={{ "width": "100%", "textAlign": "left", "display": "block", }}>Enter footer Text</label>
                                                        </Box>
                                                    </Item>
                                                </Grid>
                                            </Grid>
                                        </div>
                                    </Item>
                                </Grid>
                            </Grid>
                            <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }} mt={3}>
                                <Grid item xs={12}>
                                    <Item className='griditems'>
                                        <Box style={{ "padding": "10px 0px 0px 17px", }}>
                                            <h2 className='formheading'>Button</h2>
                                            <p style={{ "color": "#939393", "fontSize": "10px", "textAlign": "left", "margin-top": "2px", }}>Add a message header to show in message in top</p>
                                        </Box>
                                        <div style={{ "padding": "0 9px 10px", }}>
                                            <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
                                                <Grid item xs={12}>
                                                    <Item className='inneritems'>
                                                        <Box
                                                            sx={{
                                                                maxWidth: '100%',
                                                                "textAlign": "left",
                                                            }}
                                                        >
                                                            <div>
                                                                <Box className='recommendBox'>
                                                                    <Stack direction="row" spacing={2}>
                                                                        <img src='/lightbulb.png' height={17} /><span><h4 style={{ "margin": "0px 0px 0px 0", "font-size": "17px", "font-weight": "500", }}>We recommend adding the marketing opt-out Buttons.</h4></span>
                                                                    </Stack>
                                                                    <p style={{ "margin-left": "30px", "margin-bottom": "0", }}>Allow customers to request to opt out of all marketing messages. this an help reduce blocks from customers and increase your quality rating. <a href='#' style={{ "textDecoration": "none", "color": "blue", "font-weight": "500", }}>Learn more.</a></p>
                                                                </Box>
                                                            </div>
                                                        </Box>
                                                    </Item>
                                                </Grid>
                                            </Grid>
                                            <Box sx={{ flexGrow: 1 }}>
                                                <Grid container spacing={2} columns={12}>
                                                    <Grid item xs={12} className=''>
                                                        <Item className='inneritems'>
                                                            <Box
                                                                sx={{
                                                                    maxWidth: '100%',
                                                                    textAlign: 'left',
                                                                }}
                                                            >
                                                                <div>
                                                                    <Button
                                                                        aria-controls="simple-menu"
                                                                        aria-haspopup="true"
                                                                        onClick={handleClick}
                                                                        variant="contained"
                                                                        className="Buttontype"
                                                                    >
                                                                        Add a Button
                                                                    </Button>
                                                                    <Menu
                                                                        id="simple-menu"
                                                                        anchorEl={anchorEl}
                                                                        open={Boolean(anchorEl)}
                                                                        onClose={handleClose}
                                                                    >
                                                                        <Typography style={{ "padding": "6px 13px", "font-size": "16px", "font-weight": "500", }}>Quick reply buttons</Typography>
                                                                        <MenuItem onClick={() => handlesecondMenuItemClick('QUICK_REPLY')} className='custom' sx={{ "display": "grid" }}>Custom</MenuItem>
                                                                        <Divider />
                                                                        <Typography style={{ "padding": "6px 13px", "font-size": "16px", "font-weight": "500", }}>
                                                                            Call-to-action buttons
                                                                        </Typography>
                                                                        <MenuItem onClick={() => handleVistiWebsiteClick('URL')} sx={{ "display": "grid" }} disabled={clickCount >= 2}>Visit Website
                                                                            <span style={{ "fontSize": "12px", }}>2 Buttons maximum</span>
                                                                        </MenuItem>
                                                                        <MenuItem onClick={() => handleMenuItem2Click('PHONE_NUMBER')} sx={{
                                                                            "display": "grid", cursor: disabledItems.includes('PHONE_NUMBER') ? 'not-allowed' : 'pointer',
                                                                            opacity: disabledItems.includes('PHONE_NUMBER') ? 0.5 : 1,
                                                                            pointerEvents: disabledItems.includes('PHONE_NUMBER') ? 'none' : 'auto',
                                                                        }}>Call Phone Number
                                                                            <span style={{ "fontSize": "12px", }}>1 Buttons maximum</span>
                                                                        </MenuItem>
                                                                        <MenuItem onClick={() => handleMenuItem2Click('COPY_CODE')} sx={{
                                                                            "display": "grid", cursor: disabledItems.includes('COPY_CODE') ? 'not-allowed' : 'pointer',
                                                                            opacity: disabledItems.includes('COPY_CODE') ? 0.5 : 1,
                                                                            pointerEvents: disabledItems.includes('COPY_CODE') ? 'none' : 'auto',
                                                                        }}>Copy offer code
                                                                            <span style={{ "fontSize": "12px", }}>1 Buttons maximum</span>
                                                                        </MenuItem>
                                                                    </Menu>
                                                                </div>
                                                                <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }} mt={1}>
                                                                    <Grid item xs={12}>
                                                                        <Item className='inneritems'>
                                                                            <Box
                                                                                sx={{
                                                                                    maxWidth: '100%',
                                                                                    "textAlign": "left",
                                                                                }}
                                                                            >
                                                                                <Stack direction="row" spacing={2}>
                                                                                    <img src='/lightbulb.png' height={17} />
                                                                                    <p>if you add more than three buttons, they will appear in a list</p>
                                                                                </Stack>
                                                                            </Box>
                                                                        </Item>
                                                                    </Grid>
                                                                </Grid>
                                                                {isButtontypeBoxCreated && (
                                                                    <Box className='ButtontypeBox'>
                                                                        <Stack direction="row" spacing={1}>
                                                                            <img src='/arrows.png' width={23} />
                                                                            <Typography className='Buttonheading'>Quick reply buttons</Typography>
                                                                        </Stack>
                                                                        {selectedItems.map((item, index) => (
                                                                            <Box key={index} className='Marketingitembox' sx={{ flexGrow: 1 }}>
                                                                                {selectedItems[index].buttonType === 'Item 1' && (
                                                                                    <>
                                                                                        <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 1 }}>
                                                                                            <Grid item xs={4}>
                                                                                                <Item className='inneritems' sx={{ "textAlign": "left" }}>
                                                                                                    <label for="Type-select" style={{ "fontWeight": "600", color: "#000" }}>Type</label><br />
                                                                                                    <input id="tac" className='inputfield' type="text" name="terms-and-conditions" placeholder='Markting opt-out' />
                                                                                                </Item>
                                                                                            </Grid>
                                                                                            <Grid item xs={8}>
                                                                                                <Item className='inneritems' sx={{ "textAlign": "left" }}>
                                                                                                    <label for="Category-select" style={{ "fontWeight": "600", color: "#000" }}>Button Text</label><br />
                                                                                                    <input id="tac" className='inputfield' type="text" name="terms-and-conditions" placeholder='Stop Promotions' maxLength='25' />
                                                                                                    <label className='inputlenght' style={{ "width": "100%", "display": "block", }}>12/25</label>
                                                                                                </Item>
                                                                                            </Grid>
                                                                                            <Grid item xs={12}>
                                                                                                <Item className='inneritems' sx={{ "textAlign": "left", "padding": "0px" }}>
                                                                                                    <Stack direction='row' spacing={1}>
                                                                                                        <Checkbox {...label} />
                                                                                                        <p style={{ "font-size": "12px", "font-weight": "500", "marginTop": "13px", marginLeft: 0, }}>I understand that it's Test Whatsapp Bussiness Account's responsibility to stop sending marketing message to customers who opt out.</p>
                                                                                                    </Stack>
                                                                                                </Item>
                                                                                            </Grid>

                                                                                        </Grid>
                                                                                        <IconButton onClick={() => handleDeleteButtonClick(item, 'Buttontype', index)}>
                                                                                            <DeleteIcon />
                                                                                        </IconButton>
                                                                                    </>
                                                                                )}
                                                                                {selectedItems[index].button_type === 'QUICK_REPLY' && (
                                                                                    <>
                                                                                        {getItem2Content(selectedItems[index], index)}
                                                                                        <IconButton onClick={() => handleDeleteButtonClick(selectedItems[index].buttonType, 'Buttontype', index)}>
                                                                                            <DeleteIcon />
                                                                                        </IconButton>
                                                                                    </>
                                                                                )}

                                                                            </Box>
                                                                        ))}
                                                                    </Box>
                                                                )}
                                                                {isBoxCreated && (
                                                                    <Box className='ButtontypeBox'>
                                                                        <Stack direction="row" spacing={1}>
                                                                            <img src='/arrows.png' width={23} />
                                                                            <Typography className='Buttonheading'>Call-to-action buttons</Typography>
                                                                        </Stack>
                                                                        {selectedItems2.map((item, index) => (
                                                                            <Box key={index} className='Marketingitembox' sx={{ flexGrow: 1 }}>
                                                                                {item.button_type === 'URL' && (
                                                                                    <>
                                                                                        {getItem3Content(item, index)}
                                                                                        <IconButton onClick={() => handleDeleteButtonClick(item.button_type, 'CallToAction', index)}>
                                                                                            <DeleteIcon />
                                                                                        </IconButton>
                                                                                    </>
                                                                                )}
                                                                                {item.button_type === 'PHONE_NUMBER' && (
                                                                                    <>
                                                                                        <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 1 }} columns={16}>
                                                                                            <Grid item xs={0}>
                                                                                                <Item className='inneritems' sx={{ "textAlign": "left" }}>
                                                                                                    <select hidden className='selectBox' name="button_type" id="Type-select" style={{ "padding": "0px 0px 0px 6px", height: "30px", }}
                                                                                                        onChange={(event) => handlebuttonDataChange(event, index, item.button_type)}
                                                                                                    >
                                                                                                        <option value="Call Phone Number">Call Phone Number</option>
                                                                                                    </select>
                                                                                                </Item>
                                                                                            </Grid>
                                                                                            <Grid item xs={6}>
                                                                                                <Item className='inneritems' sx={{ "textAlign": "left" }}>
                                                                                                    <label for="Category-select" style={{ "fontWeight": "600", color: "#000" }}>Button Text</label><br />
                                                                                                    <input id="tac" className='inputfield' type="text" name="button_text" placeholder='Button Text'
                                                                                                        onChange={(event) => handlebuttonDataChange(event, index, item.button_type)} maxLength={25} value={item.button_text} required
                                                                                                    />
                                                                                                </Item>
                                                                                            </Grid>
                                                                                            <Grid item xs={7}>
                                                                                                <Item className='inneritems' sx={{ "textAlign": "left" }}>
                                                                                                    <label for="Category-select" style={{ "fontWeight": "600", color: "#000" }}>Phone Number</label><br />
                                                                                                    <input id="tac" className='inputfield' type="text" name="button_action" placeholder='Enter number'
                                                                                                        onChange={(event) => handlebuttonDataChange(event, index, item.button_type)} maxLength={12} value={item.button_action} required
                                                                                                    />
                                                                                                </Item>
                                                                                            </Grid>
                                                                                        </Grid>
                                                                                        <IconButton onClick={() => handleDeleteButtonClick(item.button_type, 'CallToAction', index)}>
                                                                                            <DeleteIcon />
                                                                                        </IconButton>
                                                                                    </>
                                                                                )}
                                                                                {item.button_type === 'COPY_CODE' && (
                                                                                    <>
                                                                                        <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 1 }} columns={16}>
                                                                                            <Grid item xs={0}>
                                                                                                <Item className='inneritems' sx={{ "textAlign": "left" }}>
                                                                                                    <select hidden className='selectBox' name="button_type" id="Type-select" style={{ "padding": "0px 0px 0px 6px", height: "30px", }}
                                                                                                        onChange={(event) => handlebuttonDataChange(event, index, item)}
                                                                                                    >
                                                                                                        <option value="Copy Offer Code">Copy Offer Code</option>
                                                                                                    </select>
                                                                                                </Item>
                                                                                            </Grid>
                                                                                            <Grid item xs={6}>
                                                                                                <Item className='inneritems' sx={{ "textAlign": "left" }}>
                                                                                                    <label for="Category-select" style={{ "fontWeight": "600", color: "#000" }}>Button Text</label><br />
                                                                                                    <input id="tac" className='inputfield' type="text" name="button_text" placeholder='Copy Offer Code' maxLength={25}
                                                                                                        onChange={(event) => handlebuttonDataChange(event, index, item.button_type)} value={item.button_text} required
                                                                                                    />
                                                                                                </Item>
                                                                                            </Grid>
                                                                                            <Grid item xs={6}>
                                                                                                <Item className='inneritems' sx={{ "textAlign": "left" }}>
                                                                                                    <label for="Type-select" style={{ "fontWeight": "600", color: "#000" }}>Offer code</label><br />
                                                                                                    <input id="tac" className='inputfield' type="text" name="button_action" placeholder='Enter sample' maxLength={15}
                                                                                                        onChange={(event) => handlebuttonDataChange(event, index, item.button_type)} value={item.button_action} required
                                                                                                    />
                                                                                                </Item>
                                                                                            </Grid>
                                                                                        </Grid>

                                                                                        <IconButton onClick={() => handleDeleteButtonClick(item.button_type, 'CallToAction', index)}>
                                                                                            <DeleteIcon />
                                                                                        </IconButton>
                                                                                    </>
                                                                                )}
                                                                            </Box>
                                                                        ))}
                                                                    </Box>
                                                                )}
                                                            </Box>
                                                        </Item>
                                                    </Grid>
                                                </Grid>
                                            </Box>
                                        </div>
                                    </Item>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item xs={4}>
                            <Item className='actionpreview'>
                                <div className='actionBox'>
                                    <Box style={{ "padding": "10px 0px 0px 17px", }}>
                                        <h2 className='formheading'>Action</h2>
                                    </Box>
                                    <div style={{ "padding": "16px 10px", }}>
                                        <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
                                            <Grid item xs={12}>
                                                <Item className='inneritems'>
                                                    <p style={{ "fontSize": "10px", "color": "#9E9E9E", "textAlign": "left", "margin": "0px" }} >Status</p>
                                                </Item>
                                            </Grid>
                                        </Grid>
                                        <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
                                            <Grid item xs={3}>
                                                <Item className='inneritems'>
                                                    <Box style={{
                                                        "font-size": "12px",
                                                        "background": colorPending,
                                                        "border-radius": "64px",
                                                        "text-align": "center",
                                                        "width": "72px",
                                                        "height": "26px",
                                                        "font-weight": "500",
                                                        "display": "flex",
                                                        "align-items": "center",
                                                        "justify-content": "center",

                                                    }}><p style={{ "margin": "0px", }}>Pending</p>
                                                    </Box>
                                                </Item>
                                            </Grid>
                                            <Grid item xs={3}>
                                                <Item className='inneritems'>
                                                    <Box style={{
                                                        "font-size": "12px",
                                                        "background": colorApproved,
                                                        "border-radius": "64px",
                                                        "text-align": "center",
                                                        "width": "76px",
                                                        "height": "26px",
                                                        "font-weight": "500",
                                                        "display": "flex",
                                                        "align-items": "center",
                                                        "justify-content": "center",

                                                    }}><p style={{ "margin": "0px", }}>Approved</p>
                                                    </Box>
                                                </Item>
                                            </Grid>
                                            <Grid item xs={3}>
                                                <Item className='inneritems'>
                                                    <Box style={{
                                                        "font-size": "12px",
                                                        "background": colorRejected,
                                                        "border-radius": "64px",
                                                        "text-align": "center",
                                                        "width": "86px",
                                                        "height": "26px",
                                                        "font-weight": "500",
                                                        "display": "flex",
                                                        "align-items": "center",
                                                        "justify-content": "center",

                                                    }}><p style={{ "margin": "0px", }}>Rejected</p>
                                                    </Box>
                                                </Item>
                                            </Grid>
                                        </Grid>
                                        <Divider variant="middle" style={{ "margin": "14px 0" }} />
                                        <Grid container spacing={2}>
                                            <Grid item xs={12}>
                                                <Item className={`inneritems ${Helpers.cmAccess('TEMPLATES_EDIT_SUBMIT')}`}>
                                                    <Button
                                                        className={`approvelbtn`} variant="contained" type='submit' >Save for approval</Button>
                                                </Item>
                                            </Grid>
                                        </Grid>
                                    </div>
                                </div>
                                <div className='previewBox' style={{ "padding": " 19px 16px", }}>
                                    <Box>

                                        <Card sx={{ maxWidth: "100%", "background": "#F1F8F7", "padding": "19px 16px", }}>
                                            {selectedCategory.header_type === 'TEXT' ?
                                                <Typography variant="body2" color="text.secondary" sx={{
                                                    "textAlign": "left",
                                                    "font-weight": "600",
                                                }}>
                                                    {selectedCategory.header_text}
                                                </Typography> :
                                                <Box style={{
                                                    "background-color": "#D3E8E5", "border-top-right-radius": "21px", "border-top-left-radius": "21px", "height": "150px", "align-items": "center", "display": " flex", "justify-content": "center",
                                                }}>
                                                    <CardMedia
                                                        sx={{}}
                                                        title="green iguana"
                                                    >
                                                        {selectedMediaOption === 'Image' && selectedImage && (
                                                            <div>
                                                                <img
                                                                    src={URL.createObjectURL(selectedImage)}
                                                                    alt="Selected Preview"
                                                                    style={{ width: "100px", marginTop: "9px", }}
                                                                />
                                                            </div>
                                                        )}
                                                        {selectedMediaOption === 'Video' && selectedVideo && (
                                                            <div>
                                                                <video controls width="200">
                                                                    <source src={selectedVideo} type="video/mp4" />
                                                                    Your browser does not support the video tag.
                                                                </video>
                                                            </div>
                                                        )}
                                                        {selectedMediaOption === 'Document' && selectedDocument && (
                                                            <Typography variant="body1">{selectedDocument.name}</Typography>
                                                        )}
                                                    </CardMedia>
                                                </Box>
                                            }
                                            <CardContent>
                                                <Typography type="text" display={'ruby-text-container'}>{bodyContent}</Typography>
                                                <Typography variant="body2" color="text.secondary" sx={{
                                                    "textAlign": "left",
                                                    "font-weight": "600",
                                                }}>
                                                    {chngebodytext(data.body_text)}
                                                </Typography>
                                                <Typography variant="body2" color="text.secondary" sx={{
                                                    "textAlign": "left",
                                                    "font-weight": "600",
                                                }}>
                                                    {data.footer_text}
                                                </Typography>
                                            </CardContent>
                                            <CardActions>
                                                {data.button.map((buttonInfo, index) => (
                                                    <div key={index}>
                                                        <Button
                                                            fullWidth
                                                            style={{ marginTop: '0px', textTransform: "capitalize", "borderTop": "1px solid #ddd", "borderRadius": "0 !important" }}
                                                        > {buttonInfo.button_text}</Button>
                                                    </div>
                                                ))}
                                            </CardActions>
                                        </Card>
                                    </Box>
                                </div>
                            </Item>
                        </Grid>
                    </Grid>
                </form>
            </div>
        </>
    )
}

