import * as React from 'react';
import { styled, alpha } from '@mui/material/styles';
import Paper from '@mui/material/Paper';
import { Typography, Grid, Box, Button, Toolbar, IconButton, Stack, } from '@mui/material';

const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
    ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: 'center',
    color: theme.palette.text.secondary,
}));

export default function Addcontact() {
    return (
        <>
            <div style={{
                "background": "#fff",
                "margin": "24px 0px 0px",
                "border-radius": "9px",
                "box-shadow": "0px 3px 1px 0px #EBEBEB",
                "padding": "24px",
            }}>
                <Box sx={{ width: '100%' }}>
                    <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
                        <Grid item xs={6}>
                            <Item className='inneritems'>
                                <h4 className='Templateheading2'>Add Contacts</h4>
                            </Item>
                        </Grid>
                    </Grid>
                </Box>
                <Box sx={{ width: '100%' }}>
                    <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
                        <Grid item xs={4}>
                            <Item className='inneritems'>
                                <Box
                                    sx={{
                                        maxWidth: '100%',
                                        "textAlign": "left",
                                    }}
                                >
                                    <label for="tac" className='inputlabel'>
                                        First Name<br />
                                        <input id="tac" className='inputfield' type="text" name="terms-and-conditions" placeholder='john' />
                                    </label>
                                </Box>
                            </Item>
                        </Grid>
                        <Grid item xs={4}>
                            <Item className='inneritems'>
                                <Box
                                    sx={{
                                        maxWidth: '100%',
                                        "textAlign": "left",
                                    }}
                                >
                                    <label for="tac" className='inputlabel'>
                                        Last Name<br />
                                        <input id="tac" className='inputfield' type="text" name="terms-and-conditions" placeholder='dumy' />
                                    </label>
                                </Box>
                            </Item>
                        </Grid>
                        <Grid item xs={4}>
                            <Item className='inneritems'>
                                <Box
                                    sx={{
                                        maxWidth: '100%',
                                        "textAlign": "left",
                                    }}
                                >
                                    <label for="tac" className='inputlabel'>
                                        Whats app number<br />
                                        <input id="tac" className='inputfield' type="text" name="terms-and-conditions" placeholder='Whats app number' />
                                    </label>
                                    <label className='errormsg'>Whats app number with country code.</label>

                                </Box>
                            </Item>
                        </Grid>
                    </Grid>
                </Box>
                <Box sx={{ width: '100%' }}>
                    <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
                        <Grid item xs={4}>
                            <Item className='inneritems'>
                                <Box
                                    sx={{
                                        maxWidth: '100%',
                                        "textAlign": "left",
                                    }}
                                >
                                    <label for="Category-select">Status</label><br />
                                    <select className='selectBox' name="Status" id="Status-select">
                                        <option value="">Select Status</option>
                                        <option value="Subscribed">Subscribed</option>
                                        <option value="Unsubscribed">Unsubscribed</option>
                                    </select>
                                    <label className='errormsg'>Notification are sent o only subscribed contacts.</label>
                                </Box>
                            </Item>
                        </Grid>
                    </Grid>
                </Box>
                <Box sx={{ width: '100%' }}>
                    <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
                        <Grid item xs={4}>
                            <Item className='inneritems'>
                                <Stack direction="row" spacing={2}>
                                    <Button variant="outlined" className='SaveBtn'>
                                        Save
                                    </Button>
                                    <Button variant="outlined" className='CancelBtn'>
                                        Cancel
                                    </Button>
                                </Stack>
                            </Item>
                        </Grid>
                    </Grid>
                </Box>
            </div>
        </>
    )
}