import belNotifier from "../axios/axios";


class AuthService {

    login(data) {
        return Promise.resolve(belNotifier.post('login', data))
    }
    forgotpass(data) {
        return Promise.resolve(belNotifier.post(`password`, data));
    }
    logout(data) {
        return Promise.resolve(belNotifier.post(`logout`, data));
    }
    
}

export default AuthService;
