import belNotifier from "../axios/axios";
// import institute from "../axios/axios";

let institute = "";
if (localStorage.getItem('institution_id')) {
    institute = JSON.parse(localStorage.getItem('institution_id'));
}
var whatsappNumberId = 1;
// if (localStorage.getItem('selectedWhatsappNumberId')) {
//     var whatsappNumberId = JSON.parse(localStorage.getItem('selectedWhatsappNumberId'));
// }
class UserService {

    template(data) {
        return Promise.resolve(belNotifier.post(`template?institution_id=${institute}&whatsapp_no_id=${whatsappNumberId}`, data))
    }
    updatetemplate(data,id) {
        return Promise.resolve(belNotifier.post(`template/${id}?institution_id=${institute}&whatsapp_no_id=${whatsappNumberId}`, data))
    }
    gettemplatebyId(id) {
        return Promise.resolve(belNotifier.get(`/getTemplate/${id}?institution_id=${institute}&whatsapp_no_id=${whatsappNumberId}`))
    }
    addcontact(data) {
        return Promise.resolve(belNotifier.post(`addManualContact?institution_id=${institute}&whatsapp_no_id=${whatsappNumberId}`, data))
    }
    contact(data) {
        return Promise.resolve(belNotifier.get(`getContact?institution_id=${institute}&whatsapp_no_id=${whatsappNumberId}`, data))
    }
    updateUser(data,id) {
        return Promise.resolve(belNotifier.post(`register/${id}?institution_id=${institute}`, data));
    }
    Groupcontact(id) {
        return Promise.resolve(belNotifier.get(`/getContact?group_id=${id}&institution_id=${institute}&whatsapp_no_id=${whatsappNumberId}`))
    }
    Updatecontact(id, data) {
        return Promise.resolve(belNotifier.post(`/addManualContact/${id}?institution_id=${institute}&whatsapp_no_id=${whatsappNumberId}`, data))
    }
    sendTemplate(id, data) {
        return Promise.resolve(belNotifier.post(`/sendTemplate/${id}?institution_id=${institute}&whatsapp_no_id=${whatsappNumberId}`, data))
    }

    getReport(id) {
        return Promise.resolve(belNotifier.get(`getReport?institution_id=${institute}&whatsapp_no_id=${whatsappNumberId}`))
    }
    getReportByTemplate(id) {
        return Promise.resolve(belNotifier.get(`getReport/${id}?institution_id=${institute}&whatsapp_no_id=${whatsappNumberId}`))
    }
    getTemplate() {
        return Promise.resolve(belNotifier.get(`getTemplate?institution_id=${institute}&whatsapp_no_id=${whatsappNumberId}`))
    }
    getContacts() {
        return Promise.resolve(belNotifier.get(`getContact?institution_id=${institute}&whatsapp_no_id=${whatsappNumberId}`))
    }
    getTemplateReport() {
        return Promise.resolve(belNotifier.get(`/getReportSendTemplate?institution_id=${institute}&whatsapp_no_id=${whatsappNumberId}`))
    }
    getGroup() {
        return Promise.resolve(belNotifier.get(`/getGroup?institution_id=${institute}&whatsapp_no_id=${whatsappNumberId}`))
    }
    getGroupwithId(id) {
        return Promise.resolve(belNotifier.get(`/getGroup/${id}?institution_id=${institute}&whatsapp_no_id=${whatsappNumberId}`))
    }
    getReportwithdate(from, to) {
        return Promise.resolve(belNotifier.get(`getReport?from=${from}&to=${to}&institution_id=${institute}&whatsapp_no_id=${whatsappNumberId}`))
    }
    getReportByTemplatewithdate(from, to, id) {
        return Promise.resolve(belNotifier.get(`getReport/${id}?from=${from}&to=${to}&institution_id=${institute}&whatsapp_no_id=${whatsappNumberId}`))
    }
    deleteContact(id) {
        return Promise.resolve(belNotifier.get(`deleteContact/${id}?institution_id=${institute}&whatsapp_no_id=${whatsappNumberId}`))
    }
    getReportByContactId(id) {
        return Promise.resolve(belNotifier.get(`getReportByContactId/${id}?institution_id=${institute}&whatsapp_no_id=${whatsappNumberId}`))
    }
    getGroupcontact(id) {
        return Promise.resolve(belNotifier.get(`/getContact?group_id=${id}?institution_id=${institute}&whatsapp_no_id=${whatsappNumberId}`))
    }
    sendMessageIndividual(id, msg) {
        return Promise.resolve(belNotifier.post(`/sendMessageIndividual?contact_id=${id}&message=${msg}&institution_id=${institute}&whatsapp_no_id=${whatsappNumberId}`))
    }
    getReportTemplateWithContact(id) {
        return Promise.resolve(belNotifier.get(`/getReportTemplateWithContact/${id}?institution_id=${institute}&whatsapp_no_id=${whatsappNumberId}`))
    }
    getRolls() {
        return Promise.resolve(belNotifier.get(`/accessRole?institution_id=${institute}&whatsapp_no_id=${whatsappNumberId}`))
    }
    getRoleWiseComponents(id) {
        return Promise.resolve(belNotifier.get(`/getRoleWiseComponents/${id}?institution_id=${institute}&whatsapp_no_id=${whatsappNumberId}`))
    }
    createUpdateRoleAccess(id, data) {
        return Promise.resolve(belNotifier.post(`/createUpdateRoleAccess/${id}?institution_id=${institute}&whatsapp_no_id=${whatsappNumberId}`, data))
    }
    getUsers() {
        return Promise.resolve(belNotifier.get(`/getAllUsers?institution_id=${institute}&whatsapp_no_id=${whatsappNumberId}`))
    }
    getUser(id) {
        return Promise.resolve(belNotifier.get(`/getAllUsers/${id}?institution_id=${institute}&whatsapp_no_id=${whatsappNumberId}`))
    }
    createOrEditGroup(data) {
        return Promise.resolve(belNotifier.post(`/createOrEditGroup?institution_id=${institute}&whatsapp_no_id=${whatsappNumberId}`, data))
    }
    getAssociateWhatsappNumber() {
        return Promise.resolve(belNotifier.get(`/getAssociateWhatsappNumber?institution_id=${institute}&whatsapp_no_id=${whatsappNumberId}`))
    }    
    register(data) {
        return Promise.resolve(belNotifier.post(`register?institution_id=${institute}`, data));
    }
    getAssociateWhatsappNumberwithId(id) {
        return Promise.resolve(belNotifier.get(`/getAssociateWhatsappNumber/${id}?institution_id=${institute}&whatsapp_no_id=${whatsappNumberId}`))
    }
    updateAssociateNumbers(data,id) {
        return Promise.resolve(belNotifier.post(`/storeAssociateNumbers/${id}?institution_id=${institute}&whatsapp_no_id=${whatsappNumberId}`, data))
    }
    ReceiversByTemplate(id) {
        return Promise.resolve(belNotifier.get(`/getReceiversByTemplate/${id}?institution_id=${institute}&whatsapp_no_id=${whatsappNumberId}`))
    }
    deleteTemplate(id) {
        return Promise.resolve(belNotifier.get(`/deleteTemplate/${id}?institution_id=${institute}&whatsapp_no_id=${whatsappNumberId}`))
    }
    uploadContact(data) {
        return Promise.resolve(belNotifier.post(`/excel?institution_id=${institute}&whatsapp_no_id=${whatsappNumberId}`, data))
    }
    TemplateResponseData() {
        return Promise.resolve(belNotifier.get(`/getTemplateResponseData?institution_id=${institute}&whatsapp_no_id=${whatsappNumberId}`))
    }
    getMaskingWithDataRule() {
        return Promise.resolve(belNotifier.get(`/getMaskingWithDataRule?institution_id=${institute}&whatsapp_no_id=${whatsappNumberId}`))
    } 
    submitPrivacyRules(id,data) {
        return Promise.resolve(belNotifier.post(`/createOrUpdateMaskingRuleWithDataRule/${id}?institution_id=${institute}&whatsapp_no_id=${whatsappNumberId}`, data))
    }
    TemplateResponseDatawithid(id) {
        return Promise.resolve(belNotifier.get(`/getTemplateResponseData/${id}?institution_id=${institute}&whatsapp_no_id=${whatsappNumberId}`))
    }
    storeAssociateNumbers(data) {
        return Promise.resolve(belNotifier.post(`/storeAssociateNumbers?institution_id=${institute}&whatsapp_no_id=${whatsappNumberId}`, data))
    }
    storeAssociateNumberswithid(id,data) {
        return Promise.resolve(belNotifier.post(`/storeAssociateNumbers/${id}?institution_id=${institute}&whatsapp_no_id=${whatsappNumberId}`, data))
    }
}
export default UserService;