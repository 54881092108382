import * as React from 'react';
import { useNavigate } from 'react-router-dom';
import { useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import { Typography, Stack } from '@mui/material';
// import { PieChart } from '@mui/x-charts/PieChart';
// import { PieChart } from '@mui/x-charts';
import StarBorderRoundedIcon from '@mui/icons-material/StarBorderRounded';
import { styled } from '@mui/material/styles';
import Table from '@mui/material/Table';
import Button from '@mui/material/Button';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { Helpers } from '../service';
import { UserApi } from '../service/axios';
import Divider from '@mui/material/Divider';
import Link from '@mui/material/Link';
import { PieChart, Pie, Cell } from 'recharts';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogActions from '@mui/material/DialogActions';
import Select from '@mui/material/Select';  // Import Select
import MenuItem from '@mui/material/MenuItem';
import CircularProgress from '@mui/material/CircularProgress';

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: 'center',
  color: theme.palette.text.secondary,
}));

// Pie Chart


const COLORS = ['#D2175A', '#FF6600', '#FF9650', '#59BE1B', '#6856D7'];
export default function Dashboard() {

  const [open, setOpen] = useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };


  const [chartdata, setchartdata] = useState([]);
  const [templatedata, settemplatedata] = useState([]);
  const [contactdata, setcontactdata] = useState([]);
  const [fromDate, setFromDate] = useState('');
  const [response, setResponse] = useState('');
  const [toDate, setToDate] = useState('');
  const [total, setTotal] = useState('');
  const [maxToDate, setMaxToDate] = useState('');
  const [identifier, setIdentifier] = useState([]);
  const [templateid, setTemplateId] = useState();
  const [id, setId] = useState();
  const [user, setUser] = useState([]);
  const [loading, setLoading] = useState(true);
  const [selectedWhatsappNumber, setSelectedWhatsappNumber] = useState('');
  const [whatsappNumberId, setWhatsappNumberId] = useState('');
  useEffect(() => {
    getNumbers();
    const userInfoString = localStorage.getItem('userInfo');
    const userInfo = JSON.parse(userInfoString);
    setUser(userInfo);
    getReportData();
    getTemplate();
    getContact();
  }, []);

  const getReportData = () => {
    setLoading(true);
    UserApi.getReport().then(
      (resp) => {
        if (resp.status === 200) {
          var delivercount = 0;
          var sentcount = 0;
          var readcount = 0;
          var failedcount = 0;
          var pendingcount = 0;
          if (resp.data.report) {
            for (var i = 0; i < resp.data.report.length; i++) {
              if (resp.data.report[i].status == 'read') {
                readcount++;
              }
              if (resp.data.report[i].status == 'sent') {
                sentcount++;
              }
              if (resp.data.report[i].status == 'delivered') {
                delivercount++;
              }
              if (resp.data.report[i].status == 'failed') {
                failedcount++;
              }
              if (resp.data.report[i].status == 'pending') {
                pendingcount++;
              }
            }
            const data = [
              { name: 'Message Delivered', value: delivercount },
              { name: 'Message Sent', value: sentcount },
              { name: 'Message Failed', value: failedcount },
              { name: 'Message Read', value: readcount },
              { name: 'Message Pending', value: pendingcount },
            ];
            setTotal(delivercount + sentcount + failedcount + readcount + pendingcount)
            setchartdata(data);
            setFromDate(resp.data.from);
            setToDate(resp.data.to);
            handleClose();
          }
        } else {
          alert(resp.data.error_msg);
        }
      }
    ).catch((error) => {
      Helpers.error401(error);
    })
    .finally(() => {
      setLoading(false); // Set loading to false when the request is complete
    });
  };

  const getTemplateDetail = (id) => {
    UserApi.getReportByTemplate(id).then(
      (resp) => {
        if (resp.status === 200) {
          var delivercount = 0;
          var sentcount = 0;
          var readcount = 0;
          var failedcount = 0;
          var pendingcount = 0;
          if (resp.data.report) {
            for (var i = 0; i < resp.data.report.length; i++) {
              if (resp.data.report[i].status == 'read') {
                readcount++;
              }
              if (resp.data.report[i].status == 'sent') {
                sentcount++;
              }
              if (resp.data.report[i].status == 'delivered') {
                delivercount++;
              }
              if (resp.data.report[i].status == 'failed') {
                failedcount++;
              }
              if (resp.data.report[i].status == 'pending') {
                pendingcount++;
              }
            }
            const data = [
              { name: 'Message Delivered', value: delivercount },
              { name: 'Message Sent', value: sentcount },
              { name: 'Message Failed', value: failedcount },
              { name: 'Message Read', value: readcount },
              { name: 'Message Pending', value: pendingcount },
            ];
            setTotal(delivercount + sentcount + failedcount + readcount + pendingcount)
            setchartdata(data);
            handleClose();
          }
        } else {
          alert(resp.data.error_msg);
        }
      }
    ).catch((error) => {
      Helpers.error401(error);
    });
  };


  const getReportDatawithdate = (from, to) => {
    UserApi.getReportwithdate(from, to).then(
      (resp) => {
        if (resp.status === 200) {
          var delivercount = 0;
          var sentcount = 0;
          var readcount = 0;
          var failedcount = 0;
          var pendingcount = 0;
          if (resp.data.report) {
            for (var i = 0; i < resp.data.report.length; i++) {
              if (resp.data.report[i].status == 'read') {
                readcount++;
              }
              if (resp.data.report[i].status == 'sent') {
                sentcount++;
              }
              if (resp.data.report[i].status == 'delivered') {
                delivercount++;
              }
              if (resp.data.report[i].status == 'failed') {
                failedcount++;
              }
              if (resp.data.report[i].status == 'pending') {
                pendingcount++;
              }
            }
            const data = [
              { name: 'Message Delivered', value: delivercount },
              { name: 'Message Sent', value: sentcount },
              { name: 'Message Failed', value: failedcount },
              { name: 'Message Read', value: readcount },
              { name: 'Message Pending', value: pendingcount },
            ];
            setTotal(delivercount + sentcount + failedcount + readcount + pendingcount)
            setchartdata(data);
            setFromDate(resp.data.from);
            setToDate(resp.data.to);
            handleClose();
          }
        } else {
          alert(resp.data.error_msg);
        }
      }
    ).catch((error) => {
      Helpers.error401(error);
    });
  };

  const getTemplateDetailwithdate = (from, to, id) => {
    UserApi.getReportByTemplatewithdate(from, to, id).then(
      (resp) => {
        if (resp.status === 200) {
          var delivercount = 0;
          var sentcount = 0;
          var readcount = 0;
          var failedcount = 0;
          var pendingcount = 0;
          if (resp.data.report) {
            for (var i = 0; i < resp.data.report.length; i++) {
              if (resp.data.report[i].status == 'read') {
                readcount++;
              }
              if (resp.data.report[i].status == 'sent') {
                sentcount++;
              }
              if (resp.data.report[i].status == 'delivered') {
                delivercount++;
              }
              if (resp.data.report[i].status == 'failed') {
                failedcount++;
              }
              if (resp.data.report[i].status == 'pending') {
                pendingcount++;
              }
            }
            const data = [
              { name: 'Message Delivered', value: delivercount },
              { name: 'Message Sent', value: sentcount },
              { name: 'Message Failed', value: failedcount },
              { name: 'Message Read', value: readcount },
              { name: 'Message Pending', value: pendingcount },
            ];
            setTotal(delivercount + sentcount + failedcount + readcount + pendingcount)
            setchartdata(data);
          }
        } else {
          alert(resp.data.error_msg);
        }
      }
    ).catch((error) => {
      Helpers.error401(error);
    });
  };


  const getTemplate = () => {
    UserApi.getTemplate().then(
      (resp) => {
        if (resp.status === 200) {
          settemplatedata(resp.data.template);
        } else {
          alert(resp.data.error_msg);
        }
      }
    ).catch((error) => {
      Helpers.error401(error);
    });
  };

  const getContact = () => {
    UserApi.getContacts().then(
      (resp) => {
        if (resp.status === 200) {
          setcontactdata(resp.data.group);
        } else {
          alert(resp.data.error_msg);
        }
      }
    ).catch((error) => {
      Helpers.error401(error);
    });
  };

  const getNumbers = () => {
    UserApi.getAssociateWhatsappNumber().then(
      (resp) => {
        if (resp.status === 200) {
          setResponse(resp.data);
          const associatedNumbers = resp.data?.associated_no;

          if (associatedNumbers && associatedNumbers.length > 0) {
            const firstNumber = associatedNumbers[0];
            setSelectedWhatsappNumber(firstNumber ? firstNumber.whatsapp_mobile_no : '');
            setWhatsappNumberId(firstNumber?firstNumber.id:'');
            localStorage.setItem('selectedWhatsappNumberId', whatsappNumberId);
          } else {
            // Handle the case when there are no associated numbers
          }
        } else {
          // Handle error
        }
      }
    ).catch((error) => {
      Helpers.error401(error);
    });
  };

  const handleFromDateChange = (date) => {
    setFromDate(date);
  };
  const handleToDateChange = (date) => {
    setToDate(date);
  };

  const handleTemplateSelect = (event) => {
    const selectedTemplateId = event.target.value;
    setTemplateId(event.target.value);
    // getTemplateDetail(event.target.value);
  };

  const handleClick = (event, itemIdentifier, item) => {
    setId(item.id);
    setIdentifier(itemIdentifier);
  };

  const handleFilterButtonClick = () => {
    if (!fromDate || !toDate) {
      alert('Please select both From Date and To Date');
      return;
    }
    if (!templateid) {
      getReportDatawithdate(fromDate, toDate);
    } else {
      getTemplateDetailwithdate(fromDate, toDate, templateid);
    }
  };

  // useEffect(() => {
  //   localStorage.setItem('selectedWhatsappNumber', selectedWhatsappNumber);
  // }, [selectedWhatsappNumber]);

  // useEffect(() => {
  //   localStorage.setItem('selectedWhatsappNumberId', whatsappNumberId);
  //   console.log('whatsappnumber:',whatsappNumberId);
  // }, [whatsappNumberId]);

  return (
    <>
    {loading ? ( // Conditionally render CircularProgress when loading is true
        <div style={{ textAlign: 'center', paddingTop: '150px' }}>
          <CircularProgress />
        </div>
      ) : (
      <div style={{
        "padding": "10px 27px 27px",
        "borderRadius": "10px",
        "marginTop": "0px",
      }}>
        <Grid container spacing={2}>
          <Grid item xs={4} className={Helpers.cmAccess('DASHBOARD_MOBILE')}>
            <Item className='Productcontent' style={{ textAlign: "left" }}>
              <Box aria-label="Example" className="IconBox" style={{
                "width": "fit-content",
                "background": "#FAE3E3",
                "border-radius": "7px",
                "padding": "4px 4px",
                "justify-content": "center",
                "display": "flex",
              }}>
                <img src='/phone.png' />
              </Box>
              <Typography style={{ fontSize: 10, color: "#949494", marginTop: 10, }}>Phone No.</Typography>
              <Select
                value={selectedWhatsappNumber}
                onChange={(e) => setSelectedWhatsappNumber(e.target.value)}
                displayEmpty
              >
                <MenuItem value="" disabled>Select WhatsApp Number</MenuItem>
                {response?.associated_no?.map((number) => (
                  <MenuItem key={number.id} value={number.whatsapp_mobile_no}>
                    {number.whatsapp_mobile_no}
                  </MenuItem>
                ))}
              </Select>

            </Item>
          </Grid>
          <Grid item xs={4} className={Helpers.cmAccess('DASHBOARD_EMAIL')}>
            <Item className='Productcontent' style={{ textAlign: "left" }}>
              <Box aria-label="Example" style={{
                "width": "fit-content",
                "background": "#E8FAE3",
                "border-radius": "7px",
                "padding": "7px 8px",
                "justify-content": "center",
                "display": "flex",
              }}>
                <img src='/msg.png' />
              </Box>
              <Typography style={{ fontSize: 10, color: "#949494", marginTop: 13, }}>Email</Typography>
              <h4 className="textsize" style={{ fontSize: 18, marginBottom: 0, marginTop: 8, color: "#1C1C1C" }}>{user.email}</h4>
            </Item>
          </Grid>
          <Grid item xs={4} className={Helpers.cmAccess('DASHBOARD_NAME')}>
            <Item className='Productcontent' style={{ textAlign: "left" }}>
              <Box aria-label="Example" style={{
                "width": "fit-content",
                "background": "#E0F4FF",
                "border-radius": "7px",
                "padding": "7px 8px",
                "justify-content": "center",
                "display": "flex",
              }}>
                <StarBorderRoundedIcon color='#00A3FF' fontSize='14px' />
              </Box>
              <Typography style={{ fontSize: 10, color: "#949494", marginTop: 13, }}>Name</Typography>
              <h4 style={{ fontSize: 18, marginBottom: 0, marginTop: 8, color: "#1C1C1C" }}>{user.first_name} {user.last_name}</h4>
            </Item>
          </Grid>
        </Grid>
        {/* Graph Section */}
        <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }} style={{ marginTop: 18, }}>
          <Grid item xs={6}>
            <Item style={{ "box-shadow": "0px 3px 1px 0px #F3F3F3", "border-radius": "10px", height: "100%" }}>
              <Box style={{ display: "flex", padding: "11px 16px", }}>
                <Box aria-label="Example" style={{
                  "width": "31px",
                  "background": "rgb(232, 250, 227)",
                  "borderRadius": "7px",
                  "justifyContent": "center",
                  "display": "flex",
                  "marginRight": "9px",
                  "height": "31px",
                  "padding": "7px"
                }}>
                  <img src='/msg.png' />
                </Box>
                <Typography variant="h6" style={{ fontSize: 16, color: "#000", }}>Message Status({total})</Typography>


                <div className={`popupfiltermain ${Helpers.cmAccess('DASHBOARD_FILTER')}`}>
                  <Button variant="contained" className="filterpopup" onClick={handleClickOpen}>
                    <div><img src="/funnel.svg" className="imgfilter" /></div>
                  </Button>
                  <Dialog open={open} onClose={handleClose}>
                    <DialogTitle>Filter</DialogTitle>
                    <DialogContent>
                      <DialogContentText>
                        <Grid container spacing={2}>
                          <Grid item xs={6}>
                            <input type="date" className="Datepicker" value={fromDate} onChange={(e) => handleFromDateChange(e.target.value)} max={maxToDate} />
                          </Grid>
                          <Grid item xs={6}>
                            <input type="date" className="Datepicker" value={toDate} onChange={(e) => handleToDateChange(e.target.value)} min={fromDate} max={maxToDate} />
                          </Grid>
                          <Grid item xs={6}>
                            <select className="Datepicker" onChange={handleTemplateSelect}>
                              <option selected>
                              </option>
                              {templatedata.map(item => (
                                <option value={item.id}>
                                  {item.name}
                                </option>
                              ))}
                            </select>
                          </Grid>
                          <Grid item xs={6} className="textleft">
                            <Button className="btnfilter" onClick={handleFilterButtonClick}><img src="/funnel.svg" /></Button>
                          </Grid>
                        </Grid>          </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                      <Button onClick={handleClose} color="primary">
                        Close
                      </Button>
                    </DialogActions>
                  </Dialog>
                </div>
              </Box>


              <Stack
                direction={{ xs: 'column', md: 'row' }}
                alignItems={{ xs: 'flex-start', md: 'center' }}
                justifyContent="space-between"
                sx={{ width: '100%' }}
                style={{
                  "justify- content": "center",
                  "align-items": "center",
                  "height": "87%",
                  "justify-content": "center",
                }}
              >
                <div className={Helpers.cmAccess('DASHBOARD_GRAPH')}>
                  {chartdata && chartdata.length > 0 ? (
                    <PieChart width={550} height={280} style={{ outline: "none" }}>
                      <Pie
                        data={chartdata}
                        dataKey="value"
                        cx={250}
                        cy={135}
                        fill="#8884d8"
                        startAngle={0}
                        endAngle={360}
                        style={{ outline: "none" }}
                        label
                      >
                        {chartdata.map((entry, index) => (
                          <Cell
                            key={`cell-${index}`}
                            fill={COLORS[index % COLORS.length]}
                            style={{ outline: "none" }}
                          />
                        ))}
                      </Pie>
                    </PieChart>
                  ) : (
                    <div>No record found</div>
                  )}

                  <div style={{ marginTop: 20 }}>
                    <ul style={{ "display": "flex", "padding": "20px 45px", "justify-content": "center", }}>
                      {chartdata.map((item, index) => (
                        <li key={index} style={{ "margin-right": "10px", "color": "#000", "display": "flex", "alignItems": "center", "flex-direction": "column", "gap": "8px" }}>
                          <span style={{ "width": "15px", "height": "15px", "borderRadius": "50%", "background": COLORS[index % COLORS.length], "marginRight": "5px" }}></span>
                          <Typography sx={{ "fontSize": "10px", "color": "#000", "fontWeight": "500" }}>{item.name}:</Typography>  <b>{item.value}</b>
                        </li>
                      ))}
                    </ul>
                  </div>
                </div>
              </Stack>
            </Item>
          </Grid>
          <Grid item xs={6}>
            <Item className='griditems'>
              <Box style={{
                display: "flex", alignItems: "center", // Align items vertically in the center
                padding: "11px 16px",
              }}>
                <Box aria-label="Example" style={{
                  "width": "fit-content",
                  "background": "#E3EAFA",
                  "border-radius": "7px",
                  "padding": "7px 8px",
                  "justify-content": "center",
                  "display": "flex",
                  marginRight: "9px",
                }}>
                  <img src='/message.png' />
                </Box>
                <Typography variant="h6" style={{ fontSize: 16, color: "#000", }}>Message Templates</Typography>
                <Grid item xs={6} className={Helpers.cmAccess('DASHBOARD_SEEALL_TEMPLATE_BUTTON')}>
                  <Item style={{ "box-shadow": "none", "text-align": "end", }}>
                    <Link href="/dashboard/MassageTemplate" underline="none" style={{ fontSize: 12, color: "#0000FF", }}>
                      See All Templates
                    </Link>
                  </Item>
                </Grid>
              </Box>
              <Item style={{ "box-shadow": "none", "border-radius": "10px", "padding": "0px", }}>
                <TableContainer  className={Helpers.cmAccess('DASHBOARD_TEMPLATES')}>
                  <Table sx={{ minWidth: "100%" }} aria-label="simple table"
                  // style={{ border: "1px solid #F5F5F5", marginTop: 18, borderRadius: 9, }}
                  >
                    <TableHead>
                      <TableRow>
                        <TableCell>Name</TableCell>
                        <TableCell style={{ textAlign: "end", }}>Status</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {templatedata && templatedata.length > 0 && (
                        templatedata.slice(0, 7).map((template, index) => (
                          <TableRow key={index}>
                            <TableCell style={{ color: "#0889FF" }}>
                              <Link href="#" underline="none">{template.name}</Link>
                            </TableCell>
                            <TableCell style={{ textAlign: "end", color: "#51921F", fontWeight: 700 }}>
                              {template.status}
                            </TableCell>
                          </TableRow>
                        ))
                      )}

                    </TableBody>
                  </Table>
                </TableContainer>
              </Item>
            </Item>
          </Grid>
        </Grid>
        <div style={{
          "borderRadius": "10px",
          "marginTop": "20px"
        }}>
          {/* <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
            <Grid item xs={12}>
              <Item style={{ "box-shadow": "0px 1px 3px 0px rgb(0 0 0 / 6%)", "border-radius": "9px" }}>
                <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
                  <Grid item xs={8}>
                    <Item style={{ "box-shadow": "none" }}>
                      <Box style={{ display: "flex" }}>
                        <Box aria-label="Example" style={{
                          "width": "fit-content",
                          "background": "#FFF8E0",
                          "border-radius": "7px",
                          "padding": "7px 8px",
                          "justify-content": "center",
                          "display": "flex",
                          "marginRight": "10px"
                        }}>
                          <img src='/setting.png' />
                        </Box>
                        <Typography variant="h6" style={{ fontSize: 16, color: "#000", }}>Contact  List </Typography>
                      </Box>
                    </Item>
                  </Grid>
                  <Grid item xs={4}>
                    <Item style={{ "box-shadow": "none", "text-align": "end", }}>
                      <Link href="/dashboard/ContactUs" underline="none" style={{ fontSize: 12, color: "#929292", }}>
                        See All Contact
                      </Link>
                    </Item>
                  </Grid>
                </Grid>
                <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }} spacing={2} columns={15} style={{ padding: "0px    25px", marginBottom: "23px", marginTop: 5, }}>
                  {contactdata && contactdata.length > 0 && (
                    contactdata.slice(0, 10).map((contact, index) => (
                      <Grid item xs={3}>
                        <Item style={{ "box-shadow": "rgb(0 0 0 / 6%) 0px 12px 33px 0px", borderRadius: 18, }}>
                          <Box className="NewBadges">New</Box>
                          <Box sx={{ flexGrow: 0 }} style={{}}>
                            <img src="/profile.png" width={40} height={40} />
                            <h3 style={{ fontSize: 14, }}>{contact.name}
                              <p style={{ margin: 0, fontSize: 13, fontWeight: "normal" }}>{contact.mobile_no}</p>
                            </h3>
                          </Box>
                        </Item>
                      </Grid>
                    ))
                  )}
                </Grid>
              </Item>
            </Grid>
          </Grid> */}
        </div>
      </div>
      )}
    </>
  )
}
