// ChatApp.js

import React, { useEffect, useState, useRef } from 'react';
import {
  Grid,
  Paper,
  List,
  ListItem,
  ListItemAvatar,
  Avatar,
  ListItemText,
  Typography,
  TextField,
  Button,
} from '@mui/material';
import { Helpers } from '../service';
import { UserApi } from '../service/axios';
import { useLocation, useNavigate } from 'react-router-dom';

const ChatApp = () => {
  const [selectedUser, setSelectedUser] = useState(null);
  const [message, setMessage] = useState('');
  const { state } = useLocation();
  const [chatMessages, setChatMessages] = useState([]);
  const [searchText, setSearchText] = useState('');
  const [contactdata, setcontactdata] = useState([]);
  const [contact, setcontact] = useState([]);
  const [messagedata, setmessagedata] = useState([]);
  const [sendMessages, sendMessagesText] = useState('');
  var count = 0;
  const emptyBoxRef = useRef(null);

  useEffect(() => {
    if (state) {
      getGroupcontact(state);
    }
  }, []);

  const getGroupcontact = (id) => {
    UserApi.getContacts(id).then(
      (resp) => {
        if (resp.status === 200) {
          setcontactdata(resp.data.group);
          setSelectedUser(resp.data.group[0].id);
          getContactData(resp.data.group[0].id);
        } else {
          alert(resp.data.error_msg);
        }
      }
    ).catch((error) => {
      Helpers.error401(error);
    });
  };


  const getContactData = (id) => {
    UserApi.getReportByContactId(id).then(
      (resp) => {
        if (resp.status === 200) {
          const updatedMessages = resp.data.report.map((message) => {
            if (message.template) {
              // Replace body_text
              if (message.template.body_text) {
                const bodyText = message.template.body_text;
                const exampleBodyText = message.template.example_body_text;

                if (bodyText.includes("{{")) {
                  const replacedBodyText = bodyText.replace(
                    /{{(\d+)}}/g,
                    (match, index) => {
                      const exampleValues = exampleBodyText.split(",");
                      const numericalIndex = parseInt(index) - 1;
                      return exampleValues[numericalIndex] || match;
                    }
                  );

                  message.template.body_text = replacedBodyText;
                }
              }

              // Replace header_text
              if (message.template.header_text) {
                const headerText = message.template.header_text;
                const exampleHeaderText = message.template.example_header_text;

                if (headerText.includes("{{")) {
                  const replacedHeaderText = headerText.replace(
                    /{{(\d+)}}/g,
                    (match, index) => {
                      const exampleValues = exampleHeaderText.split(",");
                      const numericalIndex = parseInt(index) - 1;
                      return exampleValues[numericalIndex] || match;
                    }
                  );

                  message.template.header_text = replacedHeaderText;
                }
              }
            }
            return message;
          });

          setmessagedata(updatedMessages);
          setcontact(resp.data.contact_detail);
        } else {
          alert(resp.data.error_msg);
        }
      }
    ).catch((error) => {
      Helpers.error401(error);
    });
  };

  const getContactDatafromsend = (id) => {
    UserApi.getReportByContactId(id).then(
      (resp) => {
        if (resp.status === 200) {
          if (count < 10) {
            const updatedMessages = resp.data.report.map((message) => {
              if (message.template) {
                // Replace body_text
                if (message.template.body_text) {
                  const bodyText = message.template.body_text;
                  const exampleBodyText = message.template.example_body_text;

                  if (bodyText.includes("{{")) {
                    const replacedBodyText = bodyText.replace(
                      /{{(\d+)}}/g,
                      (match, index) => {
                        const exampleValues = exampleBodyText.split(",");
                        const numericalIndex = parseInt(index) - 1;
                        return exampleValues[numericalIndex] || match;
                      }
                    );

                    message.template.body_text = replacedBodyText;
                  }
                }

                // Replace header_text
                if (message.template.header_text) {
                  const headerText = message.template.header_text;
                  const exampleHeaderText = message.template.example_header_text;

                  if (headerText.includes("{{")) {
                    const replacedHeaderText = headerText.replace(
                      /{{(\d+)}}/g,
                      (match, index) => {
                        const exampleValues = exampleHeaderText.split(",");
                        const numericalIndex = parseInt(index) - 1;
                        return exampleValues[numericalIndex] || match;
                      }
                    );

                    message.template.header_text = replacedHeaderText;
                  }
                }
              }
              return message;
            });

            setmessagedata(updatedMessages);
            setcontact(resp.data.contact_detail);
            count++;
            getContactDatafromsend(id);
          } else {
            count = 0;
          }
        } else {
          alert(resp.data.error_msg);
        }
      }
    ).catch((error) => {
      Helpers.error401(error);
    });
  };

  const sendMessage = (id, msg) => {
    UserApi.sendMessageIndividual(id, msg).then(
      (resp) => {
        if (resp.status === 200) {
          setSelectedUser(id);
          getContactDatafromsend(id);
        } else {
          alert(resp.data.error_msg);
        }
      }
    ).catch((error) => {
      Helpers.error401(error);
    });
  };


  const handleUserClick = (id) => {
    setSelectedUser(id);
    getContactData(id);
    setChatMessages([]);
  };
  // useEffect(() => {
  // }, [messagedata]);

  const handleSendMessage = () => {
    if (sendMessages.trim() !== '') {
      sendMessage(selectedUser, sendMessages);
      sendMessagesText('');
    }
  };

  const userAvatars = {
    'User 1': 'avatar1.jpg',
    'User 2': 'avatar2.jpg',
  };

  const filteredUsers = contactdata.filter(
    (user) =>
      user.name.toLowerCase().includes(searchText.toLowerCase()) ||
      user.mobile_no.includes(searchText)
  );

  const scrollToEmptyBox = () => {
    emptyBoxRef.current.scrollIntoView({ behavior: 'smooth' });
  };


  return (
    <Grid container spacing={2}>
      {/* Left Section: User List */}
      <Grid item xs={4} style={{ paddingLeft: 0, paddingTop: "13px", }}>
        <Paper elevation={3} className="chatscrn" style={{ height: '86.5vh', overflowY: 'auto', background: "#fff", borderRight: "1px solid #e3e3e3", borderRadius: "0 !important" }}>
          {/* Search Input */}
          <div style={{
            position: "relative", borderBottom: " 1px solid rgb(243, 243, 243)",
            marginBottom: "9px",
          }}>
            <input
              label="Search users"
              variant="outlined"
              fullWidth
              margin="normal"
              value={searchText}
              onChange={(e) => setSearchText(e.target.value)}
              placeholder="Search"
              className="formcontrol"
            />
            <img src="/search.svg" className="searchinputicons" />
          </div>
          {/* User List */}
          <div className="borderchat">
            <List className="stylechat " style={{ padding: "0px", margin: '0px !important,' }}>
              {filteredUsers.map((user) => (
                <ListItem

                  key={user.id}
                  button
                  onClick={() => handleUserClick(user.id)}
                  selected={selectedUser === user.id}
                >
                  <ListItemAvatar className="iconscenter">
                    <img src="/profile.png" className="avtaricons" />

                  </ListItemAvatar>
                  <ListItemText className="textchat"
                    primary={user.name}
                    secondary={user.mobile_no}
                  />
                </ListItem>
              ))}
            </List>
          </div>
        </Paper>
      </Grid>

      {/* Right Section: Chat Window */}
      <Grid item xs={8} className="chatingmain" style={{ position: "relative", paddingTop: "13px", }}>
        <Paper className="papperdiv chatscrn" elevation={3} style={{ padding: '0', overflowY: 'auto' }}>
          {contact ? (
            <>
              {/* Header */}
              <div style={{
                display: 'flex', alignItems: 'center', marginBottom: '16px', borderBottom: "1px solid #f3f3f3", paddingBottom: "14px", position: " fixed",
                width: "51%", padding: "10px",
                background: "#fff",
              }}>
                <Avatar alt={contact.id} src={userAvatars[contact.id]} style={{ marginRight: '8px' }} />
                <Typography variant="h6">

                  <p className="namevaluechat">{contact.name} </p>

                  <p className="numberuserchat"> {contact.mobile_no}</p>

                </Typography>
              </div>

              {/* Display chat messages */}

              <div className="heightofchat" >
                {messagedata.map((msg, index) => (
                  <div>
                    {msg.type !== "SEND" ? (
                      <div className="message2">
                        {msg.message}

                        <p className="timeadd">{msg.message_time}</p>
                      </div>) : (
                      <div style={{ textAlign: "-webkit-right" }}>
                        <div key={index} className="message">
                          {msg.template !== null ? (
                            <div>
                              <h4 className="temphead" style={{ textAlign: 'left', margin: "0" }}>{msg.template.header_text}</h4>
                              <p className="temppera" style={{ margin: '0' }}>{msg.template.body_text}</p>
                            </div>
                          ) : (
                            <p className="mychat">{msg.message}</p>
                          )}

                          {msg.status === "read" ? (
                            <p class="positionmain">
                              <img src="/sent.png" className="errorimage" />
                            </p>
                          ) : msg.status === "sent" ? (
                            <p class="positionmain">
                              <img src="/sent2.png" className="errorimage" />
                            </p>
                          ) : msg.status === "delivered" ? (
                            <p class="positionmain">
                              <img src="/delivered.png" className="errorimage" />
                            </p>
                          ) : msg.status === "failed" || msg.status === null ? (
                            <p class="positionmain">
                              <img src="/error.png" className="errorimage" />
                            </p>
                          ) : msg.status === "pending" ? (
                            <p>
                              🕒
                            </p>
                          ) : (
                            ''
                          )}


                          {msg.template !== null && msg.template.button === "1" && (
                            <>
                              {msg.template.buttons_details.map((button, i) => (
                                <React.Fragment key={i}>

                                  <div className="templtesBtn"><h3 style={{ textAlign: 'center' }}>{button.button_text}</h3></div>
                                </React.Fragment>
                              ))}
                            </>
                          )}


                        </div>
                        <p className="timeadd">{msg.message_time}</p>
                      </div>)}
                  </div>
                ))}
                <div className="arrowbottom" id="arrowbottom" onClick={scrollToEmptyBox}>
                  <img src="/down-arrow (1).gif" className="imgarrowdown" />
                </div>

              </div>

              <div ref={emptyBoxRef} style={{ height: '1px', width: '100%', marginBottom: '10px' }}></div>

              {/* Message Input Box */}
              <div className='chatingtextfiled' style={{ marginTop: '16px', position: "absolute", bottom: "0", width: "100%", marginBottom: "0px", borderRight: "1px solid #e9e9e9", }}>
                <TextField
                  label="Type your message"
                  variant="outlined"
                  fullWidth
                  value={sendMessages}
                  onChange={(e) => sendMessagesText(e.target.value)}
                />
                <div className={Helpers.cmAccess('SEND_MESSAGE')}>
                  <Button
                    variant="contained"
                    color="primary"
                    style={{ marginTop: '8px' }}
                    className={`buttonsend`}
                    onClick={handleSendMessage}
                  >
                    <img src="/send.svg" className="sendbtn" />
                  </Button>
                </div>
              </div>
            </>
          ) : (
            <Typography variant="h6">Select a user to start chatting</Typography>
          )}
        </Paper>
      </Grid>
    </Grid>
  );
};

export default ChatApp;
