import React, { useEffect, useState } from 'react';
import { styled } from '@mui/material/styles';
import Paper from '@mui/material/Paper';
import { Typography, Grid, Box, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, } from '@mui/material';
import { NavLink } from 'react-router-dom';
import { Helper, UserApi } from '../service/axios';
import { Helpers } from '../service';
import { useNavigate } from 'react-router-dom';
import CircularProgress from '@mui/material/CircularProgress';

const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
    ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: 'center',
    color: theme.palette.text.secondary,
}));

export default function Campaign() {
    const [templateStats, setTemplateStats] = useState([]);
    const [loading, setLoading] = useState(true);
    const navigate = useNavigate();


    useEffect(() => {
        TemplateResponseData();
    }, []);



    const TemplateResponseData = () => {
        setLoading(true);

        UserApi.TemplateResponseData()
            .then((resp) => {
                if (resp.status === 200) {
                    calculateTemplateStats(resp.data.template_contexts);
                } else {
                }
            })
            .catch((error) => {
                console.error('Error fetching template response data:', error);
            })
            .finally(() => {
                setLoading(false);
            });
    };



    const calculateTemplateStats = (templateContexts) => {
        const newTemplateStats = [];
        const uniqueNamesSet = new Set();
        const uniqueMobileNoSet = new Set();
        templateContexts.forEach((context) => {
            const templateName = context.template.name;
            const totalMessages = context.messages.filter(message => message.type === 'SEND').length;
            const Id = context.id;
            const sendStatusCounts = {
                read: 0,
                sent: 0,
                delivered: 0,
                failed: 0,
                pending: 0,
            };
            context.messages.forEach((message) => {
                const { status, type } = message;

                if (type === 'SEND') {
                    sendStatusCounts[status] += 1;
                }
            });
            const hasSendMessages = totalMessages > 0;
            const sendPercentages = {};

            ['read', 'sent', 'delivered', 'failed', 'pending'].forEach((status) => {
                const count = sendStatusCounts[status];
                const percentage = hasSendMessages ? (count / totalMessages) * 100 : 0;
                sendPercentages[`${status}Percentage`] = hasSendMessages ? `${percentage.toFixed(2)}%` : '0%';
                sendPercentages[`${status}Count`] = count;
            });
            let respondedMessages = 0;
            for (let i = 0; i < context.messages.length; i++) {
                if (
                    context.messages[i].type === 'TEMP_REPLY' &&
                    !uniqueMobileNoSet.has(context.messages[i].sender)
                ) {
                    respondedMessages++;
                    uniqueMobileNoSet.add(context.messages[i].sender);
                }

            }
            const templateStat = {
                templateName,
                totalMessages,
                respondedMessages,
                ...sendPercentages,
                messageTime: context.messages.length > 0 ? context.messages[0].message_time : null,
                Id,
            };
            newTemplateStats.push(templateStat);
            uniqueMobileNoSet.clear();
        });
        setTemplateStats(newTemplateStats);
    };


    const singleTemplateresponse = (id) => {
        navigate('/dashboard/campaign/campaigndetail', { replace: true, state: id });
    };





    return (
        <>
            <Box sx={{ width: '100%' }}>
                <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }} mt={2}>
                    <Grid item xs={12}>
                        <Item className='griditems'>
                            <Box
                                sx={{
                                    "border": " 1px solid #F5F5F5",
                                    "border-radius": "9px",
                                }}
                            >
                                {loading ? (
                                    <Box
                                        sx={{
                                            display: 'flex',
                                            justifyContent: 'center',
                                            alignItems: 'center',
                                            height: '200px',
                                        }}
                                    >
                                        <CircularProgress />
                                    </Box>
                                ) : (
                                    <TableContainer className={Helpers.cmAccess('CAMPAIGN_TABLE')}>
                                        <Table sx={{ minWidth: '100%' }} aria-label="simple table" className='TemplateTable'>
                                            <TableHead>
                                                <TableRow>
                                                    <TableCell>Title</TableCell>
                                                    <TableCell>Total</TableCell>
                                                    <TableCell>Sent</TableCell>
                                                    <TableCell>Delivered</TableCell>
                                                    <TableCell>Read</TableCell>
                                                    <TableCell>Pending</TableCell>
                                                    <TableCell>Failed</TableCell>
                                                    <TableCell>Responded</TableCell>
                                                    <TableCell>Created Time</TableCell>
                                                </TableRow>
                                            </TableHead>
                                            <TableBody>
                                                {templateStats.map((templateStat) => (
                                                    <TableRow key={templateStat.Id}>
                                                        <div className={Helpers.cmAccess('CAMPAIGN_VIEW')} style={{display:"contents"}}>
                                                            <TableCell style={{ color: '#0889FF', cursor: "pointer" }} onClick={() => singleTemplateresponse(templateStat.Id)}>
                                                                {templateStat.templateName}
                                                            </TableCell>
                                                        </div>
                                                        <TableCell>{templateStat.totalMessages} (100%)</TableCell>
                                                        <TableCell>{templateStat.sentCount} ({templateStat.sentPercentage})</TableCell>
                                                        <TableCell>{templateStat.deliveredCount} ({templateStat.deliveredPercentage})</TableCell>
                                                        <TableCell>{templateStat.readCount} ({templateStat.readPercentage})</TableCell>
                                                        <TableCell>{templateStat.pendingCount} ({templateStat.pendingPercentage})</TableCell>
                                                        <TableCell>{templateStat.failedCount} ({templateStat.failedPercentage})</TableCell>
                                                        <TableCell align='center'>{templateStat.respondedMessages} </TableCell>
                                                        <TableCell> {templateStat.messageTime} </TableCell>
                                                    </TableRow>
                                                ))}
                                            </TableBody>
                                        </Table>
                                    </TableContainer>
                                )}
                            </Box>
                        </Item>
                    </Grid>
                </Grid>
            </Box>
        </>
    )
}