import React from 'react';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import AccountBalanceIcon from '@mui/icons-material/AccountBalance';
import ConfirmationNumberIcon from '@mui/icons-material/ConfirmationNumber';
import ShareIcon from '@mui/icons-material/Share';
import PaidIcon from '@mui/icons-material/Paid';
import AllInboxIcon from '@mui/icons-material/AllInbox';
import { Helpers } from '../service';
import { NavLink, useLocation } from 'react-router-dom';
import KeyboardArrowDownRoundedIcon from '@mui/icons-material/KeyboardArrowDownRounded';
// import '../../css/style.css';
import '../css/style.css';
import Tooltip from '@mui/material/Tooltip';

import Box from '@mui/material/Box';
export default function MainList() {
  const location = useLocation();

  const isActive = (path) => {
    return location.pathname === path;
  };

  return (
    <React.Fragment>
      <div>
        <NavLink to="/dashboard/app" style={{ textDecoration: "none" }} className={Helpers.cmAccess('MENU_DASHBOARD')}>
          <ListItemButton style={{ "margin-bottom": 16, "border-radius": "50px" }}
            sx={{
              color: isActive('/Dashboard') ? '#FF6600' : '#181616',

            }}
          >
            <Tooltip title="Dashboard" arrow>
              <ListItemIcon style={{ minWidth: "45px" }}>
                <Box
                  sx={{
                    background: `url(${isActive('/dashboard/app') ? '/activedash.png' : '/dashboard.png'})`,
                    backgroundSize: 'cover',
                    backgroundPosition: '0px -4%',
                    width: '17px',
                    height: '17px',
                  }}
                />
              </ListItemIcon>
            </Tooltip>
            <ListItemText primary="Dashboard" style={{ margin: 0, }} />
            {/* <KeyboardArrowDownRoundedIcon /> */}
          </ListItemButton>
        </NavLink>

      </div>
      <NavLink to="/dashboard/Notification" style={{ textDecoration: "none" }} className={Helpers.cmAccess('MENU_NOTIFICATION')}>
        <ListItemButton style={{ "margin-bottom": 16, "border-radius": "50px" }}
          sx={{
            color: isActive('/Notification') ? '#FF6600' : '#181616',
          }}
        >
          <Tooltip title="Notifications" arrow>
            <ListItemIcon style={{ minWidth: "45px" }}>
              <Box
                sx={{
                  background: `url(${isActive('/dashboard/Notification') || isActive('/dashboard/notification/addnotification') || isActive('/dashboard/notification/notificationdetail') ? '/belnotification.png' : '/dnotification.png'})`,
                  backgroundSize: 'cover',
                  width: '17px',
                  height: '19px',
                  backgroundPosition: "0px -5%",
                }}
              />
            </ListItemIcon>
          </Tooltip>
          <ListItemText primary="Notifications" style={{ margin: 0, }} />
          {/* <KeyboardArrowDownRoundedIcon /> */}
        </ListItemButton>
      </NavLink>
      <NavLink to="/dashboard/MassageTemplate" style={{ textDecoration: "none" }} className={Helpers.cmAccess('MENU_CONTACTLIST')}>
        <ListItemButton style={{ "margin-bottom": 16, "border-radius": "50px" }}
          sx={{
            color: isActive('/MassageTemplate') ? '#FF6600' : '#181616',
          }}
        >
          <Tooltip title="Templates" arrow>
            <ListItemIcon style={{ minWidth: "45px" }}>
              <Box
                sx={{
                  background: `url(${isActive('/dashboard/MassageTemplate') || isActive('/dashboard/massagetemplate/massagetemplateform') ? '/activemt.png' : '/massagetemplate.png'})`,
                  backgroundSize: 'cover',
                  width: '16px',
                  height: '16px',
                  backgroundPosition: "0px 12%",
                }}
              />
            </ListItemIcon>
          </Tooltip>
          <ListItemText primary="Templates" style={{ margin: 0, }} />
          {/* <KeyboardArrowDownRoundedIcon /> */}
        </ListItemButton>
      </NavLink>

      <NavLink to="/dashboard/ContactUs" style={{ textDecoration: "none" }} className={Helpers.cmAccess('MENU_MESSAGETEMPLATE')}>
        <ListItemButton style={{ "margin-bottom": 16, "border-radius": "50px" }}
          sx={{
            color: isActive('/ContactUs') ? '#FF6600' : '#181616',
          }}
        >
          <Tooltip title="Contacts" arrow>
            <ListItemIcon style={{ minWidth: "45px" }}>
              <Box
                sx={{
                  background: `url(${isActive('/dashboard/ContactUs') || isActive('/dashboard/contactus/userdashboard') ? '/contactus.png' : '/contact.png'})`,
                  backgroundSize: 'cover',
                  width: '18px',
                  height: '16px',
                  backgroundPosition: "0px -5%",
                }}
              />

            </ListItemIcon>
          </Tooltip>
          <ListItemText primary="Contacts" style={{ margin: 0, }} />
          {/* <KeyboardArrowDownRoundedIcon /> */}
        </ListItemButton>
      </NavLink>

      <NavLink to="/dashboard/Analytics" style={{ textDecoration: "none" }} className={Helpers.cmAccess('MENU_CONVERSATIONS')}>
        <ListItemButton style={{ "margin-bottom": 16, "border-radius": "50px" }}
          sx={{
            color: isActive('/Analytics') ? '#FF6600' : '#181616',
          }}
        >
          <Tooltip title="Conversations Analytics" arrow>
            <ListItemIcon style={{ minWidth: "45px" }}>
              <Box
                sx={{
                  background: `url(${isActive('/dashboard/Analytics') || isActive('/dashboard/analytics/chatsscreen') ? '/Analytics.png' : '/Analytics2.png'})`,
                  backgroundSize: 'cover',
                  width: '16px',
                  height: '16px',
                  backgroundPosition: "0px -5%",
                }}
              />
            </ListItemIcon>
          </Tooltip>
          <ListItemText primary="Conversations" style={{ margin: 0, }} />
          {/* <KeyboardArrowDownRoundedIcon /> */}
        </ListItemButton>
      </NavLink>
      <NavLink to="/dashboard/Campaign " style={{ textDecoration: "none" }} className={Helpers.cmAccess('MENU_CAMPAIGN')}>
        <ListItemButton style={{ "margin-bottom": 16, "border-radius": "50px" }}
          sx={{
            color: isActive('/Campaign') ? '#FF6600' : '#181616',
          }}
        >
          <Tooltip title="/dashboard/Campaign" arrow>
            <ListItemIcon style={{ minWidth: "45px" }}>
              <Box
                sx={{
                  background: `url(${isActive('/dashboard/Campaign') || isActive('/dashboard/campaign/campaigndetail') ? '/campaign2.png' : '/campaign1.png'})`,
                  backgroundSize: 'cover',
                  width: '16px',
                  height: '16px',
                  backgroundPosition: '0px -5%',
                }}
              />
            </ListItemIcon>
          </Tooltip>
          <ListItemText primary="Campaign" style={{ margin: 0, }} />
          {/* <KeyboardArrowDownRoundedIcon /> */}
        </ListItemButton>
      </NavLink>
      <NavLink to="/dashboard/Admin" style={{ textDecoration: "none" }} className={Helpers.cmAccess('MENU_ADMIN')}>
        <ListItemButton style={{ "margin-bottom": 16, "border-radius": "50px" }}
          sx={{
            color: isActive('/Admin') ? '#FF6600' : '#181616',
          }}
        >
          <Tooltip title="Conversations Analytics" arrow>
            <ListItemIcon style={{ minWidth: "45px" }}>
              <Box
                sx={{
                  background: `url(${isActive('/dashboard/Admin') || isActive('/dashboard/admin/dataprivacy') || isActive('/dashboard/admin/Access') || isActive('/dashboard/admin/Configration') || isActive('/dashboard/admin/Userlist') || isActive('/dashboard/admin/RegisterForm') ? '/admin-panel1.png' : '/admin-panel2.png'})`,
                  backgroundSize: 'cover',
                  width: '19px',
                  height: '19px',
                  backgroundPosition: "0px -5%",
                }}
              />
            </ListItemIcon>
          </Tooltip>
          <ListItemText primary="Admin" style={{ margin: 0, }} />
          {/* <KeyboardArrowDownRoundedIcon /> */}
        </ListItemButton>
      </NavLink>

    </React.Fragment>
  );
}