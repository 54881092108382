import belNotifier from "../axios/axios";


class ConfigService {
	// // Assuming you are passing an endpoint as an argument
	// template(data) {
	// 	return Promise.resolve(belNotifier.post('template', data))
	// }
  
	
	
	
	
	// Example of a method to retrieve data from localStorage
	getTokenFromLocalStorage() {
	  if (localStorage.getItem('token')) {
		return JSON.parse(localStorage.getItem('token'));
	  }
	  return null;
	}
  }
export default ConfigService;