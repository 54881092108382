import * as React from 'react';
import { useState, useEffect } from 'react';
import { styled, alpha } from '@mui/material/styles';
import Paper from '@mui/material/Paper';
import {
  Typography,
  Grid,
  Box,
  Button,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Link,
  Stack
} from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import InputBase from '@mui/material/InputBase';
import AddIcon from '@mui/icons-material/Add';
import { NavLink } from 'react-router-dom';
import { UserApi } from '../service/axios';
import { Helpers } from '../service';
import { useLocation, useNavigate } from 'react-router-dom';
import CircularProgress from '@mui/material/CircularProgress';
import SendOutlinedIcon from '@mui/icons-material/SendOutlined';

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: 'center',
  color: theme.palette.text.secondary,
}));
const Search = styled("div")(({ theme }) => ({
  position: "relative",
  display: "flex",
  borderRadius: theme.shape.borderRadius,
  backgroundColor: alpha(theme.palette.common.white, 0.15),
  "&:hover": {
    backgroundColor: alpha(theme.palette.common.white, 0.25)
  },
  marginRight: theme.spacing(2),
  marginLeft: 0,
  width: "100%",
  [theme.breakpoints.up("sm")]: {
    marginLeft: theme.spacing(3),
    width: "auto"
  }
}));

const SearchIconWrapper = styled("div")(({ theme }) => ({
  padding: theme.spacing(0, 2),
  height: "100%",
  position: "absolute",
  pointerEvents: "none",
  display: "flex",
  alignItems: "center",
  justifyContent: "center"
}));

const StyledInputBase = styled(InputBase)(({ theme }) => ({
  color: "inherit",
  "& .MuiInputBase-input": {
    padding: theme.spacing(1, 1, 1, 0),
    paddingLeft: `calc(1em + ${theme.spacing(4)})`,
    transition: theme.transitions.create("width"),
    width: "100%",
    [theme.breakpoints.up("md")]: {
      "width": "20ch",
      "border": " 1px solid #E9E9E9",
      "border-radius": "6px",
      "font-size": "14px",
    },
    [theme.breakpoints.up("sm")]: {
      "border": " 1px solid #E9E9E9",
      "border-radius": "6px",
      "font-size": "14px",
    },
    [theme.breakpoints.up("xs")]: {
      "width": "100%",
      "border": " 1px solid #E9E9E9",
      "border-radius": "6px",
      "font-size": "14px",
    }
  }
}));


const MassageTemplate = () => {
  const [templates, setTemplates] = useState([]);
  const [filteredTemplates, setFilteredTemplates] = useState([]);
  const [error, setError] = useState(null);
  const [searchTerm, setSearchTerm] = useState('');
  const navigate = useNavigate(); const [currentTime, setCurrentTime] = useState(new Date());
  const [loading, setLoading] = useState(true);
  const location = useLocation();
  const templateId = location.state ? location.state.templateId : null;


  useEffect(() => {
    fetchTemplates();
  }, []);

  const isEditable = (lastEditTime, status) => {
    if (status === "PENDING") {
      return false;
    }
    if (lastEditTime === null) {
      return true;
    }
    const lastEditDate = new Date(lastEditTime);
    const currentTime = new Date();
    const timeDifferenceInMilliseconds = currentTime - lastEditDate;
    const timeDifferenceInHours = timeDifferenceInMilliseconds / (60 * 60 * 1000);
    if (timeDifferenceInHours > 24) {
      return true;
    } else {
      return false;
    }
  };

  const fetchTemplates = async () => {
    try {
      const resp = await UserApi.getTemplate();
      if (resp.status === 200) {
        if (resp.data.message === 'Template get successfully' && resp.data.template) {
          setTemplates(resp.data.template);
          setFilteredTemplates(resp.data.template);
        }
      } else {
        console.error('Error getting template:', resp.data.error_msg);
        alert(resp.data.error_msg);
      }
    } catch (error) {
      console.error('Error getting template:', error);
      Helpers.error401(error);
    } finally {
      setLoading(false);
    }
  };

  const handleSearchChange = (event) => {
    const searchTerm = event.target.value.toLowerCase();
    setSearchTerm(searchTerm);

    const filtered = templates.filter((template) =>
      template.name.toLowerCase().includes(searchTerm) ||
      template.status.toLowerCase().includes(searchTerm)
    );
    setFilteredTemplates(filtered);
  };

  if (error) {
    return <div>Error: {error}</div>;
  }

  const templateEdit = (id) => {
    navigate('/dashboard/massagetemplate/massagetemplateformedit', { replace: true, state: id });
  };

  const handleSend = (id) => {
    navigate('/dashboard/notification/addnotification', {
      replace: true,
      state: { templateId: id },
    });
  };

  return (
    <>
      {loading ? (
        <div style={{ textAlign: 'center', paddingTop: '150px' }}>
          <CircularProgress />
        </div>
      ) : (
        <div style={{
          "padding": "20px 20px",
          "borderRadius": "10px",
          "marginTop": "10px",
          "background": "#FFFFFF",
          "boxShadow": "0px 3px 1px 0px hsl(0deg 0% 0% / 6%)",
        }}>
          <Grid container spacing={2} columns={12} style={{ "padding": "10px 15px 0", }}>
            <Grid item xs={12} md={4} sm={6}>
              <Item style={{ "boxShadow": "none", }}>
                <h4 className='Templateheading' style={{}}>Message Templates</h4>
              </Item>
            </Grid>
            <Grid item xs={12} md={8} sm={6}>
              <Item className='inneritems' sx={{ textAlign: "left", "float": "inline-end", }}>
                <Stack direction="row" spacing={2}>
                  <div className={Helpers.cmAccess('TEMPLATES_SEARCH')}>
                    <Search>
                      <SearchIconWrapper>
                        <SearchIcon style={{ fontSize: 16, color: "#757575" }} />
                      </SearchIconWrapper>
                      <StyledInputBase
                        placeholder="Search…"
                        inputProps={{ "aria-label": "search" }}
                        value={searchTerm}
                        onChange={handleSearchChange}
                      />
                    </Search>
                  </div>
                  <NavLink to="/dashboard/massagetemplate/massagetemplateformedit" style={{ textDecoration: "none" }} className={Helpers.cmAccess('TEMPLATES_ADD_NEW')}>
                    <Button variant="outlined" className='contactBtn' startIcon={<AddIcon />} style={{ "height": "38px", }}>
                      Add New
                    </Button>
                  </NavLink>
                </Stack>
              </Item>
            </Grid>

          </Grid>
          <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }} className={Helpers.cmAccess('TEMPLATES_MESSAGE_TEMPLATES_TABLE')}>
            <Grid item xs={12}>
              <Item style={{ "boxShadow": "none" }}>
                <TableContainer>
                  <Table className='TemplateTable' sx={{ minWidth: "100%" }} aria-label="simple table" style={{ border: "1px solid #F5F5F5", marginTop: 18, borderRadius: 9, }}>
                    <TableHead>
                      <TableRow>
                        <TableCell>Name</TableCell>
                        <TableCell>Language</TableCell>
                        <TableCell>Status</TableCell>
                        <TableCell>Category</TableCell>
                        <TableCell>Action</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {filteredTemplates.map((template) => (
                        <TableRow key={template.id}>
                          <TableCell
                            style={{ color: "#0889FF" }}
                          >
                            <NavLink to={`/dashboard/massagetemplate/templateview`} style={{ "background-color": "transparent", "text-decoration": "none", "color": "#0889FF" }} replace={true} state={template.id} className={Helpers.cmAccess('TEMPLATES_VIEW')}>
                              {template.name}</NavLink>
                          </TableCell>
                          {template.language_code !== 'en' ? (
                          <TableCell>{template.language_code}</TableCell>
                          ):(
                            <TableCell>ENGLISH</TableCell>
                          )}
                          <TableCell style={{ color: template.status === 'REJECTED' ? 'red' : '#51921F', fontWeight: 700 }}>
                            {template.status}
                          </TableCell>
                          <TableCell>{template.category}</TableCell>
                          <TableCell>
                            <img
                              src="/edit.png"
                              alt=""
                              width={16}
                              style={{ cursor: "pointer" }}
                              className={Helpers.cmAccess('TEMPLATES_EDIT')}
                              onClick={() => isEditable(template.last_edit_time, template.status) && templateEdit(template.id)}
                            />
                            {template.status !== 'REJECTED' ? (
                              <SendOutlinedIcon
                                style={{ cursor: "pointer", marginLeft: "20px" }}
                                sx={{ transform: "rotate(333deg)", color: "#4A7CFF" }}
                                onClick={() => handleSend(template.id)}
                              />
                            ) : (
                              <SendOutlinedIcon
                                style={{ cursor: "not-allowed", marginLeft: "20px", color: "#ccc" }}
                                sx={{ transform: "rotate(333deg)" }}
                                disabled
                              />
                            )}
                          </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              </Item>
            </Grid>
          </Grid>
        </div>
      )}
    </>
  );
};

export default MassageTemplate;
