import * as React from 'react';
import { useState, useEffect } from 'react';
import { NavLink } from 'react-router-dom';
import { Typography, Grid, Box, Card, Button, CardContent, Stack, Divider, TextField, CardMedia, } from '@mui/material';
import { styled } from '@mui/material/styles';
import Paper from '@mui/material/Paper';
import SendOutlinedIcon from '@mui/icons-material/SendOutlined';
import MenuIcon from '@mui/icons-material/Menu';
import { UserApi } from '../service/axios';
import { Helpers } from '../service';
import { useLocation,useNavigate } from 'react-router-dom';

const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
    ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: 'center',
    color: theme.palette.text.secondary,
}));

export default function RegisterWhatsappNumber() {
    const [mobileNumber, setMobileNumber] = useState('');
    const [loading, setLoading] = useState(true);
    const navigate = useNavigate();


    const configureMobileNo = () => {
        setLoading(true); 
        const formData = new FormData();
        formData.append('whatsapp_mobile_no', mobileNumber);
        UserApi.storeAssociateNumbers(formData)
            .then((resp) => {
                if (resp.status === 200) {
                    navigate('/dashboard/whatsappnumber/setup', { replace: true, state: resp.data.associated_no });
                    Helpers.toastSuccess(resp.data.message);
                } else {
                    console.log('error', resp.data.error_msg);
                }
            })
            .catch((error) => {
                console.error('Error fetching to get Masking Data Rule:', error);
            })
            .finally((error) => {
                console.log('error', error);
                setLoading(false);
            });
    };

    const handleMobileNumberChange = (event) => {
        const numericValue = event.target.value.replace(/[^0-9]/g, '');

        if (numericValue.length <= 12) {
            setMobileNumber(numericValue);
        }
    };
    return (
        <>

            <div style={{

            }}>

                <Box sx={{ width: '100%' }}>
                    <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
                        <Grid item xs={12}>
                            <Item className='griditems'>
                                <Box className='mainBox'>
                                    <Box sx={{ width: '100%' }}>
                                        <div class='mobile-phone'>
                                            <div class='brove'><span class='speaker'></span></div>
                                            <div class='screen'>
                                                <Item className='' sx={{ mt: 0, borderRadius: 0, boxShadow: "0px 2px 1px -1px rgb(0 0 0 / 0%), 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 1px 3px 0px rgb(0 0 0 / 6%)", borderTopLeftRadius: 13, borderTopRightRadius: 13, background: "#fff", borderBottom: "1px solid #ccc" }}>
                                                    <Stack direction='row' spacing={1} sx={{ pt: "14px", pr: 2, alignItems: "center", justifyContent: "space-between", }}>
                                                        <img src="/belnitifire.png" alt="" width={"50%"} />
                                                        <MenuIcon />
                                                    </Stack>
                                                </Item>
                                                <Box className="mainscreen2" id='style-4'>
                                                    <Card sx={{ width: "100%", border: "1px solid #efefef4f" }} className="griditems" >
                                                        <CardMedia className='WhatsappCard'
                                                            sx={{ height: "100% ", margin: "17px 18px 0px", textAlign: "left" }}
                                                        >
                                                            <Typography sx={{ fontWeight: 500, }}>Register WhatsApp Number</Typography>
                                                        </CardMedia>
                                                        <CardContent>
                                                            <Stack direction='column' spacing={3}>
                                                                <Typography variant="body2" color="text.secondary" sx={{
                                                                    "textAlign": "left",
                                                                    "font-weight": "500",
                                                                    fontSize: "11px",
                                                                    color: "#000",
                                                                }}>

                                                                    <TextField
                                                                        id="outlined-number"
                                                                        label="Mobile Number"
                                                                        type="tel"
                                                                        value={mobileNumber}
                                                                        onChange={handleMobileNumberChange}
                                                                        style={{ fontSize: "14px" }}
                                                                        className='inputfield'
                                                                    />
                                                                </Typography>
                                                                <div style={{ textAlign: 'left', marginTop: "40px" }}>
                                                                    <div>
                                                                    <Button className='contactBtn2' style={{ fontSize: "12px", width: "-webkit-fill-available" }} onClick={configureMobileNo}>
                                                                        proceed
                                                                    </Button>
                                                                    </div>
                                                                </div>
                                                            </Stack>
                                                        </CardContent>
                                                    </Card>
                                                </Box>
                                                <Box className='chatsendsection' sx={{ p: 0, }}>
                                                    <div className="messagesend">
                                                        <input readOnly type="text" placeholder='Enter Message...' style={{ backgroundColor: "#fff", }} />
                                                        {/* <Button class="formsend-submit">
                                                            <SendOutlinedIcon className='svg-icon svg-icon--send' sx={{ transform: "rotate(333deg)" }} />
                                                        </Button> */}
                                                    </div>
                                                </Box>
                                            </div>
                                        </div>
                                    </Box>
                                </Box>
                            </Item>
                        </Grid>
                    </Grid>
                </Box>
            </div>
        </>
    )
}